import React from 'react'
import { useReducer, createContext, useContext } from 'react'
import produce from 'immer'

const initialState = {
    selectedCompetitor: null,
    url: null,
    selectedDate: null,
    step: "list-competitors",
    landingTable: [],
    topLandingPages: ["https://decathalon.com/deals/lucas-premium-leatherette-l-shape/", "https://decathalon.com/deals/lucas-premium-leatherette-l-shape/", "https://decathalon.com/deals/lucas-premium-leatherette-l-shape/", "https://decathalon.com/deals/lucas-premium-leatherette-l-shape/"],
    
}

const LandingPageReducer = produce((draft, action) => {
    switch(action.type) {
        case 'SET_SELECTED_COMPETITOR':
            draft.selectedCompetitor = action.value
            break
        case 'SET_URL':
            draft.url = action.value
            break
        case 'SET_SELECTED_DATE':
            draft.selectedDate = action.value
            break
        case 'SET_STEP':
            draft.step = action.value
            break
        case 'SET_LANDINGPAGE_TABLE_DATA':
            draft.step = action.value
            break
        case 'SET_TOP_LANDING_PAGES':
            draft.topLandingPages = action.value
            break
        default:
            break
    }
})

const useLandingPageAnalysisReducer = () => {
    return useReducer(LandingPageReducer, initialState)
}

const LandingPageAnalysisContext = createContext()

export const LandingPageAnalysisProvider = ({ children }) => {
    const [state, dispatch] = useLandingPageAnalysisReducer()
    
    const value = {
        landingPageAnalysisState: state,
        landingPageAnalysisDispatch: dispatch
    }
    
    return <LandingPageAnalysisContext.Provider value={value}>
        {children}
    </LandingPageAnalysisContext.Provider>
}

export const useLandingPageAnalysis = () => {
    return useContext(LandingPageAnalysisContext)
}
