import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ThemeProvider } from 'styled-components'
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import {Helmet} from "react-helmet"

import { GlobalStyle } from './globalStyles'
import 'rc-tooltip/assets/bootstrap.css';
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-tooltip/dist/react-tooltip.css'
import "react-datepicker/dist/react-datepicker.css";

import Content from './ui'

import theme from './theme'
import { AuthProvider } from './context/auth'
import ChatBot from './chat'
import { DashboardProvider } from './context/dashboard'
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const queryClient = new QueryClient()

function App() {
  return <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
          <GlobalStyle />
            <Elements stripe={stripePromise}>
              <AuthProvider>
                <DashboardProvider>
                  <Router>
                    <ChatBot/>
                    <Content />
                  </Router>
                </DashboardProvider>
              </AuthProvider>
            </Elements>
          <Toaster />
      </ThemeProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
  </QueryClientProvider>
}

export default App;


