import styled from "styled-components";

export const VerifyOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VerifyContainer = styled.div`
    border-radius: 24px;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 506px;
    height: auto;
    overflow: visible;
    padding: 50px 40px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    h2{
        span{
            font-size: 16px;
            font-weight: 300;
            margin-left: 6px;
        }
    }
`;

export const SendOtpContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    label{
      font-size: 18px;
      font-weight: 500;
    }
    input{
      height: 36px;
      padding: 5px 10px;
      outline: none;
      border: solid 1px #80808085;
      border-radius: 4px;
    }
`;

// export const SendOtpBtn = styled.button`
//     height: 30px;
//     border: none;
//     outline: none;
//     border-radius: 5px;
//     background-color: rgb(255, 113, 30);
//     pointer-events: all;
//     color: white;
//     border: none;
//     cursor: pointer;
//     font-size: 16px;
//     font-weight: 500;
//     width: 150px;
// `;

export const VerifyOtpContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
    label{
      font-size: 18px;
      font-weight: 500;
    }
    input{
      height: 36px;
      padding: 5px 10px;
      outline: none;
      border: solid 1px #80808085;
      border-radius: 4px;
    }
`;

export const VerifyOtpBtn = styled.button`
    height: 30px;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: rgb(255, 113, 30);
    pointer-events: all;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    width: 150px;
`;




export const InputContainer = styled.form`
    /* height: 40px; */
    display: flex;
    justify-content: space-between;
    gap: 8px;
    row-gap: 16px;
    flex-wrap: wrap;
    // @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    // }
    // /* @media screen and (max-width: 1500px) {
    //     flex-wrap: wrap;
    // } */
    // @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
    //     flex-wrap: wrap;
    // }
    // @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    // }
    // @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    // }
    // @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    // }
    // @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    // }
`; 

export const CountryCodeDropdown = styled.div`
    position: relative;
    height: 40px;
`; 

export const DropDownHeader = styled.div`
    width: 110px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    color: #505050;
    font-weight: 300;
    cursor: pointer;
    img{
        height: 30px;
        width: 45px;
    }
    span{
        margin-left: 8px;
    }
`; 

export const DropDownList = styled.div`
    padding-top: 12px;
    padding-bottom: 10px;
    position: absolute;
    width: 310px;
    top: 48px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px #0000001a;
    border-radius: 8px;
    `; 

export const CountryContainer = styled.div`

`; 

export const Albhabet = styled.div`
    background: #F9F9F9;
    border-radius: 0px 8px 8px 0px;
    height: 32px;
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #505050;
    font-size: 14px;
    margin-bottom: 12px;
`; 

export const CountriesList = styled.div`
    padding: 0px 4px;
    height: 40vh;
`; 

export const Country = styled.div`
    height: 32px;
    display: flex;
    font-size: 14px;
    font-weight: 300;
    padding: 6px 8px;
    align-items: center;
    color: #2E2E2E;
    cursor: pointer;
    img{
        height: 15px;
        width: 20px;
    }
    span{
        margin: 0px 8px;
        font-size: 14px;
        font-weight: 300;
        color: #707070;
        line-height: 21px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
    &:hover{
        background: #F6F6F6;
        border-radius: 6px;
    }
`; 

export const PhoneInput = styled.input`
    width: 300px;
    height: 40px;
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};    border-radius: 8px;
    padding: 9px 12px;
    font-weight: 300;
    color: #2E2E2E;

    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }

    // @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    // }
    // @media screen and (max-width: 1500px) {
    //     width: 239px;
    // }
    // @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {
    //     width: 239px;
    // }
    // @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    // }
    // @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {
    //     width: 215px;
    // }
    // @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {
    //     width: 175px;
    // }
    // @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {
    //     width: 175px;
    // }
`;

export const PhoneInputWrap = styled.div`

`;

export const SendOtpBtn = styled.button`
    cursor: pointer;
    background: ${props => props.isDisabled ? "#F2F2F2": "#EAF5FF"} ;
    border-radius: 8px;
    width: 152px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.isDisabled ? "#D4D4D4" : "#1A68C2"};
    font-weight: 300;
    outline: none;
    border: none;
    height: 40px;
    &:hover{
        background: #D0E8FF;
    }
    &:active{
        background: #B5D6F5;
    }


    @media screen and (max-width: ${props => props.theme.breakPoints.extraLargeScreen}) {
        
    }
    @media screen and (max-width: 1500px) {
        width: 100%;
        height: 40px;
    }
    @media screen and (max-width: ${props => props.theme.breakPoints.largeScreen}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.desktop}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobile}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileSmall}) {

    }
    @media screen and (max-width: ${props => props.theme.breakPoints.mobileExtraSmall}) {

    }
`;

export const DropDownListWraper = styled.div`
    overflow: auto;
    height: 40vh;

    &::-webkit-scrollbar-track
    {
        background-color: white;
    }

    &::-webkit-scrollbar
    {
        width: 5px;
        background-color: #D4D4D4;
    }

    &::-webkit-scrollbar-thumb
    {
        background-color: #D4D4D4;
        border-radius: 2px;
    }

`;



export const Error = styled.p`
    color: #FF0045;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
`;

export const BackButton = styled.div`
    cursor: pointer;
    img{
        width: 30px;
    }
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    p{
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 7px;
        span{
            font-size: 16px;
        }
    }
`;