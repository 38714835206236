import styled from "styled-components";

export const PromptWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 80%;
`;
export const GenerateWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  .back {
    img {
      filter: invert(1);
      width: 20px;
      transform: rotateY(180deg);
    }

    margin-top: ${(props) => props.isActive && "45px"};
    margin-bottom: 12px;
    cursor: pointer;
    color: grey;
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
  }
`;
export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: ${(props) => !props.isActive && "45px"};
`;
export const Decrip = styled.div`
  color: grey;
  font-size: 14px;
  margin-top: 12px;
`;

export const TextareaDiv = styled.textarea`
  width: 100%;
  resize: none;
  height: 65px;
  border: none;
  outline: none;
  background: none;
  font-size: 13px;
  &::placeholder {
    font-size: 14px;
    color: grey;
  }
`;
export const TextareaWrap = styled.div`
  border: 2px solid #eaeaea;
  width: 100%;
  border-radius: 4px;
  margin-top: 14px;
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: 12px;
  padding: 18px 10px 8px 20px;
`;
export const SendWrap = styled.div`
  border-radius: 4px;
  width: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #ff7829;
  opacity: ${(props) => (props.isActive ? "" : "0.3")};
  pointer-events: ${(props) => (props.isActive ? "" : "none")};
  height: 30px;
  justify-content: center;
  img {
    height: 15px;
  }
  .loadClass {
    width: 30px;
    height: 20px;
  }
`;
export const CombinationDiv = styled.div`
  width: 80%;
  margin-top: 18px;
`;
export const PromptLayer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const PromptListWrap = styled.div`
  display: flex;
  gap: 18px;
  margin-top: 28px;
`;
export const ListTitle = styled.div`
  color: grey;
  font-size: 15px;
  font-weight: 600;
  width: 27%;
`;
export const PromptDropDown = styled.div`
  width: 70%;
  label {
    font-weight: unset;
    font-size: 14px;
    color: #9a9a9a;
  }
  select {
    outline: none;
    height: 36px;
    width: 100%;
    margin-top: 8px;
    border: 2px solid rgb(192, 192, 192);
    background: none;
    border-radius: 4px;
    option:disabled {
      color: #cacaca;
    }
    option {
      color: black;
      font-size: 15px;
    }
  }
  input {
    width: 100%;
    border: 2px solid rgb(192, 192, 192);
    border-radius: 4px;
    height: 36px;
    background: none;
    outline: none;
    margin-top: 8px;
  }
`;
export const Suggestion = styled.div`
  color: #00c100;
  font-size: 13px;
  font-weight: 500;
  margin-top: 6px;
`;
export const RefinePrompt = styled.div`
  color: #ff3500;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  opacity: ${(props) => (props.isActive ? "0.3" : "")};
  pointer-events: ${(props) => (props.isActive ? "none" : "")};
`;
export const GenerateBtn = styled.div`
  background: #de7a00;
  padding: 10px 20px;
  border-radius: 4px;
  margin-top: 30px;
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? "0.3" : "")};
  pointer-events: ${(props) => (props.isActive ? "none" : "")};
  margin-bottom: 50px;
  img {
    height: 20px;
  }
`;
