import styled from "styled-components";

export const DisplayPrompt = styled.div`
  border: 2px solid grey;
  padding: 14px;
  border-radius: 4px;
  margin-top: 12px;
  max-width: 82%;
  div {
    color: grey;
    font-size: 12px;
    span {
      color: #ff3500;
      font-size: 13px;
    }
  }
  p {
    font-size: 14px;
    margin-top: 10px;
  }
`;
export const ExploreDiv = styled.div`
  margin-top: 40px;
`;
export const CheckboxContainer = styled.div`
  margin-right: 20px;
`;
export const TabToggle = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 24px;
`;
export const ToggleBtn = styled.div`
  border: 1px solid grey;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  background: ${(props) => (props.isClicked ? "#111" : "#fff")};
  color: ${(props) => (props.isClicked ? "#fff" : "#424242")};
`;
export const TableWrap = styled.div`
  max-width: 966px;
  width: 100%;
  height: 617px;
  border-radius: 6px;
  background: #fff;
  margin-top: 24px;
`;
export const TableWrapDummy = styled.div`
  max-width: 350px;
  height: 617px;
  border-radius: 6px;
  background: #fff;
  margin-top: 24px;
`;
export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 46px;
  color: rgba(28, 28, 28, 0.5);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px 6px 0px 0px;
  background: #e3e8ee;
  max-width: 966px;
  height: 57px;
  img {
    height: 16px;
    margin-left: 5px;
  }
`;
export const KeyHeader = styled.div`
  width: 32%;
  display: flex;
`;
export const StatusHeader = styled.div`
  text-align: center;
  width: 15%;
  justify-content: center;
  display: flex;
`;
export const PlatformHeader = styled.div`
  text-align: center;
  width: 16%;
  display: flex;
  justify-content: center;
`;
export const UpdatedHeader = styled.div`
  text-align: center;
  width: 20%;
  justify-content: center;
  display: flex;
`;
export const CountryHeader = styled.div`
  text-align: center;
  width: 17%;
  justify-content: center;
  display: flex;
`;

export const TableBody = styled.div`
  height: 90%;
  overflow-y: auto;
  p {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    color: grey;
  }
`;
export const Row = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e3e8ee;
  height: 69px;
  /* margin: 0px 36px; */
  padding: 0 10px;
`;
export const HoverWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;
  padding: 0 10px;
`;
export const KeyData = styled.div`
  width: 29.7%;
  color: rgba(28, 28, 28, 0.8);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
`;
export const StatusData = styled.div`
  text-align: center;
  width: 13.7%;
  display: flex;
  justify-content: center;
  p {
    width: fit-content;
    border-radius: 4px;
    background: #e1faea;
    padding: 4px 12px;
    color: #318048;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    &.inactive {
      color: #b00020;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      border-radius: 4px;
      background: #f9ebed;
      padding: 4px 12px;
    }
  }
`;
export const PlatformData = styled.div`
  text-align: center;
  width: 14.6%;
  color: rgba(28, 28, 28, 0.6);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
`;
export const UpdatedData = styled.div`
  text-align: center;
  width: 18.8%;
  color: rgba(28, 28, 28, 0.6);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
`;
export const CountryData = styled.div`
  text-align: center;
  width: 15.7%;
  color: rgba(28, 28, 28, 0.6);
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
`;
export const TargetKeyword = styled.div`
  display: flex;
  justify-content: center;
  p {
    background: #de7a00;
    padding: 10px 20px;
    border-radius: 4px;
    margin: 30px 0;
    width: fit-content;
    cursor: pointer;
    opacity: ${(props) => (props.isActive ? "" : "0.3")};
    pointer-events: ${(props) => (props.isActive ? "" : "none")};
  }
`;
export const OuterWrap = styled.div`
  display: flex;
  gap: 20px;
`;
