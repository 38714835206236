import styled from "styled-components";
export const Wrapper = styled.div`
    width: 100%;
`;

export const Header = styled.div`
    padding: 0px 56px 24px;
    border-bottom: solid 1px #DADFE4;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    span{
        color: #8B8B8B;
    }
    h2{
        font-size: 22px;
        font-weight: 600;
        line-height: 26px;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #1C1C1C80;
    }
`;

export const ContentContainer = styled.div`
    padding: 24px 56px;
    display: flex;
    flex-direction: row;
    height: calc(100vh - 230px);
    gap: 24px;
`;

export const Breadcrumb = styled.div`
    width: 200px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    background: #F6F7FB;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(28, 28, 28, 0.90);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    margin: 24px 54px;
`;

export const LeftContentContainer = styled.div`
    width: calc(50% - 12px);
`;

export const RightContentContainer = styled.div`
    width: calc(50% - 12px);
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const TopPagesContainer = styled.div`
    padding: 24px;
    background: #FFF;
    border-radius: 8px;
    height: 416px;
    h3{
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        margin-bottom: 10px;
    }
    > p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 20px;
    }
`;

export const LandingPagesList = styled.div`
    display: flex;
    flex-direction: column;
    border: solid 1px #DADFE4;
    border-radius: 8px;
    overflow: hidden;
`;

export const LandingPageLink = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    background: #FCFCFC;
    height: 66px;
    padding: 24px 16px;
    border-bottom: solid 1px #DADFE4;
    align-items: center;
    img{
        height: 20px;
    }
    p{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #4D72B7;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
`;

export const CustomDomainConatiner = styled.div`
    height: 196px;
    padding: 24px;
    background: #FFF;
    border-radius: 8px;
    h3{
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        margin-bottom: 10px;
    }
    > p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 20px;
    }
`;

export const InputUrlWrapper = styled.div`
    height: 44px;
    display: flex;
    width: 100%;
    gap: 8px;
`;

export const InputUrlContainer = styled.div`
    height: 44px;
    border: solid 1px #DADFE4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 12px;
    width: 85%;
    gap: 5px;
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #1C1C1C;
        width: auto;
    }
    input{
        width: 100%;
        outline: none;
        border: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
`;

export const NextButton = styled.button`
    height: 44px;
    width: 44px;
    background: #FF711E;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;
    img{
        width: 24px;
    }
`;

export const FocusQueryContainer = styled.div`
    height: 196px;
    padding: 24px;
    background: #FFF;
    border-radius: 8px;
    h3{
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        margin-bottom: 10px;
    }
    > p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 20px;
    }
`;