import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
`;

export const Header = styled.div`
    padding: 0px 56px 24px;
    border-bottom: solid 1px #DADFE4;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    span{
        color: #8B8B8B;
    }
    h2{
        font-size: 22px;
        font-weight: 600;
        line-height: 26px;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #1C1C1C80;
    }
`;

export const ContentContainer = styled.div`
    padding: 24px 56px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 230px);
    gap: 24px;
`;

export const Breadcrumb = styled.div`
    width: 200px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    background: #F6F7FB;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(28, 28, 28, 0.90);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    margin: 24px 54px;
`;

export const LeftContentContainer = styled.div`
    width: calc(70% - 12px);
    max-width: 726px;
`;

export const TopPagesContainer = styled.div`
    padding: 24px;
    background: #FFF;
    border-radius: 8px;
    height: 507px;
    h3{
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        margin-bottom: 10px;
    }
    > p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 20px;
    }
`;

export const RightContentContainer = styled.div`
    width: calc(50% - 12px);
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    h3{
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: #2E2E2E;
    }
`;

export const LinkContainer = styled.div`

`;

export const InnerContentWrapper = styled.div`

`;

export const SelectedItems = styled.div`
    border-radius: 8px;
    display: flex;
    gap: 31px;
    align-items: center;
    line-height: normal;
    p {
        color: #2e2e2e;
        font-size: 14px;
        font-weight: 600;
        span {
            color: #8b8b8b;
            font-size: 14px;
            font-weight: 400;
        }
    }
`;

export const EventList = styled.div`

`;

export const EventData = styled.div`

`;