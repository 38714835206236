import styled from "styled-components";

export const LandingResultWrap = styled.div`
  width: 100%;

`;
export const BackWrap = styled.div`
  width: 82px;
  height: 30px;
  color: rgba(28, 28, 28, 0.9);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #dadfe4;
  background: #f6f7fb;
  justify-content: center;
  cursor: pointer;
`;
export const LandingTitle = styled.div`
  color: #2e2e2e;
  font-size: 22px;
  font-weight: 600;
`;
export const HorizontalLine = styled.div`
  height: 1px;
    background: #dadfe4;
`;
export const AlignmentWrap = styled.div`
display: flex;
flex-direction: column;
padding: 24px 54px;
gap: 24px;
max-width: 1176px;
`;
export const ContentAligner = styled.div`
padding: 24px 50px;
`;
