import styled from "styled-components";

export const CompTableDiv = styled.div``;
export const CompTableHead = styled.div`
  display: flex;
  p {
    &.keyClass {
      width: 25%;
    }

    color: #1e1e1e;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%; /* 18px */
    letter-spacing: -0.132px;
  }
`;
export const CompTableBody = styled.div`
  display: flex;
  p {
    &.keyClass {
      width: 25%;
    }
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.176px;
  }
`;
export const DomainName = styled.div`
  width: 35%;
`;
export const ProgressDiv = styled.div`
  width: 40%;

  progress {
    background: #f8f8f8;
  }
  progress::-webkit-progress-bar {
    background: transparent;
  }
  progress::-webkit-progress-value {
    border-radius: 10px;
    border-radius: 3px;

    background: #d99bff;
  }
`;
