import styled from "styled-components";

export const Container = styled.div`
  background: #f9f2ff;
  min-height: 195px;
  width: 100%;
  position: relative;
  padding: 20px 30px;
  padding-bottom: 0px;
  border-radius: ${(props) => (props.isOpened ? " 8px 8px 0px 0px" : "8px")};
`;

export const RoundImg = styled.img``;

export const Card = styled.div`
  height: 105px;
  width: 228px;
  position: relative;
  top: 25px;
  /* margin-left: 25px; */
  border-radius: 3.654px;
  background: #fff;
  .hover-element {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  :hover .hover-element {
    opacity: 1;
    visibility: visible;
  }
`;
export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5.16px;
  height: 150px;
  width: 350px;
  padding: 10px;
  border-radius: 3.654px;
  background: #fff;
  box-shadow: 0px 1.827px 1.827px 0px rgba(0, 0, 0, 0.25);
`;

export const Header = styled.div`
  display: flex;
  column-gap: 7px;
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #132537;
  }
`;

export const LogoWrapper = styled.div`
  border-radius: 16px;
  border: 1px solid #ececec;
  background: #f1f3f4;
  display: flex;
  padding: 2px;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  align-items: center;
  img {
    height: 20px;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  width: 65%;
`;

export const Title = styled.div`
  overflow: hidden;
  color: #2e2e2e;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
`;

export const LinkWrap = styled.div`
  overflow: hidden;
  color: #598763;
  font-family: Inter;
  font-size: 9px;
  font-weight: 300;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const KeywordWrap = styled.div`
  overflow: hidden;
  color: #3f33c8;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const DescriptionWrap = styled.div`
  overflow: hidden;
  color: rgba(28, 28, 28, 0.5);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: Inter;
  font-weight: 400;
  line-height: 14px;
  height: 45px;
  font-size: 10px;
`;

export const FooterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
  align-items: center;
`;

export const CountryWrap = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

export const GoogleIcon = styled.div`
  margin-right: 5px;
  img {
    height: 12px;
  }
`;

export const Country = styled.div`
  overflow: hidden;
  color: rgba(28, 28, 28, 0.5);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
`;
export const CrownImg = styled.div`
  background: #fff2cc;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TopAlertWrap = styled.div`
  position: relative;
  /* margin-left: 55px; */
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  @media (max-width: 1495px) {
    display: none;
  }
`;
export const TopAdAlert = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  margin-bottom: 6px;
  &.titleAlert {
    margin-bottom: 11px;
    p {
      color: rgba(28, 28, 28, 0.9);
      font-size: 14px;
      font-weight: 600;
      position: relative;
    }
  }
  p {
    color: rgba(28, 28, 28, 0.9);
    font-size: 13px;
    font-style: normal;
    line-height: 22px; /* 169.231% */
  }
  .starCheck {
    position: absolute;
    bottom: 16px;
    left: 122px;
  }
`;
export const ContentWrap = styled.div`
  display: flex;
  height: 100%;
  column-gap: 27px;
`;
export const WrapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #484848b2;
    margin-top: 14px;
    margin-bottom: 12px;
  }
  @media (max-width: 1495px) {
    justify-content: space-around;
  }
`;
export const TryAdGenerate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  margin-right: 88px;
  @media (max-width: 1590px) {
    margin-right: unset;
  }
  p {
    color: #b0863c;
    width: 346px;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    margin-top: 22px;
  }
`;
export const TryAdGenerateBtn = styled.div`
  border-radius: 8px;
  background: #f9b030;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  padding: 9px 14px;
  margin-top: 18px;
  cursor: pointer;
`;

export const SnapShotList = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
`;

export const SnapShotContainer = styled.div`
  width: 30%;
  height: ${(props) => (props.isActive ? "100px" : "85px")};
  display: flex;
  flex-direction: column;
  background: #b598cd;
  border-radius: 6px;
`;

export const SnapStat = styled.div`
  height: ${(props) => (props.isActive ? "100px" : "60px")};
  display: flex;
  background: #ffffff;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  align-items: center;
  padding: 5px 5%;
  justify-content: center;
  p {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    color: #4a4a4a;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const SnapShotButton = styled.div`
  align-items: center;
  justify-content: center;
  height: 25px;
  height: 25px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
  display: ${(props) => (props.isActive ? "none" : "flex")};
`;

export const SnapDetails = styled.div`
  height: 230px;
  width: 100%;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 1;
  background: rgb(249, 242, 255);
  padding: 0px 30px 20px;
  border-radius:0px 0px 8px 8px ;

  // transition: all 1s ease;

  // position: relative;
  // animation: slide 0.5s forwards;
  // top: 50vh;

  // @-webkit-keyframes slide {
  //     100% { top: 0; }
  // }

  // @keyframes slide {
  //     100% { top: 0; }
  // }
`;

export const AdSection = styled.div`
  height: 150px;
`;

export const AdDetailsRight = styled.div`
  margin-left: 36px;
  width: calc(100% - 350px);
  p {
    color: #696969;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 14px;
  }
`;

export const SnapDetailsContainer = styled.div`
  padding: 5px 20px;
  box-shadow: 0px 0px 2px -2px #00000040;
  border: solid 1px #e1e1e1;
  border-radius: 6px 6px;
  height: 200px;
  background: #f5eaff;
  > div {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #707070;
    margin-bottom: 8px;
  }

  span {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #ce52ff;
    display: flex;
    align-items: center;
  }
`;

export const RightStats = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 14px;
`;

export const GenerateButton = styled.button`
  height: 34px;
  background: #8954b6;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  color: #fff;
  gap: 6px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  border: none;
  outline: none;
`;
