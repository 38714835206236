import styled from "styled-components";

export const TargetTotalWrap = styled.div`
  border: 2px solid grey;
  padding: 14px;
  border-radius: 4px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  max-width: 80%;
`;
export const SecondaryTarget = styled.div`
  display: flex;
  gap: 24px;
`;
export const TotalTarget = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    color: #1f1f1f;
    font-size: 13px;
  }
  .fontTarget {
    font-weight: 800;
    font-size: 19px;
  }
`;
export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 88%;
`;
export const PushWrap = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
  padding-right: 40px;
  p {
    background: #b9b9b9;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
  }
`;
