import React from "react";
import {
  LandingResultWrap,
  BackWrap,
  LandingTitle,
  HorizontalLine,
  AlignmentWrap,
  ContentAligner,
} from "./index.styled";
import Back from "../../../../../media/Dashboard/back.svg";
import ScreenshotWrap from "./components/ScreenshotWrap";
import AiAnalysisComp from "./components/AiAnalysisComp";

const LandingPageAnalysisResult = () => {
  return (
    <LandingResultWrap>
      <AlignmentWrap>
        <BackWrap>
          <img src={Back} />
          Back
        </BackWrap>
        <LandingTitle>Landing Page Analysis</LandingTitle>
      </AlignmentWrap>
      <HorizontalLine></HorizontalLine>
      <ContentAligner>
        <ScreenshotWrap />
      </ContentAligner>
      <HorizontalLine></HorizontalLine>
      <AiAnalysisComp />
    </LandingResultWrap>
  );
};

export default LandingPageAnalysisResult;
