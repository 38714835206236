import React, { useEffect, useState } from "react";
import {
  ContainerHead,
  OfferContainer,
  OfferOverlay,
  OrangeImg,
  DotImg,
  BonusText,
  BlueRectDiv,
  AdspyderImg,
  ShareThought,
  FeedInfo,
  AdspyFeature,
  TabToggleWrap,
  FeatureInfo,
  ChoiceList,
  ChoiceDiv,
  UnLockStep,
} from "./index.styled";
import dotsImg from "../ExclusiveAdspyVersion/assets/dotsImg.png";
import rect from "../ExclusiveAdspyVersion/assets/Rec.png";
import AdspyLogo from "./assets/adspyderLogo.png";
import checkOrange from "./assets/orangeCheck.png";
import clipboard from "./assets/clipboard.png";
import onlineAdvertising from "./assets/online-advertising.png";
import tracking from "./assets/tracking.png";
import frontDevelopment from "./assets/front-development.png";
import competitor from "./assets/competitor-analysis.png";
import web from "./assets/web-design.png";
import marketing from "./assets/marketing.png";
import forward from "./assets/forward.png";
import shuttle from "./assets/shuttle.png";
import beginning from "./assets/beginning.png";
import qualitative from "./assets/qualitative-research.png";
import arrow from "../../../../media/Dashboard/rightarrow.svg";
import { useAuth } from "../../../../context/auth";
import LinkedIn from "./LinkedIn";
import { useMutation } from "@tanstack/react-query";
import { appClient } from "../../../../utils/axios-utils";

const generateFeedback = (data) => {
  const config = {
    url: "/feedback/generatefeedback",
    method: "POST",
    data,
  };

  return appClient(config);
};

const FeedBackExclusive = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { authState, authDispatch } = useAuth();
  const [feedbackRecommend, setFeedbackRecommend] = useState(false);
  const [infoFeedback, setInfoFeedback] = useState(null);

  const [isLinked, setIsLinked] = useState(null);
  const [isFadeOut, setIsFadeOut] = useState(false);

  const feedbackQuestion = [
    {
      id: 1,
      question: "What feature of AdSpyder do you find most useful?",
      choices: [
        {
          choiceId: 1,
          optionImg: clipboard,
          option: "Ad Library (collection of competitor ads)",
        },
        {
          choiceId: 2,
          optionImg: onlineAdvertising,
          option: "Ad Generation (Text and Image ads)",
        },
        {
          choiceId: 3,
          optionImg: tracking,
          option: "Ad insights and performance tracking",
        },
        {
          choiceId: 4,
          optionImg: frontDevelopment,
          option: "User-friendly interface",
        },
      ],
    },
    {
      id: 2,
      question: "How has AdSpyder improved your workflow?",
      choices: [
        {
          choiceId: 1,
          optionImg: competitor,
          option: "Easily discovering competitor strategies",
        },
        {
          choiceId: 2,
          optionImg: web,
          option: "Simplifying ad creation with ready-to-use templates",
        },
        {
          choiceId: 3,
          optionImg: marketing,
          option: "Providing actionable insights to optimize campaigns",
        },
        {
          choiceId: 4,
          optionImg: forward,
          option: "Making ad management faster and easier",
        },
      ],
    },
    {
      id: 3,
      question: "Would you recommend AdSpyder? Why?",
      choices: [
        {
          choiceId: 1,
          optionImg: competitor,
          option: "Yes, because it simplifies competitive research",
        },
        {
          choiceId: 2,
          optionImg: shuttle,
          option: "Yes, because it boosts ad performance with insights",
        },
        {
          choiceId: 3,
          optionImg: beginning,
          option: "Yes, because it’s beginner-friendly and efficient",
        },
        {
          choiceId: 4,
          optionImg: qualitative,
          option: "Maybe, it depends on their specific needs",
        },
      ],
    },
  ];

  const { mutate: getFeedback, isLoading: isFeedbackLoading } = useMutation(
    generateFeedback,
    {
      onSuccess: (data) => {
        authDispatch({
          type: "SET_LINKEDIN_POST",
          value: data,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    }
  );

  const [choiceNum, setChoiceNum] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setIsVisible(true);
    setTimeout(() => {
      setIsFadeOut(true);
    }, 3500);
    setTimeout(() => {
      setFeedbackRecommend(true);
    }, 4000);
    setTimeout(() => {
      setIsVisible(false);
      setIsFadeOut(false);
    }, 4300);
  }, []);

  const handleNext = (choice) => {
    authDispatch({
      type: "SET_SELECTED_ANSWERS",
      value: {
        id: choice.id,
        quest: choice.question,
        choiceList: selectedOption,
      },
    });

    setChoiceNum(choiceNum + 1);

    if (choiceNum === 3) {
      setInfoFeedback(true);
      setFeedbackRecommend(false);
      setTimeout(() => {
        setIsFadeOut(true);
      }, 2400);
      setTimeout(() => {
        setIsLinked(true);
      }, 2600);
      setTimeout(() => {
        setInfoFeedback(false);
      }, 3000);
      getFeedback({
        featureUsed: authState.selectedAnswers.find((item) => item.id == 1)
          ?.choiceList?.option,
        improvedWorkflow: authState.selectedAnswers.find((item) => item.id == 2)
          ?.choiceList?.option,
        recommendationReason: selectedOption.option,
      });
    }
    setSelectedOption(null);
  };
  return (
    <OfferOverlay>
      <OfferContainer>
        <ContainerHead>
          <OrangeImg src={rect} />
          <DotImg src={dotsImg} />
          <AdspyderImg src={AdspyLogo} />
          <BonusText>The Feedback Bonus</BonusText>
          <BlueRectDiv></BlueRectDiv>
        </ContainerHead>
        {isVisible && (
          <ShareThought isAnimated={true} isFade={isFadeOut}>
            <FeedInfo isAnimated={true}>
              Share Your Thoughts & Unlock Your First Month FREE!
            </FeedInfo>
            <p>
              We’re thrilled you’re using AdSpyder! Your feedback helps us grow
              and deliver better tools for our users. Take 2 minutes to answer
              <br /> 3 quick questions and unlock your first month FREE. Plus,
              <br /> share your thoughts on LinkedIn to inspire others and
              <br /> get exclusive recognition!
            </p>
          </ShareThought>
        )}
        {feedbackRecommend && (
          <AdspyFeature>
            {feedbackQuestion.map((choice) => {
              return (
                choice.id == choiceNum && (
                  <>
                    <TabToggleWrap>
                      {choiceNum <= 3 && <p></p>}
                      {choiceNum <= 3 && choiceNum > 1 && <p></p>}
                      {choiceNum == 3 && <p></p>}
                    </TabToggleWrap>
                    <FeatureInfo>{choice.question}</FeatureInfo>
                    <ChoiceList>
                      {choice.choices.map((item, index) => (
                        <ChoiceDiv
                          onClick={() => setSelectedOption(item)}
                          isActive={selectedOption?.choiceId == item.choiceId}
                        >
                          <p>
                            <img src={item.optionImg} />
                            {item.option}
                          </p>
                          {selectedOption?.choiceId == item.choiceId && (
                            <img src={checkOrange} />
                          )}
                        </ChoiceDiv>
                      ))}
                    </ChoiceList>
                    <UnLockStep isDisabled={!selectedOption?.choiceId}>
                      {choiceNum == 1
                        ? "Just 2 Steps to Unlock Your Bonus!"
                        : choiceNum == 2
                        ? "Just 1 more step"
                        : ""}
                      <img src={arrow} onClick={() => handleNext(choice)} />
                    </UnLockStep>
                  </>
                )
              );
            })}
          </AdspyFeature>
        )}
        {infoFeedback && (
          <ShareThought isSlideUp={isFadeOut}>
            <FeedInfo>
              Your feedback has brought you one step closer to unlocking your
              first month FREE!
            </FeedInfo>
            <p>
              We’ve crafted a LinkedIn post based on your feedback.
              <br /> Sharing it will inspire others and unlock your first month
              FREE – instantly! We’re thrilled you’re using AdSpyder! Your
              feedback helps us grow and deliver better tools for our users.
              Take 2 minutes to answer
            </p>
          </ShareThought>
        )}
        {isLinked && (
          <LinkedIn
            InfoState={setIsLinked}
            IsFeedLoad={isFeedbackLoading}
          />
        )}
      </OfferContainer>
    </OfferOverlay>
  );
};

export default FeedBackExclusive;
