import React from "react";
import {
  DisplayAdContainer,
  KeywordContainer,
  KeywordTotWrap,
  TotalKeyDiv,
} from "./index.styled";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const TotalKeywordsComp = () => {
  const data = {
    labels: [
      "savebrites.com",
      "coldwellbanker.com.mx",
      "cupcoupon.com",
      "cupcoupon.com",
    ],
    datasets: [
      {
        label: "Sales in 2024",
        data: [65, 59, 80, 81],
        backgroundColor: "rgba(128, 97, 219, 0.94)",
        borderRadius: "12px",
      },
    ],
  };
  const options = {
    borderRadius: 6,
    categoryPercentage: 0.6,
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <KeywordTotWrap>
      <KeywordContainer>
        <p className="totalTitle">Total Keywords</p>
        <TotalKeyDiv>
          <p>Keywords</p>
          <Bar data={data} options={options} height={"300"} width={"500"} />
        </TotalKeyDiv>
      </KeywordContainer>
      <DisplayAdContainer>
        <p className="totalTitle">Most Common Display ad size</p>
        <TotalKeyDiv>
        <p>Keywords</p>
        <Bar data={data} options={options} height={"300"} width={"500"} />  
        </TotalKeyDiv>
      </DisplayAdContainer>
    </KeywordTotWrap>
  );
};

export default TotalKeywordsComp;
