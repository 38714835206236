import styled from "styled-components";

export const KeywordTotWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;
export const KeywordContainer = styled.div`
  width: 50%;

`;
export const DisplayAdContainer = styled.div`
  width: 50%;
`;
export const TotalKeyDiv = styled.div`
  height: 309px;
  border-radius: 16.756px;
  background: var(--Base-White, #fff);
  padding: 29px;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    color: var(--Gray-400, #7a7a7a);
    font-size: 14.662px;
    font-weight: 500;
    letter-spacing: -0.524px;
  }
`;
