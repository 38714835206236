import styled from "styled-components";

export const TotalAdsWrapper = styled.div`
  
`;
export const DomainListWrap = styled.div`
  margin-top: 18px;
  display: flex;
  gap: 14px;
`;
export const DomainContainer = styled.div`
  width: 532px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  background: #fff;
`;

export const AdsWrap = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 18px;
`;
export const TextAds = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const DisplayAds = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const Amount = styled.div`
  color: var(--Base-Black, #242424);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  gap: 6px;
  p {
    width: fit-content;
    color: var(--Green-300, #377e36);
    text-align: center;
    padding: 2px 4px;
    border-radius: 4px;
    background: var(--Green-100, #e0f0e4);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
`;
export const AdsOuter = styled.div`
  display: flex;
  gap: 30px;
`;
export const AdsAmount = styled.div`
  color: var(--Base-Black, #242424);
  display: flex;
  align-items: center;
  height: fit-content;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: -2px;
  gap: 14px;
  p {
    width: fit-content;
    color: var(--Green-300, #377e36);
    text-align: center;
    padding: 2px 4px;
    border-radius: 4px;
    background: var(--Green-100, #e0f0e4);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
`;
