import React from "react";
import Layout from "../../layout";
import PromptContainer from "./PromptContainer";
import { PromptProvider } from "./context/promtContext";

const KeywordResearchSample = () => {

  return (
    <Layout>
      <PromptProvider>
        <PromptContainer />
      </PromptProvider>
    </Layout>
  );
};

export default KeywordResearchSample;
