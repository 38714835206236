import { useState } from "react";
import { appClient } from "../../../../utils/axios-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAdGenerator } from "../context/adGeneration";
import { useAuth } from "../../../../context/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { getSearchParams } from "../../../../utils/helper";
import defaultPersonas from "../../../../utils/defaultPersonas";

const createCampaign = (data) => {
  const config = {
    url: `/new-image-ad/create-campaign`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const processedState = (data) => {
  const config = {
    url: `/new-image-ad/get-processed-state`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const updateCampaign = (data) => {
  const config = {
    url: `/new-image-ad/update-campaign-details`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const templateVariation = (data) => {
  const config = {
    url: `/new-image-ad/get-template-variations`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const templateDetail = (data) => {
  const config = {
    url: `/new-image-ad/get-template`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const uploadAsset = (data) => {
  const config = {
    url: `/new-image-ad/upload-asset`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const UpdateImgData = async (data) => {
  const config = {
    url: `/adgeneration/image/generate-stock-images`,
    method: "POST",
    data,
  };
  return appClient(config);
};
const saveGeneratedTemplate = async (data) => {
  const config = {
    url: `/adgeneration/image/save-template`,
    method: "POST",
    data,
  };
  return appClient(config);
};
const getStockImage = async (data) => {
  const config = {
    url: `adgeneration/image/generate-stock-images-on-query`,
    method: "POST",
    data,
  };
  return appClient(config);
};

const UpdateImgAi = async (data) => {
  const config = {
    url: `/adgeneration/image/generate-ai-images`,
    method: "POST",
    data,
  };
  return appClient(config);
};

const getAiGenImage = async (data) => {
  const config = {
    url: `/adgeneration/image/generate-ai-images-on-query`,
    method: "POST",
    data,
  };
  return appClient(config);
};
const getHistory = (data) => {
  const config = {
    url: `/new-image-ad/get-user-history`,
    method: "GET",
  };

  return appClient(config);
};
const insertTemplate = (data) => {
  const config = {
    url: `/new-image-ad/insert-edited-tempaltes`,
    method: "POST",
    data,
  };

  return appClient(config);
};

export const useImageAdGenInfo = () => {
  const navigate = useNavigate();
  const { adGeneratorState, adGeneratorDispatch } = useAdGenerator();
  const location = useLocation();
  const params = getSearchParams(window.location.search);

  let stockOrAiImg = [
    "https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxfHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1483985988355-763728e1935b?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwyfHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1509631179647-0177331693ae?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwzfHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1484327973588-c31f829103fe?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw0fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1506152983158-b4a74a01c721?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw1fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1571513800374-df1bbe650e56?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw2fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1504198458649-3128b932f49e?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw3fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1492707892479-7bc8d5a4ee93?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw4fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1495385794356-15371f348c31?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHw5fHxmYXNoaW9ufGVufDB8fHx8MTcyMzQ0NzA3OHww&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1558769132-cb1aea458c5e?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxMHx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1485231183945-fffde7cc051e?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxMXx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1529139574466-a303027c1d8b?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxMnx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1544957992-20514f595d6f?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxM3x8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1485518882345-15568b007407?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxNHx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1485125639709-a60c3a500bf1?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxNXx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1519748771451-a94c596fad67?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxNnx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1519415943484-9fa1873496d4?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxN3x8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1603189343302-e603f7add05a?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxOHx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1472417583565-62e7bdeda490?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwxOXx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
    "https://images.unsplash.com/photo-1463100099107-aa0980c362e6?ixid=M3w1NzU1NjN8MHwxfHNlYXJjaHwyMHx8ZmFzaGlvbnxlbnwwfHx8fDE3MjM0NDcwNzh8MA&ixlib=rb-4.0.3",
  ];
  // const { textAdGeneratorState, textAdGeneratorDispatch } = useTextAdGenerator();
  //   const [wesbiteMetaLoading, setWesbiteMetaLoading] = useState(false);

  const {
    name,
    description,
    productDetails,
    colors,
    images,
    logo,
    stockImages,
  } = adGeneratorState.brandDetails;

  const { assetImg, propertyType } = adGeneratorState.uploadedAsset;
  const { authState } = useAuth();

  const { mutate: getCreateCampaign, isLoading: isCreateCampaignLoading } =
    useMutation(createCampaign, {
      onSuccess: (data) => {
        adGeneratorDispatch({
          type: "SET_GENERATED_AD_ID",
          value: data.campaignId,
        });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });
  const { mutate: getProcessedState, isLoading: isProcessedStateLoading } =
    useMutation(processedState, {
      onSuccess: (data) => {
        if (!data.processed) {
          setTimeout(() => {
            getProcessedState({
              campaignId: adGeneratorState.generatedAdId,
              projectId: authState.defaultProjectInfo.projectId,
            });
          }, 2000);
        } else {
          console.log("data", data);

          adGeneratorDispatch({
            type: "SET_WEBSITE_META_LOADING",
            value: false,
          });
          if (!data.brandDetails.personas || (data.brandDetails?.personas.length == 0)) {
            adGeneratorDispatch({
              type: "SET_OCCUPATION_ITEM",
              value: defaultPersonas,
            });
          } else {
            adGeneratorDispatch({
              type: "SET_OCCUPATION_ITEM",
              value: data.brandDetails.personas,
            });
          }
          setTimeout(() => {
            if (!name) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                },
              });
            }
          }, 500);

          setTimeout(() => {
            if (!description) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutTheBrand
                    ? data.brandDetails.aboutTheBrand
                    : "",
                },
              });
            }
          }, 1000);

          setTimeout(() => {
            if (!productDetails) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutTheBrand
                    ? data.brandDetails.aboutTheBrand
                    : "",
                  productDetails: data.brandDetails.productDetails
                    ? data.brandDetails.productDetails
                    : "",
                },
              });
            }
          }, 1500);
          setTimeout(() => {
            if (colors.length === 0 || !logo || images.length === 0) {
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  name: data.brandDetails.brandName
                    ? data.brandDetails.brandName
                    : "",
                  description: data.brandDetails.aboutTheBrand
                    ? data.brandDetails.aboutTheBrand
                    : "",
                  productDetails: data.brandDetails.productDetails
                    ? data.brandDetails.productDetails
                    : "",
                  colors: data.brandDetails.colors
                    ? data.brandDetails.colors.map((col) => col.color)
                    : [],
                  logo: data.brandDetails.logos
                    ? data.brandDetails.logos[0].logo
                    : null,
                  images: data.brandDetails.stockImages ? [...(data.brandDetails.stockImages
                    ? data.brandDetails.stockImages.map(
                        (stockImage) => stockImage
                      )
                    : [])] : [
                    ...(data.brandDetails.brandImages
                      ? data.brandDetails.brandImages.map(
                          (brandImage) => brandImage.image
                        )
                      : [])
                  ],
                },
              });
            }
          }, 2000);
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const { mutate: getUpdateCampaign, isLoading: isUpdateCampaignLoading } =
    useMutation(updateCampaign, {
      onSuccess: (data) => {
        data.campaignId &&
          navigate(`/adgenerator/image-ad/result?id=${data.campaignId}`);
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const {
    mutate: getTemplateVariation,
    isLoading: isTemplateVariationLoading,
  } = useMutation(templateVariation, {
    onSuccess: (data) => {
      if (data.processed) {
        if (data.templates.length > 0) {
          const groupedObject = data.templates.reduce((acc, current) => {
            const { templateId } = current;
            if (!acc[templateId]) {
              acc[templateId] = [];
            }
            acc[templateId].push(current);
            return acc;
          }, {});

          const groupedArray = Object.values(groupedObject);
          adGeneratorDispatch({
            type: "SET_TEMPLATE_VARIATIONS",
            value: groupedArray,
          });
        } else {
          adGeneratorDispatch({
            type: "SET_TEMPLATE_VARIATIONS",
            value: data.templates,
          });
        }
      } else {
        setTimeout(() => {
          getTemplateVariation({
            campaignId: params.id,
          });
        }, 1500);
      }
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const { mutate: getTemplateDetail, isLoading: isTemplateDetailLoading } =
    useMutation(templateDetail, {
      onSuccess: (data) => {
        adGeneratorDispatch({
          type: "SET_RAW_TEMPLATE",
          value: data,
        });
        // console.log(data,"data");
        data.stockImages.length > 0
          ? adGeneratorDispatch({
              type: "UPDATE_STOCK_IMAGE",
              value: data.stockImages,
            })
          : adGeneratorDispatch({
              type: "UPDATE_STOCK_IMAGE",
              value: [],
            });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const { mutate: getUploadAsset, isLoading: isUploadAssetLoading } =
    useMutation(uploadAsset, {
      onSuccess: (data) => {
        const uploadImageUsingPresignedUrl = async (presignedUrl) => {
          try {
            const base64Image = assetImg.split(",")[1];

            const binaryString = atob(base64Image);
            const uint8Array = new Uint8Array(binaryString.length);

            for (let i = 0; i < binaryString.length; i++) {
              uint8Array[i] = binaryString.charCodeAt(i);
            }

            console.log(uint8Array, "final image to upload");
            console.log(uint8Array, "final image to upload");
            const response = await fetch(presignedUrl.uploadUrl, {
              method: "PUT",
              body: uint8Array,
              headers: {
                "Content-Type": "image/png",
              },
            });
            if (response.ok) {
              const isLogoChange = propertyType === "logo";
              adGeneratorDispatch({
                type: "SET_BRAND_DETAILS",
                value: {
                  ...adGeneratorState.brandDetails,
                  [propertyType]: isLogoChange
                    ? presignedUrl.imageUrl
                    : [
                        presignedUrl.imageUrl,
                        ...adGeneratorState.brandDetails.images,
                      ],
                },
              });
              return true;
            } else {
              return false;
            }
          } catch (e) {
            console.log(e, "error");
          }
        };
        uploadImageUsingPresignedUrl(data);
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });
  const {
    mutate: getStockImageSuggestion,
    isLoading: isGetStockImageSuggestionLoading,
  } = useMutation(UpdateImgData, {
    onSuccess: (data) => {
      adGeneratorDispatch({ type: "UPDATE_STOCK_IMAGE", value: data });
    },
    onError: (e) => {
      adGeneratorDispatch({ type: "UPDATE_STOCK_IMAGE", value: [] });
    },
  });

  const {
    mutate: SaveGeneratedTemplateMutate,
    isLoading: isSaveGeneratedTemplateLoading,
  } = useMutation(saveGeneratedTemplate, {
    onSuccess: (data) => {},
    onError: (e) => {},
  });
  const { mutate: getStockImageMutate, isLoading: isGetStockImageLoading } =
    useMutation(getStockImage, {
      onSuccess: (data) => {
        if(data && data.stockImages)
          adGeneratorDispatch({
            type: "UPDATE_STOCK_IMAGE",
            value: data.stockImages,
          });
      },
      onError: (e) => {
        adGeneratorDispatch({
          type: "UPDATE_STOCK_IMAGE",
          value: [],
        });
      },
    });
    
  const {
    mutate: getAiGenImagesSuggestion,
    isLoading: isGetAiGenImagesSuggestionLoading,
  } = useMutation(UpdateImgAi, {
    onSuccess: (data) => {
      adGeneratorDispatch({ type: "UPDATE_STOCK_IMAGE", value: data.uploadedAiImagesURLs });
      // setAiGenImageSearch(data.prompt)
    },
    onError: (e) => {
      adGeneratorDispatch({ type: "UPDATE_STOCK_IMAGE", value: [] });
    },
  });
  const { mutate: getAiGenImagesMutate, isLoading: isGetAiGenImagesLoading } =
    useMutation(getAiGenImage, {
      onSuccess: (data) => {
        if(data && data.uploadedAiImagesURLs)
        adGeneratorDispatch({ type: "UPDATE_STOCK_IMAGE", value: data.uploadedAiImagesURLs });
      },
      onError: (e) => {
        adGeneratorDispatch({
          type: "UPDATE_STOCK_IMAGE",
          value: [],
        });
      },
    });

  const { mutate: getImageAdHistory, isLoading: isImageAdHistoryLoading } =
    useMutation(getHistory, {
      onSuccess: (data) => {
        adGeneratorDispatch({ type: "SET_HISTORY_DATA", value: data });
      },
      onError: (e) => {},
    });
  const { mutate: getInsertTemplate, isLoading: isInsertTemplateLoading } =
    useMutation(insertTemplate, {
      onSuccess: (data) => {},
      onError: (e) => {},
    });
  return {
    getCreateCampaign,
    isCreateCampaignLoading,

    getProcessedState,
    isProcessedStateLoading,

    getUpdateCampaign,
    isUpdateCampaignLoading,

    getTemplateVariation,
    isTemplateVariationLoading,

    getTemplateDetail,
    isTemplateDetailLoading,

    getUploadAsset,
    isUploadAssetLoading,

    getStockImageSuggestion,
    isGetStockImageSuggestionLoading,

    SaveGeneratedTemplateMutate,
    isSaveGeneratedTemplateLoading,

    getStockImageMutate,
    isGetStockImageLoading,

    getAiGenImagesSuggestion,
    isGetAiGenImagesSuggestionLoading,

    getAiGenImagesMutate,
    isGetAiGenImagesLoading,

    getImageAdHistory,
    isImageAdHistoryLoading,

    getInsertTemplate,
    isInsertTemplateLoading,
  };
};
