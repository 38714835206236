import styled from "styled-components"

export const Wrapper = styled.div`
    width: 100%;
`;

export const WebIcon = styled.div`
    height: 46px;
    width: 46px;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    border-radius: 5px;
`;

export const Header = styled.div`
    padding: 0px 56px 24px;
    border-bottom: solid 1px #DADFE4;
    width: 100%;
    h2{
        font-size: 22px;
        font-weight: 600;
        line-height: 26px;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #1C1C1C80;
    }
`;

export const ContentContainer = styled.div`
    padding: 24px 56px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 215px);
`;

export const Breadcrumb = styled.div`
    width: 163px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    background: #F6F7FB;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(28, 28, 28, 0.90);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    margin: 24px 54px;
`;

export const CompetitorListContainer = styled.div`
    // height: 50%;
    overflow: auto;
    padding: 5px;
    width: 100%;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
`;

export const CompetitorContainer = styled.div`
    height: 78px;
    width: 20%;
    background: #FFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 16px;
    min-width: 360px;
    cursor: pointer;
    position: relative;
    transition-time: 0.5s;
    border: solid 1px #FFF; 
    p{
        overflow: hidden;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
    }
    img{
        height: 25px;
        width: 25px;
    }
        
    span{
        height: 46px;
        width: 46px;
        margin-right: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        background: #FFF4ED;
        border-radius: 5px;
    }

    :hover{
        outline: solid 1px #FF711E;
        .arrow-container{
            display: flex;
        }
    }
`;

export const KeywordWrap = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
max-width: 966px;
width: 100%;

`
export const KeywordTracking = styled.div`
color: #000;
font-family: 'Inter';
font-size: 20px;
font-weight: 600;
`
export const AddKeyword = styled.div`
border-radius: 6px;
background: #FF711E;
color: #FFF;
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
width: 154px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
column-gap: 8px;
cursor: pointer;
`
export const TableWrap = styled.div`
width: 100%;
height: 75%;
border-radius: 6px;
background: #FFF;
`
export const TableHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 46px;
color: rgba(28, 28, 28, 0.50);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
border-radius: 6px 6px 0px 0px;
background: #F6F7FB;
height: 34px;
    img{
        height: 16px;
        margin-left: 5px;
    }  
`
export const KeyHeader = styled.div`
width: 32%;
    display: flex;
    align-items: center;
        cursor: pointer;
`
export const StatusHeader = styled.div`
text-align: center;
    display: flex;
    align-items: center;
        justify-content: center;
width: 25%;
    cursor: pointer;

`
export const UpdatedHeader = styled.div`
text-align: center;
    display: flex;
    align-items: center;
width: 25%;
    justify-content: center;
    cursor: pointer;
`

export const TableBody = styled.div`
height: 75%;
overflow-y: auto;
::-webkit-scrollbar-track
{
    border-radius: 10px;
}

::-webkit-scrollbar
{
    width: 7px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb
{
    background-color: rgb(227, 232, 238);
    border-radius: 10px;
}

`
export const Row = styled.div`
display: flex;
align-items: center;
border-bottom: 1px solid #E3E8EE;
height:69px;
margin: 0px 36px;

`
export const HoverWrapper = styled.div`
display: flex;
align-items: center;
width: 100%;
height:45px;
padding: 0 10px;
justify-content: space-between;
&:hover{
    background: #FFF7E8;
    cursor: pointer;
}
`
export const KeyData = styled.div`
width: 32%;
color: rgba(28, 28, 28, 0.80);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
`
export const StatusData = styled.div`
text-align: center;
width: 25%;
display: flex;
justify-content: center;
p{
    width: fit-content;
    border-radius: 4px;
    background: ${props => props.status == "success" ? "#E1FAEA" : props.status == "in-progress" ? "#FFEECE" : "#F9EBED"};
    padding: 4px 12px;
    color: ${props => props.status == "success" ? "#318048" : props.status == "in-progress" ? "#FFAA0A" : "#B00020"};
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
}
`
export const UpdatedData = styled.div`
text-align: center;
width: 25%;
color: rgba(28, 28, 28, 0.60);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
`

export const NoHeader = styled.div`
    padding: 9px 20px 9px 20px;
    width: 5%;
    display: flex;
    align-items: center;
`;

export const NumberData = styled.div`
    padding: 0px 20px 0px 20px;
    width: 5%;
`;


export const NoResult = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
`;

export const ArrowIconContainer = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 25px;
    box-shadow: 0px 0px 20px 20px #ffffff;
    display: none;
    img{
        background: #FF711E;
        border-radius: 12px;
    }
`;

export const RecentSearchesContainer = styled.div`
    height: 50%;
    background: #FFF;
    width: 100%;
    max-width: 1128px;
    border-radius: 16px;
    margin-top: 24px;
    h2{
        padding: 24px;
    }
`;