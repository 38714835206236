import styled from "styled-components";

export const OfferOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferContainer = styled.div`
  border-radius: 24px;
  background: #fff;
  width: 775px;
  height: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CancelImg = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  padding: 19px 26px 0 0;
  img {
    height: 24px;
    cursor: pointer;
  }
`;
export const MissedText = styled.div`
  color: #f12375;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
  margin-top: 21px;
  line-height: 150%;
`;
export const ExclusiveText = styled.div`
  color: #000;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 14px;
  line-height: 150%;
  span {
    color: #f12375;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.28px;
  }
`;
export const OfferDescrip = styled.div`
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  margin-top: 39px;
  line-height: 170%;
`;
export const DaysWrap = styled.div`
  display: flex;
  background: #f12375;
  height: 84px;
  width: 98%;
  align-items: center;
  gap: 100px;
  justify-content: center;
  margin-top: 32px;
`;
export const TimeDiv = styled.div`
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.3px;
  text-align: center;
  p {
    color: #fefefe;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.26px;
  }
`;
export const ClaimBtn = styled.div`
  width: 265px;
  background: #262626;
  padding: 8px 0;
  color: #fff;
  text-align: center;
  font-size: ${(props) => (props.isLoading ? "20px" : "")};
  font-weight: 800;
  line-height: ${(props) => (props.isLoading ? "170%" : "")};
  letter-spacing: 0.4px;
  margin-top: 49px;
  cursor: pointer;
  pointer-events: ${(props) => (props.isLoading ? "all" : "none")};
  img {
    height: 31px;
  }
`;
