import styled from "styled-components";
import layerImg from "./assets/layerImg.png";

export const OfferOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferContainer = styled.div`
  border-radius: 24px;
  background: #fff;
  padding-right: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 775px;
  height: 460px;
  display: flex;
`;
export const OfferWrapper = styled.div`
  width: 97%;
  padding: 30px 0 30px 30px;
  display: flex;
`;
export const CancelImg = styled.div`
  padding-top: 10px;
  img {
    cursor: pointer;
  }
`;
export const OfferViewWrap = styled.div`
  background: url(${layerImg}) #fdf264;
  background-size: contain;
  width: 311px;
  height: 100%;
  position: relative;
`;
export const OfferInfoWrap = styled.div`
  border: 2px solid #474747;
  background: #fff;
  width: 404px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: #747474;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    padding-top: 51px;
    display: flex;
    justify-content: center;
    gap: 3px;
  }
`;
export const TimeWrap = styled.div`
width: 93px;
`;
export const WhiteDiv = styled.div`
  background: #fff;
  width: 137px;
  height: 42px;
  display: flex;
  justify-content: end;
  align-items: center;
  top: 27px;
  position: absolute;
  &.bottomDiv {
    width: 178px;
    position: unset;
    justify-content: center;
    color: #000;
    font-size: 17px;
    font-weight: 600;
  }
`;
export const InfoDiv = styled.div`
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 170%; /* 34px */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  position: absolute;
  left: 14px;
  top: 33px;
  width: 243px;
`;
export const VerticalLine = styled.div`
  width: 114px;
  height: 2px;
  background: #000;
  margin-left: 76px;
`;
export const BottomWrap = styled.div`
  bottom: 33px;
  position: absolute;
  right: 11px;
`;
export const ExclusiveText = styled.div`
  width: 100%;
  color: #000;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.48px;
  text-align: end;
  padding: 0 17px 6px 0;
  p {
    color: #000;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.68px;
  }
`;
export const AccessInfo = styled.div`
  width: 272px;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 170%; /* 40.8px */
  letter-spacing: 0.48px;
  text-transform: uppercase;
  padding-top: 40px;
`;
export const Cost = styled.div`
  color: #000;
  font-size: 54px;
  font-weight: 800;
  line-height: 170%; /* 91.8px */
  letter-spacing: 1.08px;
  text-transform: uppercase;
  padding-top: 23px;
`;
export const TryNow = styled.div`
  color: #000;
  font-size: 26px;
  font-weight: 700;
  line-height: 170%; /* 44.2px */
  letter-spacing: 0.52px;
  margin-top: 13px;
  height: 84px;
  background: #fdf264;
  padding: 8px 16px 32px 16px;
  cursor: pointer;
  pointer-events: ${(props) => (props.isLoading ? "all" : "none")};

  img {
    height: 44px;
    width: 247px;
    filter: invert(1);
  }
`;
