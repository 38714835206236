import styled from 'styled-components';

export const HeaderContainer = styled.div`
    height: 64px;
    background: #F7F9FC;
    display: flex;
    /* width: ${props => props.noSideNav ? "100vw" : props.isNavCollapsed ? "calc(100vw - 80px)" : "calc(100vw - 285px)"}; */
    width: ${props => props.noSideNav ?  "100vw": "100%"};

    justify-content: space-between;

    border-bottom: 1px solid #E3E8EE;
`;


export const Logo = styled.div`
    /* width: 200px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    margin-left: 20px;
`;

export const NavContainer = styled.div`
    display: flex;
    /* width: calc(100% - 200px); */
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

export const LinksContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    a{
        text-decoration: none;
        margin-right: 4px;
    }
`;

export const NavButton = styled.span`
    padding: 6px 12px;
    cursor: pointer;
    background: ${props => props.focused ? "#2E2E2E" : "none"};
    color: ${props => props.theme.colors.white};
    font-size: 14px;
    border-radius: 8px;
    
    :hover{
        background: ${props => props.theme.colors.black};
    }   
`;

export const DropDown = styled.div`
    /* width: 100px; */
    position: relative;
    color: ${props => props.theme.colors.white};
    /* justify-content: center; */
    align-items: center;
    display: flex;
    margin-right: 25px;
    cursor: pointer;
`;

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    img{
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
    }
    span{
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 65px;
    }
     /* &:hover{
        background: #333;
    }
    &:active{
        background: #3D3D3D;
    } */
    /* .arrow-icon{
        filter: invert(100%);
        width: 14px;
        margin-left: 8px;
    } */
`;

export const DisabledNavButton = styled.div`
    padding: 6px 12px;
    cursor: default;
    background: ${props => props.focused ? "#2E2E2E" : "none"};
    color: ${props => props.theme.colors.white};
    font-size: 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    
    span{
        color: #FFF;
        font-size: 10px;
        font-weight: 500;
        border-radius: 14px;
        background: #006ECC;
        padding: 4px 8px;
        height: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const DropDownContent = styled.div`
    /* border-radius: 8px; */
    position: absolute;
    /* width: 170px; */
    top: 56px;
    display: flex;
    flex-direction: column;
    z-index: 11;
    background: #FFFFFF;
    right: 15px;
    padding: 24px 20px 24px 0;
    width: 290px;
    height: 320px;
    border-radius: 10px;
    box-shadow: 0px 5px 10px 0px rgba(64, 75, 89, 0.2);


  
`;

export const RightHeader = styled.div`
    display: flex;
`;

export const BreadcrumbContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: 30px;
    gap: 14px;
    p span{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: #1C1C1C;
    }
    p{
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 8px;
        text-transform: capitalize;
        cursor: pointer;
    }

    .sub-path span{
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(28, 28, 28, 0.50);
    }
`;

export const FeedbackContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 24px;
    cursor: pointer;
    border-radius: 24px;
    background: #111926;
    padding: 0px 18px;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    filter: drop-shadow(1px 4px 3px rgba(17, 25, 38, 0.15));
    position: relative;
    overflow: hidden;   

    &::after {
        content: "";
        width: 30px;
        height: 200px;
        position: absolute;
        left: -20px;
        top: 0;
        margin-top: -20px;
        background: #fff;
        opacity: 0.6;
        filter: blur(20px);
        animation: login-blur 3s infinite;
        animation-delay: 0s;
        transform-origin: top;
    }

    @keyframes login-blur {
    from {
        transform: translate(0) rotate(45deg);
    }
    to {
        transform: translateX(370px) rotate(45deg);
    }
    }
`;


export const SelectProject = styled.div`
    height: 34px;
    position: relative;
    width: 200px;
    cursor: pointer;
`;

export const CurrentSelectedProject = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: solid 1px #8080802e;
    border-radius: 3px;
    margin-right: 10px;
    cursor: pointer;
    div{
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        img{
            height: 20px;
            width: 20px;
            object-fit: contain;
        }
    }
    p{
        width: 115px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        cursor: pointer;
    }
`;

export const ProjectListDropDown = styled.div`
    position: absolute;
    background: #FFF;
    border-radius: 3px;
    width: 100%;
    max-height: 180px;
    overflow: auto;
    padding: 5px;
    box-shadow: 0px 0px 1px 1px #80808038;
    z-index: 1;
    top: 38px;
    right: 10px;
`;

export const Project = styled.div`
    padding: 5px;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
    gap: 10px;
    
    pointer-events: ${props => props.isDisabled ? "none": "unset"};
    opacity: ${props => props.isDisabled ? "0.5": "1"};
    background: ${props => props.isDisabled ? "#6666662b": "none"};

    &:hover{
        background: #80808024;
    }

    p{
        width: 190px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }
`;
export const DetailsWrap = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    padding-left: 20px;
    img{
        height: 50px;
        width: 50px;
        border-radius: 4px;
    }
`;
export const NameWrap = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: rgba(102, 112, 133, 1);
        width: 187px;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
    }

`;
export const NameDiv = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(64, 75, 89, 1);
    text-transform: capitalize;
 `;
export const VerticalLine = styled.div`
    border: 1px solid rgba(220, 225, 233, 1);
    margin-top: 20px;
    margin-left: 20px;

`;
export const UpgradePlan = styled.div`
margin-top: 15px;
    padding-left: 20px;

    p{
        color: rgba(102, 112, 133, 1);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin-top: 6px;
    }
`;
export const UpgradeDiv = styled.div`
width: fit-content;
    color: rgba(255, 113, 30, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
cursor: pointer;
`;
export const AccountSettingWrap = styled.div`
    margin-top: 12px;
    padding-left: 12px;
    a{
        height: 36px;
        text-decoration: none;
        color: #2E2E2E;
        display: flex;
        justify-content: start;
        align-items: center;
        border-radius: 4px;
        padding-left: 8px;

        &:hover{
            background: rgba(248, 249, 251, 1);
        }
        &:active{
            background: #E2E2E2;
        }
    }
    span{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(64, 75, 89, 1);
    }
`;