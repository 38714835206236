import React from "react";
import {
  AiAnalysisWrap,
  AnalysisTitle,
  AnalysisDataWrap,
  NumberOfData,
  KeywordTable,
  DataWraper,
  DataDiv,
  TableHead,
  TableBody,
  TableDataWrap,
  TableTitle,
} from "./index.styled";

const AiAnalysisComp = () => {
  return (
    <AiAnalysisWrap>
      <AnalysisTitle>AI Analysis for decathalon.in</AnalysisTitle>
      <AnalysisDataWrap>
        <NumberOfData>
          <DataWraper>
            <p>Number of Ads</p>
            <DataDiv className="bolder">
              1000 <span>+25%</span>
            </DataDiv>
          </DataWraper>
          <DataWraper>
            <p>Number of Keywords</p>
            <DataDiv className="bolder">
              12,000 <span>+25%</span>
            </DataDiv>
          </DataWraper>

          <DataWraper>
            <p>Platform Targeted</p>
            <DataDiv>
              5 <p>(Google, meta, Tiktok)</p>
            </DataDiv>
          </DataWraper>

          <DataWraper>
            <p>Average CPC</p>
            <DataDiv>
              $0.06 <span>-5%</span>
            </DataDiv>
          </DataWraper>

          <DataWraper>
            <p>Campaign Reach</p>
            <DataDiv>
              12 <p>country</p>
            </DataDiv>
          </DataWraper>

          <DataWraper>
            <p>Period</p>
            <DataDiv>
              11 <p>months</p>
            </DataDiv>
          </DataWraper>
        </NumberOfData>
        <KeywordTable>
          <TableTitle>Top Keywords</TableTitle>
          <TableHead>
            <p>Keyword</p>
            <p>Position</p>
            <p>Volume</p>
          </TableHead>
          <TableBody>
            <TableDataWrap>
              <p>Sports wear</p>
              <p>1</p>
              <p>1,00,000</p>

            </TableDataWrap>
          </TableBody>
        </KeywordTable>
      </AnalysisDataWrap>
    </AiAnalysisWrap>
  );
};

export default AiAnalysisComp;
