import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import DashboardLayout from "../../layout";
import NotFound from "../NotFoundPage";
import LandingPageAnalysisHome from "./components/LandingPageAnalysisHome";
import LandingPageAnalysisResult from "./components/LandingPageAnalysisResult";
import { LandingPageAnalysisProvider } from "./context/landingpage";

function LandingPage() {
    return ( 
        <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
            path="/"
            element={
            <DashboardLayout>
                <LandingPageAnalysisHome />
            </DashboardLayout>
            }
        />
        <Route
            path="/details"
            element={
            <DashboardLayout>
                <LandingPageAnalysisResult />
            </DashboardLayout>
            }
        />
        </Routes>
     );
}


function LandingPageProviderWrapper() {
    return ( 
        <LandingPageAnalysisProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard | AdSpyder</title>
                <meta name="title" content="Landing Page Analysis | AdSpyder" />
                <meta name="description" content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. " />
            </Helmet>
            <LandingPage />
        </LandingPageAnalysisProvider>
     );
}

export default LandingPageProviderWrapper;