import React,{ useEffect, useState } from 'react'
import { useAuth } from '../../../../../../context/auth'
import { useDashboard } from '../../../../../../context/dashboard';
import { Wrapper, Header, ContentContainer, CompetitorListContainer, CompetitorContainer, Breadcrumb, WebIcon, Row, StatusData, StatusHeader, TableBody, TableHeader, TableWrap, UpdatedData, UpdatedHeader, NoHeader, NumberData, NoResult,  HoverWrapper, KeyData, KeyHeader, ArrowIconContainer, RecentSearchesContainer } from "./index.styled"
import { useTrackingCompetitors, useTrackingKeywords } from '../../../../AdPages/utils/SortFocus/hooks/useSortFocus';
import { useNavigate } from 'react-router-dom';
import Back from "../../../../../../media/Dashboard/back.svg"
import AddNewPopup from '../../../../../components/AddNewPopup';
import { useDashboardInfo } from '../../../hooks/useLandingPageInfo';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  ColumnDef,
  getSortedRowModel
} from '@tanstack/react-table'
import { useLandingPageAnalysis } from '../../../context/landingpage';
import moment from 'moment';
import { getNumberDateFromInput } from '../../../../../../utils/dateFormat';
import DownArrow from "../../../../../../media/Dashboard/downarrow.png";
import FilterIcon from "../../../../../../media/Dashboard/filter.png";
import UpArrow from "../../../../../../media/Dashboard/uparrow.png";
import RightArrowIcon from "../../../../../../media/Dashboard/RightArrowWhiteOrange.png"

const ListCompetitors = () => {
  const { authState, authDispatch } = useAuth();
  const { dashboardState} = useDashboard()
  const [isAddNewPopupOpened, setAddNewPopupOpened] = useState(false);

  const navigate = useNavigate()

  const {
    authState: { trackingKeywords, trackingCompetitors, defaultProjectInfo },
  } = useAuth();
  const {
    dashboardState: {
      selectedProject,
      keywordChoices,
      selectedCountry,
      competitorChoices,
    },
    dashboardDispatch,
  } = useDashboard();
    const useDashboardInfoHook = useDashboardInfo();
  

  const columnHelper = createColumnHelper();
  const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
  const [sorting, setSorting] = useState([]);
  
  const columns = [
      columnHelper.accessor('no', {
          id: 'no',
          header: () => 'No',
          cell: info => info.getValue(),
      }),
      columnHelper.accessor('url', {
          id: 'url',
          header: () => 'Url',
          cell: info => info.getValue(),
      }),
      columnHelper.accessor('status', {
          id: 'status',
          header: () => 'Status',
          cell: info => info.getValue(),
      }),
      columnHelper.accessor(row => `${new Date(row.requestedOn).getTime()}`, {
          id: 'requestedOn',
          header: () => 'Requested Date',
          cell: info => info.getValue(),
      })
  ];

  const table = useReactTable({
      data: landingPageAnalysisState.landingTable,
      columns,
      state: {
        sorting,
        sortBy: [{ id: "datetime", desc: false }],
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
  });

  const landingDetailNewFn = (data) =>{
      // console.log(data)
      navigate(`/landing-page-analysis/details?url=${data.url}&date=${getNumberDateFromInput(data.requestedDate   )}`)
  }

  const closeAddNewPopup = () => {
    setAddNewPopupOpened(false);
  };

  const { getTrackingKeywords } = useTrackingKeywords();
  const { getTrackingCompetitors } = useTrackingCompetitors();

  const handleSetup = (chosenChoicesIndices, userSelectedList) => {
      const competitorsData = chosenChoicesIndices.map((index) => {
        return {
          domain: competitorChoices[index].domainName,
          source: "suggested",
        };
      });

      const userEnteredCompetitorData = userSelectedList.map((data) => {
        return {
          domain: data,
          source: "user",
          country: selectedCountry,
        };
      });

      useDashboardInfoHook.addCompetitorsForTrackingMutate({
        projectId: selectedProject.id,
        domains: [...competitorsData, ...userEnteredCompetitorData],
        pageRequestFrom: "sortFocus"
      });
  
    closeAddNewPopup();
  }

  useEffect(() => {
    getTrackingKeywords(defaultProjectInfo.projectId);
    getTrackingCompetitors(defaultProjectInfo.projectId);
  }, [defaultProjectInfo]);


  return <Wrapper>
          {isAddNewPopupOpened && <AddNewPopup handleSetup={handleSetup} handleClose={closeAddNewPopup} forKeyword={false} />}
          <Breadcrumb onClick={() => navigate('/overview')} >
              <img src={Back} />
              Back to overview
          </Breadcrumb>
          <Header>
            <h2>
              Landing Page Analysis
            </h2>
            <p>Select the competitors to spy on their landing page</p>
          </Header>
          <ContentContainer>
            <CompetitorListContainer>
              <CompetitorContainer onClick={()=>setAddNewPopupOpened(!isAddNewPopupOpened)}>
                <span>+</span> 
                <p>
                  Add new competitor
                </p>
              </CompetitorContainer>
              {
                authState.trackingCompetitors.map((competitor)=>{
                  return <CompetitorContainer onClick={()=> {
                    landingPageAnalysisDispatch({type: "SET_SELECTED_COMPETITOR", value: competitor})
                    landingPageAnalysisDispatch({type: "SET_STEP", value: "select-url"})
                  }}>
                    <WebIcon>
                      <img src={`https://www.google.com/s2/favicons?domain=https://${competitor}/}`}/> 
                    </WebIcon>
                    <p>
                      {competitor}
                    </p>
                    <ArrowIconContainer className='arrow-container'>
                      <img src={RightArrowIcon}/>
                    </ArrowIconContainer>
                  </CompetitorContainer>
                })
              }
            </CompetitorListContainer>
            <RecentSearchesContainer>
              <h2>Recent Searches</h2>
              <TableWrap>
                      {table.getHeaderGroups().map(headerGroup => {
                          return <TableHeader>
                              <NoHeader>No.</NoHeader>
                          <KeyHeader onClick={headerGroup.headers[1].column.getToggleSortingHandler()}>
                              Domain{" "}
                              <img
                                  src={
                                  headerGroup.headers[1].column.getIsSorted() == "asc"
                                      ? UpArrow
                                      : headerGroup.headers[1].column.getIsSorted() == "desc"
                                      ? DownArrow
                                      : FilterIcon
                                  }
                              />
                          </KeyHeader>
                          <StatusHeader onClick={headerGroup.headers[2].column.getToggleSortingHandler()}>
                              Status{" "}
                              <img
                                  src={
                                  headerGroup.headers[2].column.getIsSorted() == "asc"
                                      ? UpArrow
                                      : headerGroup.headers[2].column.getIsSorted() == "desc"
                                      ? DownArrow
                                      : FilterIcon
                                  }
                              />
                          </StatusHeader>
                          <UpdatedHeader onClick={headerGroup.headers[3].column.getToggleSortingHandler()}>
                              Requested Date{" "}
                              <img
                                  src={
                                  headerGroup.headers[3].column.getIsSorted() == "asc"
                                      ? UpArrow
                                      : headerGroup.headers[3].column.getIsSorted() == "desc"
                                      ? DownArrow
                                      : FilterIcon
                                  }
                              />
                          </UpdatedHeader>
                      </TableHeader>
                        
                              })}
                    <TableBody>
                    {table.getRowModel().rows.length > 0 ? (
                      table.getRowModel().rows.map((row, rowIndex) => {
                          return <Row key={rowIndex}>
                              <HoverWrapper onClick={()=>landingDetailNewFn(row.original)}>
                                  <NumberData>{rowIndex + 1}</NumberData>
                                  <KeyData>{row.original.url}</KeyData>
                                  <StatusData status={row.original.status}>
                                  <p>{row.original.status}</p>
                                  </StatusData>   
                                  <UpdatedData>{moment(row.original.requestedOn).format('YYYY-MM-DD HH:mm')}</UpdatedData>
                              </HoverWrapper>
                          </Row>
                          }
                      )):
                    (
                      <Row>
                        <NoResult>No data available</NoResult>
                      </Row>
                    )
                    }
              </TableBody>
              </TableWrap>
            </RecentSearchesContainer>
          </ContentContainer>
        </Wrapper>
        }


export default ListCompetitors 