import React from "react";
import { useReducer, createContext, useContext } from "react";
import produce from "immer";

const initialState = {
  promptInput: null,
  keywordPlan: null,
  generateKeyw: null,
  selectedInputs: {
    location: null,
    audience: null,
    searchInt: null,
    campaignGoal: null,
  },
  refinedPrompt: null,
  selectedRows: null,
  targetToggle: false,
  seedKeywords: "",
};

const PromptReducer = produce((draft, action) => {
  switch (action.type) {
    case "SET_PROMPT_INPUT":
      draft.promptInput = action.value;
      break;
    case "SET_KEYWORD_PLAN":
      draft.keywordPlan = action.value;
      break;
    case "SET_SELECTED_INPUTS":
      draft.selectedInputs[action.key] = action.value;
      break;
    case "SET_REFINED_PROMPT":
      draft.refinedPrompt = action.value;
      break;
    case "SET_GENERATE_KEYWORD":
      draft.generateKeyw = action.value;
      break;
    case "SET_SELECTED_ROWS":
      draft.selectedRows = action.value;
      break;
    case "SET_TARGET_TOGGLE":
      draft.targetToggle = action.value;
      break;
    case "SET_SEED_KEYWORDS":
      draft.seedKeywords = action.value;
      break;
    default:
      break;
  }
});

const usePromptReducer = () => {
  return useReducer(PromptReducer, initialState);
};

const PromptContext = createContext();

export const PromptProvider = ({ children }) => {
  const [state, dispatch] = usePromptReducer();

  const value = {
    promptState: state,
    promptDispatch: dispatch,
  };

  return (
    <PromptContext.Provider value={value}>{children}</PromptContext.Provider>
  );
};

export const usePromptTracking = () => {
  return useContext(PromptContext);
};
