import { appClient } from "../../../../utils/axios-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
// import { useDashboard } from "../context/dashboard";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import { useAuth } from "../../../../context/auth";
import { useDashboard } from "../../../../context/dashboard";

const getKeywordChoices = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/keyword-suggestions`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getCompetitorChoices = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/competitor-suggestions`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const addKeywordsForTracking = (data) => {
  const config = {
    url: `/dashboard-overview/tracking/add-keyword`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const addCompetitorsForTracking = (data) => {
  const config = {
    url: `/dashboard-overview/tracking/add-competitors`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const TrackingKeywords = (projectId) => {
  const config = {
    url: `/dashboard-overview/competitors/get-tracking-keywords/${projectId}`,
    method: "GET",
  };

  return appClient(config);
};

const getKeywordVolumeChartData = (data) => {
  const config = {
    url: `/dashboard-overview/tracking/keyword-chart`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const Competitors = (projectId) => {
  const config = {
    url: `/dashboard-overview/competitors/get-competitor-tracking-domains/${projectId}`,
    method: "GET",
  };

  return appClient(config);
};

const getProjectData = () => {
  const config = {
    url: `/dashboard-overview/competitors/get-projects`,
    method: "GET",
  };

  return appClient(config);
};
const getIndividual = (projectId) => {
  const config = {
    url: `/dashboard-overview/competitors/get-individual-project/${projectId}`,
    method: "GET",
  };

  return appClient(config);
};

const DeleteTrackingKeywords = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/remove-keyword-tracking`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const DeleteCompetitors = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/remove-competitors-tracking`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const getImageAds = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/top-image-ads`,
    method: "POST",
    data,
  };
  return appClient(config);
};

const getTextAds = (data) => {
  const config = {
    url: `/dashboard-overview/competitors/top-text-ads`,
    method: "POST",
    data,
  };
  return appClient(config);
};

const getCompetitorsChartData = (data) => {
  const config = {
    url: `/dashboard-overview/tracking/competitors-chart`,
    method: "POST",
    data,
  };
  return appClient(config);
};

const pinProject = (data) => {
  const config = {
      url: `/dashboard-overview/client-profile/pin-project`,
      method: "POST",
      data
  }
  return appClient(config)
}

export const useDashboardInfo = () => {
  const {
    dashboardState: { selectedProject, selectedCountry, projectList, restrictions },
    dashboardDispatch,
  } = useDashboard();
  
  const { authState, authDispatch } = useAuth()
  const { getCurrentUser, getRemainingCreditsMutate } = useGetUserInfo()

  const {
    mutate: getTrackingKeywordData,
    isLoading: isTrackingKeywordLoading,
  } = useMutation(TrackingKeywords, {
    onSuccess: (data) => {
      dashboardDispatch({ type: "SET_TRACKING_KEYWORDS", value: data });
      authDispatch({ type: "SET_TRACKING_KEYWORDS", value: data.map((k) => k.keyword) });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const { mutate: pinProjectMutate, isLoading: isLoadingPinProject } = useMutation(pinProject, {
    onSuccess: (data) => {
      getCurrentUser()
    },
    onError: (e) => console.log(e)
  })

  const { mutate: getProjectDetails, isLoading: isProjectDetailsLoading } =
    useMutation(getProjectData, {
      onSuccess: (data) => {
        const { projects, allowedProjectCount, projectCountStillCanAdd } = data
        dashboardDispatch({ type: "SET_PROJECT_LIST", value: projects });
        dashboardDispatch({ 
          type: "SET_PROJECT_RESTRICTIONS", 
          value: {
            allowedProjectCount, 
            projectCountStillCanAdd
          } 
        })
        authDispatch({ type: "SET_PROJECT_LIST", value: data });
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const {
    mutate: getIndividualProject,
    isLoading: isIndividualProjectLoading,
  } = useMutation(getIndividual, {
    onSuccess: (data) => {
      const  { 
        setupDomains, 
        setupKeywords
      } = data
      authDispatch({
        type: "UPDATE_ACTIVE_PROJECT_INFO",
        value: data
      })
      dashboardDispatch({
        type: "SET_SELECTED_PROJECT",
        value: data,
      });
      dashboardDispatch({
        type: "SET_COMPETITOR_SETUP_STATUS",
        value: setupDomains,
      });
      dashboardDispatch({
        type: "SET_KEYWORD_SETUP_STATUS",
        value: setupKeywords,
      });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const {
    mutate: deleteTrackingKeywords,
    isLoading: isDeleteTrackingKeywordsLoading,
  } = useMutation(DeleteTrackingKeywords, {
    onSuccess: (data) => {
      getTrackingKeywordData(selectedProject.id);
      getIndividualProject(selectedProject.id);
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const { mutate: deleteCompetitors, isLoading: isDeleteCompetitorsLoading } = useMutation(
    DeleteCompetitors,
    {
      onSuccess: (data) => {
        getCompetitorsData(selectedProject.id);
        getIndividualProject(selectedProject.id);
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const { mutate: getImageAdsMutate,isLoading:  isImageAdsLoading } = useMutation(
    getImageAds,
    {
      onSuccess: (data) => {
        dashboardDispatch({
          type: "SET_IMAGEADS_DATA",
          value: data,
        });
      },
      onError: (e) => console.log(e),
    }
  );

  const { mutate: getTextAdsMutate,isLoading:  isTextAdsLoading } = useMutation(
    getTextAds,
    {
      onSuccess: (data) => {
        dashboardDispatch({
          type: "SET_TEXTADS_DATA",
          value: data,
        });
      },
      onError: (e) => console.log(e),
    }
  );

  const {
    mutate: getKeywordChoicesMutate,
    isLoading: isGetKeywordChoicesLoading,
  } = useMutation(getKeywordChoices, {
    onSuccess: (data) => {
      if(data.success){
        dashboardDispatch({ type: "SET_KEYWORD_CHOICES", value: data.keywords });
      }else{
        getKeywordChoicesMutate({
          projectId: selectedProject.id,
          country: selectedCountry,
        });
      }
      
      // if (data.length > 0) {
      //   dashboardDispatch({ type: "SET_KEYWORD_CHOICES", value: data });
      // } else {
        // getKeywordChoicesMutate({
        //   projectId: selectedProject.id,
        //   country: selectedCountry,
        // });
      // }
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const {
    mutate: getCompetitorChoicesMutate,
    isLoading: isGetCompetitorChoicesLoading,
  } = useMutation(getCompetitorChoices, {
    onSuccess: (data) => {
      if (data.length > 0) {
        dashboardDispatch({ type: "SET_COMPETITOR_CHOICES", value: data });
      } else {
        getCompetitorChoicesMutate({
          projectId: selectedProject.id,
          country: selectedCountry,
        });
      }
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const {
    mutate: addCompetitorsForTrackingMutate,
    isLoading: isAddCompetitorsForTrackingLoading,
  } = useMutation(addCompetitorsForTracking, {
    onSuccess: (data) => {
      dashboardDispatch({
        type: "SET_COMPETITOR_SETUP_STATUS",
        value: true,
      });
      dashboardDispatch({
        type: "SET_SETUP_STATUS",
        value: false,
      });
      getCompetitorsData(selectedProject.id);
      getIndividualProject(selectedProject.id);
      getRemainingCreditsMutate()
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const {
    mutate: addKeywordsForTrackingMutate,
    isLoading: isAddKeywordsForTrackingLoading,
  } = useMutation(addKeywordsForTracking, {
    onSuccess: (data) => {
      dashboardDispatch({
        type: "SET_KEYWORD_SETUP_STATUS",
        value: true,
      });
      dashboardDispatch({
        type: "SET_SETUP_STATUS",
        value: false,
      });
      getTrackingKeywordData(selectedProject.id);
      getIndividualProject(selectedProject.id);
      getRemainingCreditsMutate()
    },
    onError: (err) => {
      console.log('keyword-tracking error',err);
      if(err.response.status === 403){
        dashboardDispatch({
          type: "SET_KEYWORD_LIMIT_EXCEEDED",
          value: true
        })
      }
      
    },
    onMutate: () => {},
  });

  const {
    mutate: getKeywordVolumeChartDataMutate,
    isLoading: isGetKeywordVolumeChartDataLoading,
  } = useMutation(getKeywordVolumeChartData, {
    onSuccess: (data) => {
      dashboardDispatch({ type: "SET_KEYWORD_CHART_DATA", value: data });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const { mutate: getCompetitorsData, isLoading: isCompetitorsLoading } =
    useMutation(Competitors, {
      onSuccess: (data) => {
        dashboardDispatch({ type: "SET_COMPETITORS", value: data });
        authDispatch({ type: "SET_TRACKING_COMPETITORS", value: data.map((k) => k.domainName) });

        const hasNullCompetingKeywords = data.some(item => item.competingKeywords === null);

        if(hasNullCompetingKeywords){
          setTimeout(() => {
            getCompetitorsData(selectedProject.id)
          }, 3000);
        }
      },
      onError: (err) => {
        console.log(err);
      },
      onMutate: () => {},
    });

  const { mutate: getCompetitorChartValue, isLoading: isCompetitorChartValue } =
    useMutation(getCompetitorsChartData, {
    onSuccess: (data) => {
      dashboardDispatch({ type: "SET_COMPETITORS_CHART", value: data.chartData});
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const restrictionHandler = (restrictionFor) => {
    switch (restrictionFor) {
      case "add-project": 
         useGetUserInfo.getRemainingCreditsMutate()
        console.log('after credits call',authState.plan);
        break
    }
  }

  return {
    deleteTrackingKeywords,
    isDeleteTrackingKeywordsLoading,

    addCompetitorsForTrackingMutate,
    isAddCompetitorsForTrackingLoading
  };
};
