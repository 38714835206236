import React,{ useState } from 'react'
import { useAuth } from '../../../../../../context/auth'
import { useDashboard } from '../../../../../../context/dashboard';
import { Wrapper, Header, ContentContainer, Breadcrumb, LeftContentContainer, RightContentContainer, TopPagesContainer, LinkContainer, InnerContentWrapper, SelectedItems, EventList, EventData } from "./index.styled"
import { useNavigate } from 'react-router-dom';
import Back from "../../../../../../media/Dashboard/back.svg"
import { useLandingPageAnalysis } from '../../../context/landingpage';
import RightArrowIcon from "../../../../../../media/Dashboard/rightarrow.png"
import LinkPin from "../../../../../../media/Dashboard/LinkPin.png"
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

const SelectDate = () => {

  const events = [
    { title: 'Black Friday', start: new Date() },
    { title: 'New Year', start: '2025-01-01T20:00:00+00:00' }
  ];

  const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
  const navigate = useNavigate()

  return <Wrapper>
          <Breadcrumb onClick={() => {
            landingPageAnalysisDispatch({type: "SET_STEP", value: "list-competitors"})
          }} >
              <img src={Back} />
              Back to LP Homepage
          </Breadcrumb>
          <Header>
            <h2>
              Landing Page Analysis <span>{"<  " + landingPageAnalysisState.selectedCompetitor}</span>
            </h2>
            <p>Using the AdSpyder library, we identified changes and updates for the selected competitor on these dates</p>
          </Header>
          <LinkContainer>
          </LinkContainer>
          <ContentContainer>
            <SelectedItems>
              <p>
                Link : <span> https://decathalon.com/deals/lucas-premium-leatherette-l-shape/</span>
              </p>
            </SelectedItems>
            <InnerContentWrapper>
              <LeftContentContainer>
                <TopPagesContainer>
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView='dayGridMonth'
                  weekends={true}
                  events={events}
                  height="100%"
                  eventContent={renderEventContent}
                />
                </TopPagesContainer>
              </LeftContentContainer>
              <RightContentContainer> 
                  <h3>Event Calendar</h3>
                  <EventList>
                    {
                      events.map((eventData)=> {
                        return <EventData>
                            <p>{eventData.start}</p>
                        </EventData>
                      })
                    }
                  </EventList>
              </RightContentContainer>
            </InnerContentWrapper>
          </ContentContainer>
        </Wrapper>
        }


export default SelectDate 