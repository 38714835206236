import React from "react";
import { OfferAnounceTimer, TimerDiv } from "./index.styled";
import loudSpeaker from "../../../../../media/Dashboard/loud-speaker.png"
import { useTimer } from "react-timer-hook";
import { useAuth } from "../../../../../context/auth";

const CountDown = () => {
        const { authState, authDispatch } = useAuth()
    
  const { seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp: new Date(
      authState.setNotifiedCount ? authState.setNotifiedCount.expiredAt : ""
    ),
    onExpire: () => {
      authDispatch({ type: "SET_SHOW_DAY", value: null });
      authDispatch({ type: "SET_NOTIFIED_COUNT", value: null });
    },
  });

  const handleAnouncer = () => {
    authDispatch({ type: "SET_SHOW_DAY", value: authState.setNotifiedCount });
    authDispatch({ type: "SET_NOTIFIED_COUNT", value: null });
  };
  return (
    <OfferAnounceTimer onClick={handleAnouncer}>
      <img src={loudSpeaker} />
      <TimerDiv>{`${days}: ${hours}: ${minutes}: ${seconds}`}</TimerDiv>
    </OfferAnounceTimer>
  );
};

export default CountDown;
