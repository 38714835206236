export function getIdsFromAds(adsArray) {
    let adIds = [];

    adsArray.map((ad)=>{
        adIds.push(ad.id)
    })

    return adIds;
}


export function findAdById(adsArray, id){
    if(id){
        return adsArray.find(item => item.id === id);
    }else{
        return null
    }
}