import React, { useRef, useState } from 'react'
import {
    useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"

// Styles
import {
    CardBody,
    Card,
    ResultsContainer,
    NoResults,
    RequestDataBtn,
    SaveAdsButton,
    HoverCard,
    AdDetailsButton,
    HoverCardWrapper,
    CardWrapper,
    Header,
    LogoWrapper,
    TitleWrap,
    Title,
    LinkWrap,
    KeywordWrap,
    DescriptionWrap,
    FooterWrap,
    CountryWrap,
    Country,
    GoogleIcon
} from "./index.styled"
import _static from "../../../../../../../config/static"
import SearchLoader from '../../../../../../HOC/SearchLoader'
import GlobeIcon from '../../../../../../../media/Dashboard/globe.svg';
import { useGoogleSearch } from "../../context/google"


import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'
import RightUpperBlackArrow from "../../../../../../../media/Dashboard/RightUpperBlackArrow.svg"
import { appClient } from '../../../../../../../utils/axios-utils'
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'
import locationImg from '../../../../../../../media/Dashboard/locationCard.svg'
import AdDetails from "../../../../../../components/AdDetailsModal"
import googleImg from "../../../../../../../media/Dashboard/google.svg"
import { useSaveAds } from '../../../../../../hooks/useSaveAds'
import TryAdGeneration from '../../../Components/TryAdGeneration'

const adDetails = async (id) => {
    const config = {
        url: `/detail/google/${id}`,
        method: "GET"
    }

    return appClient(config)
}
const adClassification = async (id) => {
    const config = {
        url: `/detail/google/classification/${id}`,
        method: "GET"
    }

    return appClient(config)
}

const addToFavourite = async (data) => {
    const config = {
        url: `/adgeneration/project/save-ad`,
        method: "POST",
        data
    }

    return appClient(config)
}

const Results = () => {
    const { googleSearchState, googleSearchDispatch } = useGoogleSearch()
    const [basicAdDetails, setBasicAdDetails] = useState({})
    const [showTick, setShowTick] = useState(false)
    const [copiedItem, setCopiedItem] = useState('')
    const { searchState, searchDispatch } = useSearch()
    const useSaveAdsHook = useSaveAds()
    const { authState, authDispatch } = useAuth()
    const containerRef = useRef(null);
    const useRequestDataHook = useRequestData()

    const toggleDetailsModal = (ad, idx) => {
        googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
        setBasicAdDetails(ad)
        // adsDetailsMutate(ad.id)
        googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
    }

    const getDomainFromUrl = (url) => {
        var parsedUrl = new URL(url);
        var domain = parsedUrl.hostname;
        // Remove subdomains and top-level domain
        var domainParts = domain.split('.');
        if (domainParts.length > 2) {
            domain = domainParts[domainParts.length - 2];
        }
        return domain;
    }

    const convertTimestamp = (timestamp) => {
        // Create a new Date object based on the timestamp
        var date = new Date(timestamp);
        // Get the month name
        var monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        var month = monthNames[date.getMonth()];
        // Get the year
        var year = date.getFullYear();
        // Assemble the formatted date string
        var formattedDate = date.getDate() + ' ' + month + ' ' + year;
        return formattedDate;
    }

    const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(adDetails, {
        onSuccess: (data) => {
            googleSearchDispatch({ type: 'SET_AD_DETAILS', value: data })
        },
        onError: ((e) => {
            console.log("err", e)

            googleSearchDispatch({ type: 'SET_AD_DETAILS', value: {} })
            // googleSearchDispatch({type:'UPDATE_DETAILS_MODAL', value: true})
        })
    })

    const { mutate: adAiClassificationMutate, isLoading: isAiClassificationLoading } = useMutation(adClassification, {
        onSuccess: (data) => {
            if (data.processed || data.id) {
                // console.log("data.processed", data.processed)
                googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION', value: data })
                googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false })
            } else {
                setTimeout(() => {
                    adAiClassificationMutate(basicAdDetails.id)
                }, 5000);
            }
        },
        onError: ((e) => {
            googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION', value: {} })
            googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: false })
        })
    })

    const { mutate: addFavouriteMutate, isLoading: isAddFavouriteLoading } = useMutation(addToFavourite, {
        onSuccess: (data) => {
        },
        onError: ((e) => {
        })
    })
    const onDetailsClose = () => {
        setBasicAdDetails({})
        googleSearchDispatch({ type: 'SET_AD_DETAILS', value: {} })
        googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 20;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    if (authState.isRestrictedPopup) {
        return <ResultsContainer restricted={true} detailsModal={googleSearchState.detailsModal}>
            <PlatformRestrictedPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    if (searchState.limitExceeded) {
        return <ResultsContainer restricted={true} detailsModal={googleSearchState.detailsModal}>
            <LimitExceededPopup onClose={()=>{searchDispatch({type:"UPDATE_LIMIT_EXCEEDED", value: false})}} closeEnabled={true} err={searchState.searchError} />
        </ResultsContainer>
    }

    const onDataRequest = () => {
        if (!authState.isDataRequested) {
            // useRequestDataHook.requestDataMutate({ id: googleSearchState.queryId })
            useRequestDataHook.requestRealtimeDataForGoogleMutate({ id: googleSearchState.queryId })
        }
    }

    const closeDetailModa = () => {
        googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }

    const onCopyClick = (value) => {
        navigator.clipboard.writeText(value);
        setCopiedItem(value)
        setShowTick(true)
        setTimeout(() => {
            setShowTick(false)
        }, 1500);
    }

    function findIndexById(arr, searchId) {
        for (let i = 0; i < googleSearchState.ads.length; i++) {
            if (arr[i].id === searchId) {
                return i; // Return the index when the ID is found
            }
        }
        return -1; // Return -1 if ID is not found in the array
    }

    const navigateTo = (adId, toAd) => {
        // console.log("navigateTo", adId, toAd)
        let adIndex = findIndexById(adId)
        // console.log(adIndex)
        if (adIndex != -1) {
            if (toAd == "next") {
                // console.log("inside next")
                googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(googleSearchState.ads[adIndex + 1])
                googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            } else if (toAd == "back") {
                // console.log("inside back")
                googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: true })
                setBasicAdDetails(googleSearchState.ads[adIndex - 1])
                googleSearchDispatch({ type: 'SET_AD_AI_CLASSIFICATION_LOADING', value: true })
            }
        }
    }

    const onSave = (ad) => {
        useSaveAdsHook.SaveAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId: `${ad.id}`,
            platform: "google"    
        })
        // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'bing' })
        // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
        // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }

    const onRemoveSavedAd = (ad) => {
        useSaveAdsHook.RemoveSavedAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId:  `${ad.id}`,
            platform: "google"    
        })
    }
    
    console.log(googleSearchState)

    return <>
        <ResultsContainer resultLoading={googleSearchState.loadingResults} noResults={googleSearchState.noResults} detailsModal={googleSearchState.detailsModal}>
            {googleSearchState.loadingResults ? <div className='loader'>
                <SearchLoader />
            </div> : 
                googleSearchState.noResults ? <NoResults>
                    <img src={NoResultsLogo} />
                    <h2>No Results Found</h2>
                    <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
                    <RequestDataBtn 
                        // dataRequested={authState.isDataRequested}
                        isDisabled={useRequestDataHook.isRequestRealtimetDataForGoogleLoading || authState.isDataRequested}
                        onClick={onDataRequest}
                    >
                        {authState.isDataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg} /></p> : <p>{useRequestDataHook.isRequestRealtimetDataForGoogleLoading ? "Requesting..." : "Request data crawl for this query"}</p>}
                    </RequestDataBtn>
                </NoResults> :
                    <>
                        {authState.saveAdsPopupOpen && <SaveAdsPopup noMarginTop={true} />}
                        {googleSearchState.topResultAd?.length > 0 && <TryAdGeneration topAd={googleSearchState.topResultAd} platform="google"/>}
                        <CardWrapper ref={containerRef} detailsModal={googleSearchState.detailsModal}>
                            {
                                googleSearchState.ads && googleSearchState.ads.length > 0 && googleSearchState.ads.map((ad, idx) => {
                                    return (
                                        <Card detailsOpen={basicAdDetails.id == ad.id} key={
                                            ad.id
                                        }>
                                            {/* {console.log(authState.savedAdsIds,ad.id)} */}
                                            <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad, idx)}>
                                                <HoverCardWrapper>
                                                    <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
                                                        <img src={RightUpperBlackArrow} />
                                                        <span>Ad Details</span>
                                                    </AdDetailsButton>
                                                    <SaveAdsButton isSaved={authState.savedAdsIds.includes(ad.id)} onClick={(e) => {
                                                        e.stopPropagation();
                                                        if(authState.savedAdsIds.includes(ad.id)){
                                                            onRemoveSavedAd(ad)
                                                        }else{
                                                            onSave(ad);
                                                        }
                                                        
                                                    }}>
                                                        {authState.savedAdsIds.includes(ad.id) ? "Saved" : "Save"}
                                                    </SaveAdsButton>
                                                </HoverCardWrapper>
                                            </HoverCard>
                                            <CardBody>
                                                <Header>
                                                    <LogoWrapper>
                                                        <img src={GlobeIcon} />
                                                    </LogoWrapper>
                                                    <TitleWrap> 
                                                        <Title>{ad.domainId}</Title>
                                                        <LinkWrap>{ad.url}</LinkWrap>
                                                    </TitleWrap>
                                                </Header>
                                                <KeywordWrap>{ad.title}</KeywordWrap>
                                                <DescriptionWrap>
                                                    {`${convertTimestamp(ad.lastSeen)} __ ${ad.content}`}
                                                </DescriptionWrap>
                                                <FooterWrap>
                                                    <CountryWrap>
                                                    <img src={locationImg}/>
                                                        <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
                                                    </CountryWrap>
                                                    <GoogleIcon>
                                                        <img src={googleImg}/>
                                                    </GoogleIcon>
                                                </FooterWrap>
                                            </CardBody>
                                        </Card>
                                    )
                                 })
                             }
                         </CardWrapper>

                        {
                            googleSearchState.detailsModal &&
                            <AdDetails
                                navigateTo={navigateTo}
                                platform="google"
                                basicDetail={basicAdDetails}
                                onDetailsClose={onDetailsClose}
                                highlightWords={googleSearchState.highlightWords}
                                onSave={onSave}
                                advancedFilters={googleSearchState.selectedOtherFilters}
                            />
                        }
                    </>
            }
        </ResultsContainer>
       </>
}

export default Results