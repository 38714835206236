import styled from "styled-components";
import checkLine from "./assets/checkLine.png";

export const OfferOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferContainer = styled.div`
  width: 775px;
  height: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #642112;
  position: relative;
`;
export const CheckLineDiv = styled.img`
  height: 100%;
`;
export const BlackContainer = styled.div`
  height: 233px;
  width: 100%;
  background: #131210 url(${checkLine});
  background-size: cover;
`;
export const CancelImg = styled.img`
  position: absolute;
  right: 15px;
  top: 17px;
  height: 16px;
  cursor: pointer;
`;
export const OfferDetailWrap = styled.div`
  width: 640px;
  height: 352px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin: 61px auto 0 auto;
  padding: 0 22px;
`;
export const FullPower = styled.div`
  width: 396px;
  height: 59px;
  background: #e65130;
  position: absolute;
  top: 31px;
  left: 81px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 20px 0 20px 23px;
`;
export const CancelInfo = styled.div`
  color: #4e4e4e;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  padding-top: 44px;
  span {
    color: #e65130;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }
`;
export const OptionWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 16px;
`;

export const FreeOption = styled.div`
  width: 293px;
  height: ${(props) => (props.forFree ? "250px" : "244px")};
  border: ${(props) =>
    props.forFree ? "2px solid #e65130" : "2px solid #b4b4b4"};
  background: #fff;
  padding: 14px;
`;
export const OptionCount = styled.div`
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-left: 2px;
  span {
    color: #e65130;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
  }
`;
export const Price = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 170%;
  letter-spacing: 0.36px;
  margin: ${(props) => (props.forFree ? "65px 0 0 4px" : "70px 0 0 2px")};
  span {
    color: #000;
    font-size: ${(props) => (props.forFree ? "28px" : "24px")};
    font-weight: 800;
    line-height: 170%;
    letter-spacing: ${(props) => (props.forFree ? "0.56px" : "0.48px")};
    margin-left: 9px;
  }
`;
export const ClaimNowBtn = styled.div`
  border: ${(props) =>
    props.forFree ? "1px solid #000000" : "1px solid #e65130"};
  background: ${(props) => (props.forFree ? "#E65130" : "#fff")};
  width: 265px;
  height:  ${(props) => (props.forFree ? "50px" : "46px")};
  color: ${(props) => (props.forFree ? "#fff" : "#E65130")};
  text-align: center;
  font-size: ${(props) => (props.forFree ? "20px" : "16px")};
  box-shadow: ${(props) =>
    props.forFree ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : ""};
  font-weight: 800;
  line-height: 170%;
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18px;
  cursor: pointer;
  pointer-events: ${(props) => (props.isLoading && !props.forFree  && "all")};

  img{
    height: 46px;
    filter: invert(1);
  }
`;
export const Emoji = styled.img`
  position: absolute;
  right: 49px;
  top: 15px;
`;
