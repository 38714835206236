import React from "react";
import { useReducer, createContext, useContext } from "react";
import produce from "immer";
import { extendMoment } from "moment-range";
import moment from "moment";
const extendedMoment = extendMoment(moment);
const today = extendedMoment();

const initialState = {
  selectedCountry: {},
  selectedDate: extendedMoment.range(
    today.clone().subtract(1, "year"),
    today.clone()
  ),
  insights: {
    insights: [
      {
        id: "db9f2af1-4318-410d-ba5f-3ec236f3adbd",
        domainName: "savebrites.com",
        avgCpc: "NaN",
        adCountOvertime: [
          {
            startDate: "2023-12-16",
            endDate: "2024-01-15",
            textAdsCount: 2,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-01-16",
            endDate: "2024-02-15",
            textAdsCount: 1,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-02-16",
            endDate: "2024-03-17",
            textAdsCount: 8,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-03-18",
            endDate: "2024-04-17",
            textAdsCount: 4,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-04-18",
            endDate: "2024-05-18",
            textAdsCount: 5,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-05-19",
            endDate: "2024-06-18",
            textAdsCount: 2,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-06-19",
            endDate: "2024-07-19",
            textAdsCount: 2,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-07-20",
            endDate: "2024-08-19",
            textAdsCount: 1,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-08-20",
            endDate: "2024-09-19",
            textAdsCount: 1,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-09-20",
            endDate: "2024-10-20",
            textAdsCount: 8,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-10-21",
            endDate: "2024-11-20",
            textAdsCount: 26,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-11-21",
            endDate: "2024-12-21",
            textAdsCount: 10,
            displayAdsCount: 0,
          },
        ],
        totalAdsCount: 70,
        totalKeywordsCount: 55,
        platformTargeted: {
          google: true,
          googleEcom: false,
          bing: false,
          bingEcom: false,
          display: false,
          facebook: false,
        },
        platformTargetedCount: 1,
      },
      {
        id: "d4bd9e3a-2976-40e5-8602-08616a04f3d2",
        domainName: "coldwellbanker.com.mx",
        avgCpc: null,
        adCountOvertime: [
          {
            startDate: "2023-12-16",
            endDate: "2024-01-15",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-01-16",
            endDate: "2024-02-15",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-02-16",
            endDate: "2024-03-17",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-03-18",
            endDate: "2024-04-17",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-04-18",
            endDate: "2024-05-18",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-05-19",
            endDate: "2024-06-18",
            textAdsCount: 6,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-06-19",
            endDate: "2024-07-19",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-07-20",
            endDate: "2024-08-19",
            textAdsCount: 4,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-08-20",
            endDate: "2024-09-19",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-09-20",
            endDate: "2024-10-20",
            textAdsCount: 24,
            displayAdsCount: 6,
          },
          {
            startDate: "2024-10-21",
            endDate: "2024-11-20",
            textAdsCount: 1,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-11-21",
            endDate: "2024-12-21",
            textAdsCount: 14,
            displayAdsCount: 7,
          },
        ],
        totalAdsCount: 62,
        totalKeywordsCount: 44,
        platformTargeted: {
          google: true,
          googleEcom: false,
          bing: false,
          bingEcom: false,
          display: true,
          facebook: false,
        },
        platformTargetedCount: 2,
      },
      {
        id: "1d5c9ecf-f865-4d1b-9a87-d6862c0e875f",
        domainName: "cupcoupon.com",
        avgCpc: null,
        adCountOvertime: [
          {
            startDate: "2023-12-16",
            endDate: "2024-01-15",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-01-16",
            endDate: "2024-02-15",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-02-16",
            endDate: "2024-03-17",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-03-18",
            endDate: "2024-04-17",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-04-18",
            endDate: "2024-05-18",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-05-19",
            endDate: "2024-06-18",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-06-19",
            endDate: "2024-07-19",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-07-20",
            endDate: "2024-08-19",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-08-20",
            endDate: "2024-09-19",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-09-20",
            endDate: "2024-10-20",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-10-21",
            endDate: "2024-11-20",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
          {
            startDate: "2024-11-21",
            endDate: "2024-12-21",
            textAdsCount: 0,
            displayAdsCount: 0,
          },
        ],
        totalAdsCount: 0,
        totalKeywordsCount: 133,
        platformTargeted: {
          google: false,
          googleEcom: false,
          bing: false,
          bingEcom: false,
          display: false,
          facebook: false,
        },
        platformTargetedCount: 0,
      },
    ],
    mostPlatformTargeted: {
      id: "d4bd9e3a-2976-40e5-8602-08616a04f3d2",
      domainName: "coldwellbanker.com.mx",
      platformTargeted: {
        google: true,
        googleEcom: false,
        bing: false,
        bingEcom: false,
        display: true,
        facebook: false,
      },
      platformTargetedCount: 2,
    },
    lowestCpc: {
      id: "db9f2af1-4318-410d-ba5f-3ec236f3adbd",
      domainName: "savebrites.com",
      avgCpc: "NaN",
    },
    highestAdVolume: {
      id: "db9f2af1-4318-410d-ba5f-3ec236f3adbd",
      domainName: "savebrites.com",
      totalAdsCount: 70,
    },
    aiSuggestion:
      "The domain savebrites.com has the highest ad volume with 70 ads. It focuses on platforms such as google. Consider analyzing its strategy to understand ad placements and targeting.\nThe domain coldwellbanker.com.mx stands out by targeting 2 platforms with 62 ads. This multi-platform strategy could be a benchmark for expanding your reach.\nThe domain savebrites.com has the lowest CPC or minimal ad activity with 55 keywords. This could indicate a cost-efficient or niche targeting strategy.",
  },
};

const CompetitorReducer = produce((draft, action) => {
  switch (action.type) {
    case "SET_SELECTED_COUNTRY":
      draft.selectedCountry = action.value;
      break;
    case "SET_SELECTED_DATE":
      draft.selectedDate = action.value;
      break;
    case "SET_INSIGHTS":
      draft.insights = action.value;
      break;
    default:
      break;
  }
});

const useCompetitorReducer = () => {
  return useReducer(CompetitorReducer, initialState);
};

const CompetitorContext = createContext();

export const CompetitorProvider = ({ children }) => {
  const [state, dispatch] = useCompetitorReducer();

  const value = {
    competitorState: state,
    competitorDispatch: dispatch,
  };

  return (
    <CompetitorContext.Provider value={value}>
      {children}
    </CompetitorContext.Provider>
  );
};

export const useCompetitorTracking = () => {
  return useContext(CompetitorContext);
};
