import React, { useEffect, useState } from "react";
import {
  CancelImg,
  ClaimBtn,
  DaysWrap,
  ExclusiveText,
  MissedText,
  OfferContainer,
  OfferDescrip,
  OfferOverlay,
  TimeDiv,
} from "./index.styled";

import cancelIcon from "../../../../media/Dashboard/closeIcon.svg";
import { useAuth } from "../../../../context/auth";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import { useSubscription } from "../../../hooks/useSubscription";
import rolling from "../../../../media/Dashboard/Rolling.svg";
import { useTimer } from "react-timer-hook";

const ExclusivePinkVersion = () => {
  const { authState, authDispatch } = useAuth();
  const useGetUserInfoHook = useGetUserInfo();
  const subscriptionHook = useSubscription();

  const { seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp: new Date(authState.showDayPopup.expiredAt),
    onExpire: () => {
      authDispatch({ type: "SET_SHOW_DAY", value: null });
      authDispatch({ type: "SET_NOTIFIED_COUNT", value: null });
    },
  });

  const handleClaim = () => {
    subscriptionHook.CreateSubscriptionMutate({
      planId: authState.showDayPopup.associatedPlanId,
      offerCode: authState.showDayPopup.offerCode,
    });
    useGetUserInfoHook.setOfferNotified({
      offerId: authState.showDayPopup.id,
    });
  };

  return (
    <OfferOverlay>
      <OfferContainer>
        <CancelImg>
          <img
            src={cancelIcon}
            onClick={() => {
              useGetUserInfoHook.setOfferNotified({
                offerId: authState.showDayPopup.id,
              });
              authDispatch({ type: "SET_SHOW_DAY", value: null });
              authDispatch({
                type: "SET_NOTIFIED_COUNT",
                value: authState.showDayPopup,
              });
            }}
          />
        </CancelImg>
        <MissedText>
          {authState.showDayPopup.offerCode == "STVD2" &&
            `Missed the $49 Deal?`}{" "}
          Don’t Miss This Deal!
        </MissedText>
        <ExclusiveText>
          EXCLUSIVE OFFER:
          <span>
            {authState.showDayPopup.offerCode == "STVD1" ? "$49" : "$99"}/MONTH
            PLAN
          </span>
        </ExclusiveText>
        <OfferDescrip>
          {authState.showDayPopup.offerCode == "STVD1" &&
            `Get it before it’s gone—price jumps to $99/month after this
          limited-time offer!`}
        </OfferDescrip>
        <DaysWrap>
          <TimeDiv>
            {days}
            <p>Days</p>
          </TimeDiv>
          <TimeDiv>
            {hours}
            <p>Hours</p>
          </TimeDiv>
          <TimeDiv>
            {minutes}
            <p>Mins</p>
          </TimeDiv>
          <TimeDiv>
            {seconds}
            <p>Seconds</p>
          </TimeDiv>
        </DaysWrap>
        <ClaimBtn
          isLoading={!subscriptionHook.isSubscriptionLoading}
          onClick={handleClaim}
        >
          {!subscriptionHook.isSubscriptionLoading ? (
            "CLAIM NOW>>"
          ) : (
            <img src={rolling} />
          )}
        </ClaimBtn>
      </OfferContainer>
    </OfferOverlay>
  );
};

export default ExclusivePinkVersion;
