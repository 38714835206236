import styled from "styled-components";

export const OfferOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferContainer = styled.div`
  border-radius: 24px;
  background: rgb(98, 30, 175);
  width: 775px;
  height: 460px;
  overflow: hidden;
`;
export const OfferHead = styled.div`
  background: #621eaf;
  height: 92px;
  width: 100%;
  position: relative;
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 150%; /* 45px */
  letter-spacing: 0.6px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 15px;
    position: absolute;
    right: 21px;
    top: 21px;
    cursor: pointer;
  }
`;
export const PlanWrap = styled.div`
  display: flex;
  gap: 62px;
`;
export const StandardPlan = styled.div`
  margin-left: 113px;
  p {
    color: #767676;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 171%; /* 30.6px */
    margin-top: 82px;
  }
`;
export const LifeTimePlan = styled.div`
  margin-top: 36px;
  border-radius: 12px;
  border: 1px solid #632fb5;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 316px;
  height: 163px;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    color: #621eaf;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
    line-height: 172%; /* 30.6px */
  }
`;
export const PlanAmount = styled.div`
  color: #000;
  font-size: 40px;
  font-weight: 700;
  line-height: 165%;
  letter-spacing: 1px;
`;

export const LifeTimePrice = styled.div`
  color: #000;
  font-size: 80px;
  font-weight: 800;
  letter-spacing: 1.6px;
`;
export const LimitedDiv = styled.div`
  color: #737373;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  letter-spacing: -0.42px;
  text-align: end;
  width: 88%;
  margin-top: 5px;
`;
export const WrapperDiv = styled.div`
  background: #fff;
  height: 368px;
`;
export const ClaimAccess = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 23px;
  p {
    border-radius: 30px;
    background: #621eaf;
    width: 475px;
    height: 61px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 170%; /* 34px */
    letter-spacing: 0.4px;
    padding-top: ${(props) => (props.isLoading ? "14px" : "none")};
    cursor: pointer;
    pointer-events: ${(props) => (props.isLoading ? "all" : "none")};
    img {
      height: 61px;
    }
  }
`;
export const SpotsLeft = styled.div`
  color: #737373;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 170%; /* 23.8px */
  letter-spacing: -0.42px;
  margin-top: 8px;
`;
