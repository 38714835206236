import React, { useEffect, useState } from "react";
import {
  PushWrap,
  SecondaryTarget,
  TableContainer,
  TargetTotalWrap,
  TotalTarget,
} from "./index.styled";
import {
  HoverWrapper,
  KeyData,
  KeyHeader,
  PlatformData,
  PlatformHeader,
  Row,
  StatusData,
  StatusHeader,
  TableBody,
  TableHeader,
  TableWrap,
  CheckboxContainer,
  CountryData,
  CountryHeader,
  UpdatedData,
  UpdatedHeader,
} from "../GenerateKeyComponent/index.styled";
import { usePromptTracking } from "../../context/promtContext";
import { useMutation } from "@tanstack/react-query";
import { appClient } from "../../../../../utils/axios-utils";
import { useAuth } from "../../../../../context/auth";

const getKeywordForecast = async (data) => {
  const config = {
    url: "/keyword-research/keyword-forecast",
    method: "POST",
    data,
  };

  return appClient(config);
};

const TargetedKeywords = () => {
  const { promptState, promptDispatch } = usePromptTracking();
  const { authState, authDispatch } = useAuth();
  const [targetData, setTargetData] = useState([]);

  const handleCheckboxChange = (e, row) => {
    // const isChecked = e.target.checked;
    // promptDispatch({
    //   type: "SET_SELECTED_ROWS",
    //   value: isChecked
    //     ? [...(promptState.selectedRows || []), row]
    //     : promptState.selectedRows.filter((selectedRow) => selectedRow !== row),
    // });
  };

  const { mutate: setKeywordForecast, isLoading: isKeywordForecastLoading } =
    useMutation(getKeywordForecast, {
      onSuccess: (data) => {
        setTargetData(data);
      },
      onError: (err) => {},
    });

  useEffect(() => {
    setKeywordForecast({
      broadKeywords: promptState.selectedRows?.map((item) => item.keyword),
      phraseKeywords: [],
      exactKeywords: [],
      currencyCode: "USD",
      projectId: authState.defaultProjectInfo.projectId,
      keywordPlanId: promptState.keywordPlan?.id,
    });
  }, []);
  return (
    <div>
      <TargetTotalWrap>
        <TotalTarget>
          <p>Total Targeted Keywords</p>
          <p style={{ color: "green", fontSize: "14px" }}>
            {promptState.selectedRows.length} keywords
          </p>
        </TotalTarget>
        <SecondaryTarget>
          <TotalTarget>
            <p>Estimated target audience </p>
            <p className="fontTarget">
              {targetData.forecastResult?.impressions}
            </p>
          </TotalTarget>
          <TotalTarget>
            <p>Avg CPC</p>
            <p className="fontTarget">
              {targetData.forecastResult?.averageCpc
                ? targetData.forecastResult?.averageCpc
                : 0}
              /per day
            </p>
          </TotalTarget>
        </SecondaryTarget>
      </TargetTotalWrap>
      <TableWrap>
        <TableHeader>
          <KeyHeader>Keyword text</KeyHeader>
          <StatusHeader>Search volume</StatusHeader>
          <PlatformHeader>CPC </PlatformHeader>
          <CountryHeader>Relevance Score</CountryHeader>
          <UpdatedHeader>Competition level</UpdatedHeader>
        </TableHeader>
        {/* <TableContainer> */}
        <TableBody>
          {promptState.selectedRows.length > 0 &&
            promptState.selectedRows.map((row, rowIndex) => {
              return (
                <Row key={rowIndex}>
                  <HoverWrapper>
                    {/* <CheckboxContainer>
                      <input
                        type="checkbox"
                        id={`checkbox-${rowIndex}`}
                        name={`checkbox-${rowIndex}`}
                        value={row.keyword}
                        onChange={(e) => handleCheckboxChange(e, row)}
                      />
                    </CheckboxContainer> */}
                    <KeyData>{row.keyword}</KeyData>
                    <StatusData>{row.relevanceScore}</StatusData>
                    <PlatformData>{row.avgSearchVolume}</PlatformData>
                    <CountryData>{row.avgCpc}</CountryData>
                    <UpdatedData>{row.competition} </UpdatedData>
                  </HoverWrapper>
                </Row>
              );
            })}
        </TableBody>
        {/* <PushWrap>
            <p>Push to Campaign</p>
            <p>Save as Keyword Set</p>
          </PushWrap> */}
        {/* </TableContainer> */}
      </TableWrap>
    </div>
  );
};

export default TargetedKeywords;
