import React from "react";
import {
  ScreenShotContainer,
  ScreenShotDiv,
  ScreenWrapper,
  SelectedItems,
  KeyObservationDiv,
  ImageWrap,
  FullScreenImg,
  KeyTitle,
  KeyPoints,
  IndexNumber,
} from "./index.styled";
import fullscreen from "../../../../../../../media/Dashboard/fullscreen.png";

const ScreenshotWrap = () => {
  return (
    <ScreenWrapper>
      <SelectedItems>
        <p>
          Competitor Selected : <span>decathalon.in </span>
        </p>
        <p>
          Date Selected : <span>27-04-2022 </span>
        </p>
        <p>
          Link : <span> https://decathalon.com/deals/lucas-premium-leatherette-l-shape/</span>
        </p>
      </SelectedItems>
      <ScreenShotContainer>
        <ScreenShotDiv>
          <FullScreenImg>
            <img src={fullscreen} />
          </FullScreenImg>
          <ImageWrap></ImageWrap>
        </ScreenShotDiv>
        <KeyObservationDiv>
          <KeyTitle>Key Observations</KeyTitle>
          <KeyPoints>
            <IndexNumber>
              <p>1.</p>
              <p>
                Prominent Banner Update:
                <span>
                  A new banner at the top announces a "Christmas Day Sale" with
                  offers like discounts or free shipping.
                </span>
              </p>
            </IndexNumber>
          </KeyPoints>
          {/* <p>
              Focused Deals Section:
              <span>
                Key offers, such as “Top Christmas Picks” or time-limited deals,
                are highlighted prominently with a countdown timer to create
                urgency
              </span>
            </p>
            <p>
              Festive Visual Elements:
              <span>
                The page features a holiday themed design, including festive
                colors (red and green), snowflake patterns, or gift icons to
                create a seasonal fee
              </span>
            </p> */}
        </KeyObservationDiv>
      </ScreenShotContainer>
    </ScreenWrapper>
  );
};

export default ScreenshotWrap;
