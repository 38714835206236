import styled from "styled-components";

export const AiAnalysisWrap = styled.div`
  padding: 24px 50px;
`;
export const AnalysisTitle = styled.div`
  color: rgba(28, 28, 28, 0.9);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`;
export const AnalysisDataWrap = styled.div`
  padding-top: 24px;
  width: 100%;
  display: flex;
  gap: 24px;
`;
export const NumberOfData = styled.div`
  display: flex;
  gap: 24px;
  width: 50%;
  flex-wrap: wrap;
`;
export const KeywordTable = styled.div`
  width: 50%;
  height: 408px;
  border-radius: 16px;
  background: #fff;
`;
export const DataWraper = styled.div`
  border-radius: 16px;
  background: #fff;
  width: 276px;
  height: 120px;
  padding: 28px 24px;
  p {
    color: #b8b8b8;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.2px;
  }
`;
export const DataDiv = styled.div`
  color: rgba(28, 28, 28, 0.8);
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.2px;
  padding-top: 19px;
  display: flex;
  align-items: end;
  gap: 10px;
  &.bolder {
    color: #1c1c1c;
  }

  span {
    color: #25cd25;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.36px;
  }
  p {
    color: rgba(28, 28, 28, 0.7);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
  }
`;
export const TableHead = styled.div`
  background: #f6f7fb;
  padding: 9px 24px;
  display: flex;
  p {
    color: #b8b8b8;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.2px;
  }
`;
export const TableBody = styled.div``;
export const TableTitle = styled.div`
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.2px;
  padding: 24px 24px 20px 24px;
`;

export const TableDataWrap = styled.div`
  padding: 16px 24px;
  display: flex;
  p {
    color: rgba(28, 28, 28, 0.7);
    font-size: 14px;
    font-weight: 500;
  }
`;
