import React from "react";
import {
  CancelImg,
  InfoDiv,
  OfferContainer,
  OfferInfoWrap,
  OfferOverlay,
  OfferViewWrap,
  OfferWrapper,
  WhiteDiv,
  VerticalLine,
  BottomWrap,
  ExclusiveText,
  AccessInfo,
  Cost,
  TryNow,
  TimeWrap
} from "./index.styled";
import cancelIcon from "../../../../media/Dashboard/closeIcon.svg";
import { useTimer } from "react-timer-hook";
import { useAuth } from "../../../../context/auth";
import { useSubscription } from "../../../hooks/useSubscription";
import rolling from "../../../../media/Dashboard/Rolling.svg";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";

const ExclusiveOffer = () => {
  const { authState, authDispatch } = useAuth();
  const subscriptionHook = useSubscription();
  const useGetUserInfoHook = useGetUserInfo();

  const { seconds, minutes, hours, days, isRunning } = useTimer({
    expiryTimestamp: new Date(authState.showDayPopup.expiredAt),
    onExpire: () => {
      authDispatch({ type: "SET_SHOW_DAY", value: null });
      authDispatch({ type: "SET_NOTIFIED_COUNT", value: null });
    },
  });
  const handleClaim = () => {
    subscriptionHook.CreateSubscriptionMutate({
      planId: authState.showDayPopup.associatedPlanId,
      offerCode: authState.showDayPopup.offerCode,
    });
    useGetUserInfoHook.setOfferNotified({
      offerId: authState.showDayPopup.id,
    });
  };
  return (
    <OfferOverlay>
      <OfferContainer>
        <OfferWrapper>
          <OfferViewWrap>
            <WhiteDiv></WhiteDiv>
            <InfoDiv>
              Over 10,000+ Professionals trust adspyder
              <VerticalLine></VerticalLine>
            </InfoDiv>
            <BottomWrap>
              <ExclusiveText>
                Exclusive
                <p>OFFER</p>
              </ExclusiveText>
              <WhiteDiv className="bottomDiv">First Time user only</WhiteDiv>
            </BottomWrap>
          </OfferViewWrap>
          <OfferInfoWrap>
            <AccessInfo>Get Full Access to AdSpyder for </AccessInfo>
            <Cost>Just $1!</Cost>
            <p>
              Don’t miss out—Offer expires in <TimeWrap>{` ${days}:${hours}:${minutes}:${seconds}`}</TimeWrap>
            </p>
            <TryNow onClick={handleClaim} isLoading={!subscriptionHook.isSubscriptionLoading}>
              {!subscriptionHook.isSubscriptionLoading ? (
                "Try for $1 now >>>"
              ) : (
                <img src={rolling} />
              )}
            </TryNow>
          </OfferInfoWrap>
        </OfferWrapper>
        <CancelImg>
          <img
            src={cancelIcon}
            width={24}
            onClick={() => {
              useGetUserInfoHook.setOfferNotified({
                offerId: authState.showDayPopup.id,
              });
              authDispatch({ type: "SET_SHOW_DAY", value: null });
              authDispatch({
                type: "SET_NOTIFIED_COUNT",
                value: authState.showDayPopup,
              });
            }}
          />
        </CancelImg>
      </OfferContainer>
    </OfferOverlay>
  );
};

export default ExclusiveOffer;
