import styled from "styled-components";

export const SuccessWrap = styled.div`
  padding: 94px 36px 36px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative;
  p {
    color: #5f5f5f;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    &.redirectClass {
      padding-top: 16px;
    }
  }
`;
export const CancelAnytime = styled.div`
  color: #484747;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
`;
export const FeedInfo = styled.div`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 160%;
  max-width: ${(props) => (props.isAnimated ? "314px" : "437px")};
`;
export const OfferOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferContainer = styled.div`
  border-radius: 24px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 506px;
  height: 566px;
  overflow: hidden;
  position: relative;
`;
export const OrangeImg = styled.img`
  position: absolute;
`;
export const AdspyderImg = styled.img`
  position: absolute;
  top: 24px;
  left: 22px;
  height: 32px;
`;
export const ContainerHead = styled.div`
  position: relative;
  height: 69px;
`;
export const DotImg = styled.img`
  width: 100%;
  position: absolute;
`;
export const BonusText = styled.div`
  position: absolute;
  right: 24px;
  top: 31px;
  color: #e65130;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
`;
export const BlueRectDiv = styled.div`
  position: absolute;
  height: 108px;
  right: 0;
  top: 157px;
  width: 66px;
  border-radius: 14px;
  background: #c7f0ff;
  filter: blur(42px);
`;
