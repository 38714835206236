import styled from "styled-components";

export const LinkedInWrap = styled.div`
  padding: 38px 34px 0 34px;
  position: relative;
`;
export const LinkedInInfo = styled.div`
  color: #5f5f5f;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  animation: slideFromDown 500ms;

  @keyframes slideFromDown {
    from {
      transform: translate(5%, 230%);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`;
export const CreateWrap = styled.div`
  border-radius: 10px;
  border: 1px solid #0b73af;
  background: #eaf4ff;
  width: 438px;
  height: 269px;
  margin-top: 16px;
`;
export const CreateInfo = styled.div`
  height: 42px;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #0b69c7;
  p {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
`;
export const CreatedText = styled.div`
  height: 85%;
  p {
    color: #404040;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 24px */
    letter-spacing: 0.16px;
    height: 98%;
    overflow-y: auto;
    ::-webkit-scrollbar-track {
      box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
      background-color: rgb(245 245 245 / 92%);
      border-radius: 10px;
    }

    ::-webkit-scrollbar {
      width: 5px;
      background-color: rgb(245 245 245 / 92%);
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgb(211 211 211 / 92%);
      border-radius: 10px;
    }
  }
  padding: 13px 8px 13px 13px;

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  /* Blinking caret effect */
  @keyframes blink {
    from {
      border-right-color: black;
    }
    to {
      border-right-color: transparent;
    }
  }

  /* Optional: Caret wiggle effect */
  @keyframes caret {
    50% {
      border-right-color: black;
    }
    100% {
      border-right-color: transparent;
    }
  }
`;
export const ShareBtn = styled.div`
  text-align: center;
  margin-top: 24px;
  cursor: pointer;
  pointer-events: ${(props) => (props.isLoading ? "none" : "")};
  opacity: ${(props) => (props.isLoading ? "0.5" : "")};
  height: 44px;
  background: ${(props) => (props.isLoading ? "#C2C5C7" : "#FF711E")};
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.16px;
  border-radius: 4px;

  a {
    text-decoration: none;
    color: #fff;
    display: block;
    padding-top: 10px;
  
  }
`;

export const OnlyOffer = styled.div`
  color: #5f5f5f;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  text-align: center;
  margin-top: 9px;
`;
