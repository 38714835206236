import styled from "styled-components";

export const OfferAnounceTimer = styled.div`
display: flex;
align-items: center;
gap:5px;
margin-right: 20px;
cursor: pointer;
transition-duration: 0.3s;
padding: 0px 10px;
border-radius: 20px;

    background: #f5712070;
    filter: drop-shadow(1px 4px 3px rgba(17, 25, 38, 0.15));
    position: relative;
    overflow: hidden;   

    &::after {
        content: "";
        width: 30px;
        height: 200px;
        position: absolute;
        left: -20px;
        top: 0;
        margin-top: -20px;
        background: #fff;
        opacity: 0.6;
        filter: blur(20px);
        animation: login-blur 3s infinite;
        animation-delay: 0s;
        transform-origin: top;
    }

    @keyframes login-blur {
    from {
        transform: translate(0) rotate(45deg);
    }
    to {
        transform: translateX(370px) rotate(45deg);
    }
    }
`;
export const TimerDiv = styled.div`
border-radius: 5px;
background: #CD332A;
padding: 2px 4px;
color: #FFF;
font-size: 13px;
font-weight: 500;
width: 90px;
display: flex;
justify-content: center;
cursor: pointer;    
`;