import React, { useEffect } from "react";
import {
  Details,
  DetailsAdContent,
  DetailsContainer,
  DetailsWrap,
  GenerateBtn,
  ScrollWraper,
} from "./index.styled";
import downArrow from "../../../../../../../../../../media/Dashboard/down-arrow-grey.svg";
import textGenStatic from "../../../../../static";
import { useTextAdGenerator } from "../../../../../../context/textAdGeneration";
import BrandDetails from "./components/BrandDetails/index";
import TargetAudience from "./components/TargetAudience";
import SeedKeyword from "./components/SeedKeyword";
import { useTextAdGenInfo } from "../../../../../../../hooks/useTextAdGenInfo";
import { getSearchParams } from "../../../../../../../../../../utils/helper";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../../../../../../../../context/auth";
import { useAdGenerator } from "../../../../../../../context/adGeneration";
import rolling from "../../../../../../../../../../media/Dashboard/Rolling.svg";
const DetailsContent = () => {
  const { detailsAdGroup } = textGenStatic;
  const {
    textAdGeneratorState: { isDetailsToggle },
    textAdGeneratorDispatch,
  } = useTextAdGenerator();
  const {
    adGeneratorState: { isAdDetails },
    adGeneratorDispatch,
  } = useAdGenerator();
  const location = useLocation();
  const { authState, authDispatch } = useAuth();
  const searchParamFromState = getSearchParams(location.search);

  const { id, adId, adPlatform } = searchParamFromState;
  const { getAdDetails, getUpdateAd, isUpdateAdLoading } = useTextAdGenInfo();

  const renderDetailList = (itemNumber) => {
    switch (itemNumber) {
      case 0:
        return <BrandDetails />;
      case 1:
        return <TargetAudience />;
      case 2:
        return <SeedKeyword />;
        break;
      default:
        break;
    }
  };

  const generateFunction = () => {
    getUpdateAd({
      projectId: authState.defaultProjectInfo.projectId,
      userAdId: id,
      adGoal: isAdDetails.adDetails.adGoal,
      adGoalDescription: "adGoalDescription",
      destinationUrl: isAdDetails.adDetails.domain,
      location: isAdDetails.adDetails.locations,
      brandName: isAdDetails.adDetails.brandName,
      productDetails: isAdDetails.adDetails.pageDetailSummary,
      productDescription: isAdDetails.adDetails.brandDescription,
      age: isAdDetails.adDetails.age,
      gender: isAdDetails.adDetails.gender,
      language: isAdDetails.adDetails.language,
      occupation: isAdDetails.adDetails.occupation,
      seedKeywords: isAdDetails.adDetails.seedKeywords,
      adId: isAdDetails.adDetails.adId && isAdDetails.adDetails.adId,
      adPlatform: isAdDetails.adDetails.adPlatform && isAdDetails.adDetails.adPlatform,
    });
  };

  useEffect(() => {
    getAdDetails({userAdId: id});
  }, []);

  return (
    <DetailsAdContent>
      <DetailsWrap>
        <h3>Details</h3>
        <ScrollWraper>
          {detailsAdGroup.map((item, index) => {
            return (
              <DetailsContainer props={item.title == isDetailsToggle}>
                <Details
                  onClick={() =>
                    textAdGeneratorDispatch({
                      type: "SET_DETAILS_TOGGLE",
                      value: item.title == isDetailsToggle ? null : item.title,
                    })
                  }
                  props={item.title == isDetailsToggle}
                >
                  <p>{item.title}</p>
                  <img src={downArrow} />
                </Details>
                {item.title == isDetailsToggle && renderDetailList(index)}
              </DetailsContainer>
            );
          })}
        </ScrollWraper>
      </DetailsWrap>
      <GenerateBtn onClick={generateFunction} isDisabled={isUpdateAdLoading}>
        {isUpdateAdLoading ? <img src={rolling} /> : "Generate new"}
      </GenerateBtn>
    </DetailsAdContent>
  );
};

export default DetailsContent;
