import styled from "styled-components";

export const ScreenWrapper = styled.div`
  max-width: 1176px;
`;

export const SelectedItems = styled.div`
  border-radius: 8px;
  background: #efefef;
  height: 37px;
  display: flex;
  gap: 31px;
  align-items: center;
  padding-left: 16px;
  p {
    color: #2e2e2e;
    font-size: 14px;
    font-weight: 600;
    span {
      color: #8b8b8b;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
export const ScreenShotContainer = styled.div`
  display: flex;
  gap: 14px;
  padding-top: 24px;
`;
export const ScreenShotDiv = styled.div`
  border-radius: 8px;
  border: 7px solid #fff;
  background: linear-gradient(290deg, #f9f2d0 0%, #a2f6a2 100%);
  width: 836px;
  height: 631px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const FullScreenImg = styled.div`
  text-align: end;
  padding: 18px 20px 0 0;
  width: 100%;
  img {
    height: 20px;
    cursor: pointer;
  }
`;
export const ImageWrap = styled.div`
  border-radius: 4px;
  border: 8px solid #fff;
  background: url(<path-to-image>) lightgray -70px -41px / 120.934% 107.305%
    no-repeat;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2),
    0px 0px 2px 0px rgba(0, 0, 0, 0.08), 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  width: 664px;
  height: 561.25px;
  overflow-y: auto;
`;
export const KeyObservationDiv = styled.div`
  width: 326px;
  height: 631px;
  border-radius: 8px;
  background: #fff;
  padding: 24px;
`;
export const KeyTitle = styled.div`
  color: #2e2e2e;
  font-size: 14px;
  font-weight: 600;
`;
export const KeyPoints = styled.div`
padding-top: 24px;
  p {
    color: #1c1c1c;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    span {
      color: rgba(28, 28, 28, 0.8);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
`;
export const IndexNumber = styled.div`
display: flex;
gap: 10px;
`;