import styled, { keyframes } from "styled-components";

export const OfferOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OfferContainer = styled.div`
  border-radius: 24px;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 506px;
  height: 566px;
  overflow: hidden;
  position: relative;
`;
export const OrangeImg = styled.img`
  position: absolute;
`;
export const AdspyderImg = styled.img`
  position: absolute;
  top: 24px;
  left: 22px;
  height: 32px;
`;
export const ContainerHead = styled.div`
  position: relative;
  height: 69px;
`;
export const DotImg = styled.img`
  width: 100%;
  position: absolute;
`;
export const BonusText = styled.div`
  position: absolute;
  right: 24px;
  top: 31px;
  color: #e65130;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
`;
export const BlueRectDiv = styled.div`
  position: absolute;
  height: 108px;
  right: 0;
  top: 157px;
  width: 66px;
  border-radius: 14px;
  background: #c7f0ff;
  filter: blur(42px);
`;
export const ShareThought = styled.div`
  padding: 94px 32px 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19px;
  animation: ${(props) =>
    props.isAnimated ? "slideInFromBottom 1s ease-out forwards" : ""};
  height: 100%;
  position: absolute;
  width: 100%;
  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    70% {
      transform: translateY(-5%);
      opacity: 1;
    }
    100% {
      transform: translateY(0%);
    }
  }

  animation: ${(props) => (props.isFade ? "fadeOut 1s" : "")};
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.1;
    }
  }
  animation: ${(props) => (props.isSlideUp ? "slideUp 1s" : "")};

  @keyframes slideUp {
    from {
      transform: translateY(0%);
      opacity: 1;
    }
    to {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  p {
    color: #5f5f5f;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
`;
export const FeedInfo = styled.div`
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 160%;
  max-width: ${(props) => (props.isAnimated ? "314px" : "437px")};
`;

export const AdspyFeature = styled.div`
  padding: 38px 0 0 30px;
  animation: fadeIn 1.3s forwards;
  position: absolute;
  width: 100%;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const TabToggleWrap = styled.div`
  display: flex;
  gap: 8px;
  p {
    border-radius: 9px;
    background: #ff985c;
    width: 57px;
    height: 9px;
  }
`;
export const FeatureInfo = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 31px;
`;
export const ChoiceList = styled.div`
  position: relative;
  padding-top: 27px;
`;

export const ChoiceDiv = styled.div`
  border-radius: 6px;
  border: ${(props) =>
    props.isActive ? "1px solid #ff711e" : "1px solid #DFDFDF"};
  background: #fff;
  width: 442px;
  height: 60px;
  padding: 7px 6px 13px 13px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 12px;
  cursor: pointer;
  p {
    padding-top: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 19px;
    color:${(props)=>props.isActive ? '#000':'#575757'};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    img {
      border-radius: 3px;
      border: 1px solid #ebebeb;
      background: #fff;
      padding: 7px;
      height: 34px;
    }
  }
`;
export const UnLockStep = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
  padding: 24px 28px 0 0;
  color: #8a8a8a;
  font-size: 14px;
  font-weight: 400;
  img {
    border-radius: 6px;
    background: #ff711e;
    padding: 10px;
    cursor: pointer;
    opacity: ${(props) => (props.isDisabled ? "0.5" : "")};
    pointer-events: ${(props) => (props.isDisabled ? "none" : "all")};
  }
`;
