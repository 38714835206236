import React from "react";
import {
  ClaimAccess,
  LifeTimePlan,
  LifeTimePrice,
  LimitedDiv,
  OfferContainer,
  OfferHead,
  OfferOverlay,
  PlanAmount,
  PlanWrap,
  SpotsLeft,
  StandardPlan,
  WrapperDiv,
} from "./index.styled";
import rolling from "../../../../media/Dashboard/Rolling.svg";

import cancelIcon from "../../../../media/Dashboard/crossWhite.svg";
import { useAuth } from "../../../../context/auth";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import { appClient } from "../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";

const OneTimeSubscription = async (data) => {
  const config = {
    url: "/payment/session/checkout-one-time-charges",
    method: "POST",
    data,
  };

  return appClient(config);
};

const ExclusivePurpleVersion = () => {
  const { authState, authDispatch } = useAuth();
  const useGetUserInfoHook = useGetUserInfo();

  const { mutate: CreateOneTimeMutate, isLoading: isCreateOneTimeLoading } =
    useMutation(OneTimeSubscription, {
      onSuccess: (data) => {
        window.open(data, "_blank").focus();
      },
      onError: (err) => {
        console.log(err);
      },
    });

  const handleClaim = () => {
    CreateOneTimeMutate({
      chargeCode: authState.showDayPopup.offerCode,
      currency: localStorage.getItem("usercurrency"),
    });
    useGetUserInfoHook.setOfferNotified({
      offerId: authState.showDayPopup.id,
    });
  };

  return (
    <OfferOverlay>
      <OfferContainer>
        <OfferHead>
          Lifetime Offer for Power Users!
          <img
            src={cancelIcon}
            onClick={() => {
              useGetUserInfoHook.setOfferNotified({
                offerId: authState.showDayPopup.id,
              });
              authDispatch({ type: "SET_SHOW_DAY", value: null });
              authDispatch({
                type: "SET_NOTIFIED_COUNT",
                value: authState.showDayPopup,
              });
            }}
          />
        </OfferHead>
        <WrapperDiv>
          <PlanWrap>
            <StandardPlan>
              <p>Standard plan for 1year</p>
              <PlanAmount>$1800/yr</PlanAmount>
            </StandardPlan>
            <LifeTimePlan>
              <p>Lifetime AdSpy access for</p>
              <LifeTimePrice>$1000</LifeTimePrice>
            </LifeTimePlan>
          </PlanWrap>
          <LimitedDiv>(Limited to your account!)</LimitedDiv>
          <ClaimAccess
            isLoading={!isCreateOneTimeLoading}
            onClick={handleClaim}
          >
            <p>
              {!isCreateOneTimeLoading ? (
                "CLAIM LIFETIME ACCESS NOW>>"
              ) : (
                <img src={rolling} />
              )}
            </p>
          </ClaimAccess>
          <SpotsLeft>Only 20 spots left today!</SpotsLeft>
        </WrapperDiv>
      </OfferContainer>
    </OfferOverlay>
  );
};

export default ExclusivePurpleVersion;
