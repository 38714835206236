import React, { act, useMemo } from 'react'
import { useReducer, createContext, useContext } from 'react'

import produce from 'immer'

const initialState = {
    formEmail: "",
    formPhoneOtp: "",
    formPhoneNo: "",
    // user data
    userId: "",
    email: "",
    username: "",
    profileImage: "",
    signupType: "",
    createdAt: "",
    emailVerified: false,
    phone: "",
    phoneVerified: false,
    old: false,
    notified: false,
    isAuthenticated: false,
    postloginSurvey: false,
    isBusinessEmail: false,
    userInfoAdded: false,
    defaultProjectInfo: null,
    activeProjectInfo: null,
    tourCompleted: false,
    // Login states
    businessEmailFound: false,

    // user loaction
    userLocation: {
        currency: '',
        region: '',
        regionName: '',
        countryCode: '',
        country: ''
    },

    isLoading: true,
    timer: 0,

    // popup states
    feedbackPopup: false,
    isRestrictedPopup: false,
    isPPCRestrictedPopup: false,
    isFeatureRestrictedPopup: false,
    isDomainAnalysisRestrictedPopup: false,
    saveAdsPopupOpen: false,
    planExpiredPopup: false,

    // plan details
    plan: {

    },

    // feature details
    features: {

    },

    // saved ads and projects
    selectedFavouriteAdId: '',
    favAdsList: {},
    savedAds: [],
    savedAdsIds: [],

    project: {
        selectedPlatform: '',
        tags: [],
        selectedTags: [],
        collections: [],
        selectedCollections: [],
        savedAds: []
    },
    projects: null,
    selectedProject: null,
    // Navbar data
    searchMode: 'keyword',
    collapsedMenus: ['Ad Library', 'Keywords Analysis', 'Domain Analysis', 'Landing Page (LP)'],
    isNavCollapsed: false,
    navHovered: false,
    activeMenuSection: "",

    // SEARCH STATE

    isCrawlRequestPage: false,
    limitExceeded: false,
    searchError: '',
    searchPlatform: 'google',
    searchPlatformError: false,
    /* KEYWORD */
    keywordSearchType: "phrase",
    /* Keyword broad match state */
    keywords: [],
    keywordInputError: false,
    broadMinShouldMatch: {
        name: 'Min. match',
        value: 0
    },
    phraseInput: '',
    /* URL */
    urlSearchType: "exact",
    // selectedUrlSearchType: "domain",
    domainUrlInput: {
        value: '',
        valid: false
    },
    urlInputError: false,

    searchDisabled: false,

    // REQUEST CRAWL DATA
    isDataRequested: false,

    trackingKeywords: [],
    trackingCompetitors: [],
    showCreditExpiredModal: false, 
    showAiProcessedPopup: false,
    isSaleBar: true,
    showDayPopup: null, 
    offerData: null,
    setNotifiedCount: null,
    selectedAnswers: [],
    openFeedBack: false,
    linkedInPost: null,
    openSuccessPopup:false
}

const AuthReducer = produce((draft, action) => {
    switch (action.type) {
        case 'UPDATE_FORM_EMAIL':
            draft.formEmail = action.value
            break
        case 'UPDATE_FORM_PHONE_OTP':
            draft.formPhoneOtp = action.value
            break
        case 'UPDATE_FORM_PHONE_NUMBER':
            draft.formPhoneNo = action.value
            break
        case 'UPDATE_LIMIT_EXCEEDED':
            draft.limitExceeded = action.value
            break
        case 'UPDATE_SEARCH_ERROR':
            draft.searchError = action.value
            break
        case 'CURRENT_USER_UPDATE':
            draft.userId = action.data.id
            draft.username = action.data.username
            draft.email = action.data.email
            draft.profileImage = action.data.profileImage
            draft.signupType = action.data.signupType
            draft.createdAt = action.data.createdAt
            draft.isAuthenticated = action.isAuthenticated
            draft.emailVerified = action.data.emailVerified
            draft.postloginSurvey = action.data.onboardingCompleted
            draft.phone = action.data.phone
            draft.phoneVerified = action.data.phoneVerified
            draft.isBusinessEmail = action.data.isBusinessEmail
            draft.userInfoAdded = action.data.userInfoAdded
            draft.tourCompleted = action.data.tourCompleted
            draft.old = action.data.old
            draft.notified = action.data.notified
            draft.userLocation = {...draft.userLocation, "countryCode": action.data.countryCode}

            draft.plan = action.data.plan ? action.data.plan : {}
            draft.features = action.data.features ? action.data.features : {}
            draft.defaultProjectInfo = action.data.defaultProjectInfo
            break

        case "UPDATE_DEFAULT_PROJECT_INFO":
            draft.defaultProjectInfo = { ...draft.defaultProjectInfo, ...action.value }
            break

        case "UPDATE_ACTIVE_PROJECT_INFO":
            draft.activeProjectInfo = action.value
            break

        case 'UPDATE_NOTIFIED':
            draft.notified = action.value
            break

        case 'SET_SEARCH_PLATFORM':
            draft.searchPlatform = action.value
            break

        case 'SET_SEARCH_PLATFORM_ERROR':
            draft.searchPlatformError = action.value
            break

        case 'SET_IS_AUTHENTICATED':
            draft.isAuthenticated = action.value
            break

        case 'SET_PLAN_DETAILS':
            draft.plan = action.value ? action.value : {}
            break

        case 'SET_EMAIL_VERIFIED':
            draft.emailVerified = true
            break

        case 'SET_PHONE_VERIFIED':
            draft.phoneVerified = true
            break
        case 'SET_SALE_BAR':
            draft.isSaleBar =  action.value
            break
        case 'LOGOUT_SUCCESS':
            draft.userId = ""
            draft.username = ""
            draft.email = ""
            draft.profileImage = ""
            draft.signupType = ""
            draft.createdAt = ""
            draft.isAuthenticated = false
            draft.emailVerified = false
            draft.postloginSurvey = false
            draft.timer = 0
            draft.isLoading = false
            break
        case 'START_LOADER':
            draft.isLoading = true
            break
        case 'STOP_LOADER':
            draft.isLoading = false
            break
        case 'SET_POST_LOGIN_SURVEY_STATUS':
            draft.postloginSurvey = action.value
            break
        case 'UPDATE_TIMER':
            draft.timer = action.value
            break

        case 'UPDATE_USER_LOCATION':
            draft.userLocation = action.value
            break

        case 'UPDATE_USER_PHONE_CODE':
            draft.userLocation = {...draft.userLocation, "countryCode": action.value}
            break

        case 'UPDATE_IS_BUSINESS_EMAIL':
            draft.isBusinessEmail = action.value
            break

        case 'UPDATE_IS_RESTRICTED':
            draft.isRestrictedPopup = action.value
            break

        case 'UPDATE_IS_PPC_RESTRICTED':
            draft.isPPCRestrictedPopup = action.value
            break

        case 'UPDATE_IS_FEATURE_RESTRICTED':
            draft.isFeatureRestrictedPopup = action.value
            break

        case 'UPDATE_IS_DOMAIN_ANALYSIS_RESTRICTED':
            draft.isDomainAnalysisRestrictedPopup = action.value
            break

        case 'UPDATE_FEEDBACK_POPUP':
            draft.feedbackPopup = action.value
            break

        case 'UPDATE_SEARCHES_MADE':
            draft.features.searchesMade = action.value
            break

        case 'UPDATE_SAVE_AD_POPUP':
            draft.saveAdsPopupOpen = action.value
            break

        case 'UPDATE_PLAN_EXPIRED_POPUP':
            draft.planExpiredPopup = action.value
            break

        case 'UPDATE_PROJECT_TAGS':
            draft.project.tags = action.value
            break

        case 'UPDATE_PROJECT_SELECTED_TAGS':
            draft.project.selectedTags = action.value
            break

        case 'UPDATE_PROJECT_SELECTED_PLATFORM':
            draft.project.selectedPlatform = action.value
            break

        case 'UPDATE_PROJECT_COLLECTIONS':
            draft.project.collections = action.value
            draft.projects = action.value
            break

        case 'SET_PROJECT_LIST':
            draft.projects = action.value
            break

        case 'SET_BROAD_MIN_SHOULD_MATCH':
            draft.broadMinShouldMatch = action.value
            break
            
        case 'UPDATE_PROJECT_SELECTED_COLLECTIONS':
            draft.project.selectedCollections = action.value
            break

        case 'SET_PHRASE_INPUT':
            draft.phraseInput = action.value
            break

        case 'SET_URL_SEARCHTYPE':
            draft.urlSearchType = action.value
            break

        case 'UPDATE_SAVED_ADS':
            draft.project.savedAds = action.value
            break

        case 'SET_SELECTED_FAVOURITE_AD_ID':
            draft.selectedFavouriteAdId = action.value
            break

        case 'SET_FAV_ADS_LIST':
            draft.favAdsList = action.value
            break

        case 'SET_ADSPY_SEARCH_MODE':
            draft.searchMode = action.value
            break

        case 'SET_COLLAPSED_MENUS':
            draft.collapsedMenus = action.value
            break

        case 'SET_IS_NAV_COLLAPSED':
            draft.isNavCollapsed = action.value
            break

        case 'SET_ACTIVE_MENU_SECTION':
            draft.activeMenuSection = action.value
            break

        case 'SET_NAV_HOVERED_STATE':
            draft.navHovered = action.value
            break

        case 'SET_IS_CRAWL_REQUEST_PAGE':
            draft.isCrawlRequestPage = action.value
            break

        case 'SET_DOMAIN_URL_INPUT':
            draft.domainUrlInput = action.payload
            break

        case 'SET_BUSINESS_EMAIL_FOUND':
            draft.businessEmailFound = action.value
            break

        case 'SET_KEYWORD_SEARCHTYPE':
            draft.keywordSearchType = action.value
            break

        case 'SET_KEYWORDS':
            draft.keywords = action.value
            break

        case 'SET_KEYWORD_INPUT_ERROR':
            draft.keywordInputError = action.value
            break

        case 'SET_URL_INPUT_ERROR':
            draft.urlInputError = action.value
            break

        case 'SET_SEARCH_DISABLED':
            draft.searchDisabled = action.value
            break

        case 'SET_SAVED_ADS':
            draft.savedAds = action.value
            break

        case 'SET_SAVED_ADS_IDS':
            draft.savedAdsIds = action.value
            break

        case 'SET_IS_DATA_REQUESTED':
            draft.isDataRequested = action.value
            break

        case 'SET_IS_APP_TOUR_DONE':
            draft.tourCompleted = action.value
            break

        case 'SET_SELECTED_PROJECT':
            draft.selectedProject = action.value
            break

        case 'SET_TRACKING_KEYWORDS': 
            draft.trackingKeywords = action.value
        break

        case 'SET_TRACKING_COMPETITORS': 
            draft.trackingCompetitors = action.value
        break

        case 'SET_USER_REMAINING_CREDITS': 
            draft.plan.planName = action.value.planName
            draft.plan.creditLimit = action.value.creditLimit
            draft.plan.creditsSpent = action.value.creditsSpent
            draft.plan.status = action.value.status
        break

        case 'SET_SHOW_CREDIT_EXPIRED_MODAL':
            draft.showCreditExpiredModal = action.value
        break

        case 'SET_SHOW_AI_PROCESSED':
            draft.showAiProcessedPopup = action.value
        break
        case 'SET_SHOW_DAY':
            draft.showDayPopup = action.value
        break
        case 'SET_OFFER_DATA':
            draft.offerData = action.value
        break
        case 'SET_NOTIFIED_COUNT':
            draft.setNotifiedCount = action.value
        break
        case 'SET_SELECTED_ANSWERS':
            draft.selectedAnswers.push(action.value)
        break
        case 'SET_OPEN_FEEDBACK':
            draft.openFeedBack = action.value
        break
        case 'SET_LINKEDIN_POST':
            draft.linkedInPost = action.value
        break
        case 'SET_OPEN_SUCCESS_POPUP':
            draft.openSuccessPopup = action.value
        break

        default:
            break
    }
})

const useAuthReducer = () => {
    return useReducer(AuthReducer, initialState)
}

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useAuthReducer()

    const value = { authState: state, authDispatch: dispatch }

    // const value = useMemo(() => ({
    //     authState: state,
    //     authDispatch: dispatch
    // }), [state, dispatch])

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}
