import { countryNewData } from "./countryData";

export function GetCountryData(countryName){
    console.log(countryName)
    let filteredCountry = countryNewData.filter((data) => {
        return (countryName == data.adspyder_name)
    })

    return filteredCountry

}

function getCountryByDialingCode(dialingCode) {
  const country = countryNewData.find((c) => c.dial_code === dialingCode);

  if (country) {
    return country;
  }

  return null;
}

export default getCountryByDialingCode;
