import React, { useEffect } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import {
  AdspyderImg,
  BlueRectDiv,
  BonusText,
  CancelAnytime,
  ContainerHead,
  DotImg,
  FeedInfo,
  OfferContainer,
  OfferOverlay,
  OrangeImg,
  SuccessWrap,
} from "./index.styled";
import dotsImg from "../ExclusiveAdspyVersion/assets/dotsImg.png";
import rect from "../ExclusiveAdspyVersion/assets/Rec.png";
import AdspyLogo from "../FeedBackExclusive/assets/adspyderLogo.png";
import { useSubscription } from "../../../hooks/useSubscription";
import { useAuth } from "../../../../context/auth";

const plan = {
  INR: "Freelancer-Special-INR-Monthly",
  USD: "Freelancer-Special-USD-Monthly",
};

const SuccessPopup = () => {
  const subscriptionHook = useSubscription();
  const { authState, authDispatch } = useAuth();

  useEffect(() => {
    authDispatch({ type: "SET_SHOW_DAY", value: null });
    authDispatch({
      type: "SET_NOTIFIED_COUNT",
      value: authState.showDayPopup,
    });
    setTimeout(() => {
      subscriptionHook.CreateSubscriptionMutate({
        planId: plan[localStorage.getItem("usercurrency")],
        trial: true,
      });
    }, 10000);
  }, []);

  return (
    <OfferOverlay>
      <OfferContainer>
        <ContainerHead>
          <OrangeImg src={rect} />
          <DotImg src={dotsImg} />
          <AdspyderImg src={AdspyLogo} />
          <BonusText>The Feedback Bonus</BonusText>
          <BlueRectDiv></BlueRectDiv>
        </ContainerHead>
        <SuccessWrap>
          <FeedInfo>
            <ConfettiExplosion />
            🎉 Success! Your First Month is FREE! 🚀!
          </FeedInfo>
          <p className="redirectClass">
            Awesome! You’ve unlocked your first month FREE with AdSpyder. Your
            feedback is shaping the future of our tools – thank you! 🙌
          </p>
          <CancelAnytime>
            Next, enjoy full access for just $30/month after your free month. No
            worries, cancel anytime!
          </CancelAnytime>
          <p>Hang tight! You’ll be redirected to complete your offer.</p>
        </SuccessWrap>
      </OfferContainer>
    </OfferOverlay>
  );
};

export default SuccessPopup;
