import styled from "styled-components";

export const LocationWrap = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
  p {
    color: #1e1e1e;
    font-size: 24px;
    font-weight: 700;
  }
`;
export const CompHeadWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const CompetitorContainer = styled.div`
  padding: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  .totalTitle {
    color: #1e1e1e;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.456px;
  }
`;
export const CalendarContainer = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid #dadfe4;
  background: #fff;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  img {
    height: 16px;
  }
  .arrowToggle {
    transform: ${(props) => !props.isArrow && "rotate(180deg)"};
  }
`;
export const CalendarDropDown = styled.div`
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 9px 3px;
  z-index: 2;
  height: fit-content;
  .DateRangePicker {
    background: #f8fcff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 9px 3px;
    margin-bottom: unset;
  }
  .DateRangePicker__CalendarSelection {
    background-color: rgb(255, 113, 30);
    border: 1px solid rgb(255, 113, 30);
  }
`;
export const CalenderWrap = styled.div`
  position: relative;
`;
export const CompetitorWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 1084px;
`;
export const CompCommonWrap = styled.div`
display: flex;
gap: 20px;
`;
export const CommonContainer = styled.div`
  height: 424px;
  background: #fff;
  padding: 16px;
  border-radius: 6px;
border: 2px solid #49545C;
width: 50%;
`;
export const CompCommonTitle = styled.div`
  color: #1e1e1e;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%; /* 36px */
  letter-spacing: -0.456px;
`;
