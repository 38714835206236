import styled from "styled-components";

export const AdCountWrapper = styled.div`
background: #fff;
padding: 20px;
border-radius: 6px;
border: 2px solid #49545C;
p{
    margin-bottom: 20px;
}
`;