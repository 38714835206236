import React from "react";
import {
  OfferContainer,
  OfferOverlay,
  BlackContainer,
  CancelImg,
  OfferDetailWrap,
  FullPower,
  CancelInfo,
  OptionWrap,
  FreeOption,
  OptionCount,
  Price,
  ClaimNowBtn,
  Emoji,
} from "./index.styled";
import cancelIcon from "../../../../media/Dashboard/crossWhite.svg";
import EmojiImg from "./assets/Emoji.png";
import rolling from "../../../../media/Dashboard/Rolling.svg";
import { useSubscription } from "../../../hooks/useSubscription";
import { useAuth } from "../../../../context/auth";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";

const ExclusiveBrownVersion = () => {
  const subscriptionHook = useSubscription();
  const { authState, authDispatch } = useAuth();
  const useGetUserInfoHook = useGetUserInfo();

  const handleClaim = () => {
    subscriptionHook.CreateSubscriptionMutate({
      planId: authState.showDayPopup.associatedPlanId,
      offerCode: authState.showDayPopup.offerCode,
    });
    useGetUserInfoHook.setOfferNotified({
      offerId: authState.showDayPopup.id,
    });
  };

  return (
    <OfferOverlay>
      <OfferContainer>
        <CancelImg
          src={cancelIcon}
          onClick={() => {
            useGetUserInfoHook.setOfferNotified({
              offerId: authState.showDayPopup.id,
            });
            authDispatch({ type: "SET_SHOW_DAY", value: null });
            authDispatch({
              type: "SET_NOTIFIED_COUNT",
              value: authState.showDayPopup,
            });
          }}
        />
        <FullPower>Want the Full Power of AdSpyder? ⚡</FullPower>
        <Emoji src={EmojiImg} />
        <BlackContainer>
          <OfferDetailWrap>
            <CancelInfo>
              This offer is EXCLUSIVE for you—don’t wait, act now! ⏳
              <span>Cancel anytime</span>
            </CancelInfo>
            <OptionWrap>
              <FreeOption forFree={false}>
                <OptionCount>Option 1</OptionCount>
                <Price forFree={false}>
                  just<span>$30/month</span>
                </Price>
                <ClaimNowBtn
                  forFree={false}
                  isLoading={!subscriptionHook.isSubscriptionLoading}
                  onClick={handleClaim}
                >
                  {!subscriptionHook.isSubscriptionLoading ? (
                    "CLAIM NOW>>"
                  ) : (
                    <img src={rolling} />
                  )}
                </ClaimNowBtn>
              </FreeOption>
              <FreeOption forFree={true}>
                <OptionCount>
                  Option 2 : <span>The Feedback Bonus</span>
                </OptionCount>
                <Price forFree={true}>
                  First month<span>FREE! 🥳</span>
                </Price>
                <ClaimNowBtn
                  forFree={true}
                  onClick={() => {
                    authDispatch({ type: "SET_OPEN_FEEDBACK", value: true });
                    authDispatch({ type: "SET_SHOW_DAY", value: null });
                    authDispatch({
                      type: "SET_NOTIFIED_COUNT",
                      value: authState.showDayPopup,
                    });
                  }}
                >
                  {"CLAIM NOW>>"}
                </ClaimNowBtn>
              </FreeOption>
            </OptionWrap>
          </OfferDetailWrap>
        </BlackContainer>
      </OfferContainer>
    </OfferOverlay>
  );
};

export default ExclusiveBrownVersion;
