import React, { useEffect, useState } from "react";
import {
  CheckboxContainer,
  CountryData,
  CountryHeader,
  DisplayPrompt,
  ExploreDiv,
  HoverWrapper,
  KeyData,
  KeyHeader,
  OuterWrap,
  PlatformData,
  PlatformHeader,
  Row,
  StatusData,
  StatusHeader,
  TableBody,
  TableHeader,
  TableWrap,
  TableWrapDummy,
  TabToggle,
  TargetKeyword,
  ToggleBtn,
  UpdatedData,
  UpdatedHeader,
} from "./index.styled";
import { usePromptTracking } from "../../context/promtContext";
import { GenerateBtn } from "../index.styled";
import TargetedKeywords from "../TargetedKeywords";

const GenerateKeyComponent = () => {
  const { promptState, promptDispatch } = usePromptTracking();
  const [activeState, setActiveState] = useState(0);
  const [tableData, setTableData] = useState(
    promptState.generateKeyw.categories.locationBased
  );

  const arrSample = [
    {
      option: "Location based",
      data: promptState.generateKeyw.categories.locationBased,
    },
    {
      option: "Intent-Based",
      data: promptState.generateKeyw.categories.searchIntentBased,
    },
    {
      option: "Trend-Based",
      data: promptState.generateKeyw.categories.trendBased,
    },
    {
      option: "CampaignGoal-Based",
      data: promptState.generateKeyw.categories.campaignGoalBased,
    },
    {
      option: "Audience-Based",
      data: promptState.generateKeyw.categories.audienceBased,
    },
  ];

  const handleCheckboxChange = (e, row) => {
    const isChecked = e.target.checked;

    promptDispatch({
      type: "SET_SELECTED_ROWS",
      value: isChecked
        ? [...(promptState.selectedRows || []), row]
        : promptState.selectedRows.filter((selectedRow) => selectedRow !== row),
    });
  };

  return !promptState.targetToggle ? (
    <>
      <DisplayPrompt>
        <div>
          Main prompt:
          {/* <span>Refine prompt again</span> */}
        </div>
        <p>{`${promptState.keywordPlan?.userPrompt}${
          promptState.selectedInputs.location
            ? ` in ${promptState.selectedInputs.location}`
            : ""
        }${
          promptState.selectedInputs.audience
            ? ` for ${promptState.selectedInputs.audience}`
            : ""
        }${
          promptState.selectedInputs.searchInt
            ? ` ${promptState.selectedInputs.searchInt}`
            : ""
        }${
          promptState.selectedInputs.campaignGoal
            ? ` who ${promptState.selectedInputs.campaignGoal}`
            : ""
        } `}</p>
      </DisplayPrompt>
      <ExploreDiv>Explore Keywords for your prompt more focusing on</ExploreDiv>
      <TabToggle>
        {arrSample.map((item, index) => (
          <ToggleBtn
            key={index}
            onClick={() => {
              setActiveState(index);
              setTableData(item.data);
            }}
            isClicked={activeState == index}
          >
            {item.option}
          </ToggleBtn>
        ))}
      </TabToggle>
      <OuterWrap>
        <TableWrap>
          <TableHeader>
            <KeyHeader>Keyword text</KeyHeader>
            <StatusHeader>Search volume</StatusHeader>
            <PlatformHeader>CPC </PlatformHeader>
            <CountryHeader>Relevance Score</CountryHeader>
            <UpdatedHeader>Competition level</UpdatedHeader>
          </TableHeader>

          <TableBody>
            {tableData.length > 0 ? (
              tableData.map((row, rowIndex) => {
                const isChecked = promptState.selectedRows?.some(
                  (selectedRow) => selectedRow.keyword === row.keyword
                );
                return (
                  <Row key={rowIndex}>
                    <CheckboxContainer>
                      <input
                        type="checkbox"
                        id={`checkbox-${rowIndex}`}
                        name={`checkbox-${rowIndex}`}
                        value={row.keyword}
                        checked={isChecked}
                        onChange={(e) => handleCheckboxChange(e, row)}
                      />
                    </CheckboxContainer>
                    <KeyData>{row.keyword}</KeyData>
                    <StatusData>{row.avgSearchVolume}</StatusData>
                    <PlatformData>{row.avgCpc}</PlatformData>
                    <CountryData>{row.relevanceScore}</CountryData>
                    <UpdatedData>{row.competition} </UpdatedData>
                  </Row>
                );
              })
            ) : (
              <p>No data </p>
            )}
          </TableBody>
        </TableWrap>
        <TableWrapDummy>
          <TableHeader style={{ padding: "0 10px" }}>
            <div>Keyword Planner</div>
          </TableHeader>

          <TableBody>
            {promptState.generateKeyw &&
            Array.isArray(promptState.generateKeyw.keywordPlannerKeywords) &&
            promptState.generateKeyw.keywordPlannerKeywords.length > 0 ? (
              promptState.generateKeyw.keywordPlannerKeywords.map(
                (item, index) =>
                  Array.isArray(item) ? (
                    <div key={index}>
                      <h4>Group {index + 1}</h4>
                      {item.map((row, rowIndex) => (
                        <Row key={rowIndex}>
                          <div>{row.keyword}</div>
                        </Row>
                      ))}
                    </div>
                  ) : (
                    <Row key={index}>
                      <div>{item.keyword}</div>
                    </Row>
                  )
              )
            ) : (
              <p>No data available</p>
            )}
          </TableBody>
        </TableWrapDummy>
      </OuterWrap>
      <TargetKeyword
        isActive={
          promptState.selectedRows && promptState.selectedRows.length > 0
        }
      >
        <p
          onClick={() =>
            promptDispatch({ type: "SET_TARGET_TOGGLE", value: true })
          }
        >
          {" "}
          View targeted keywords{" "}
          {`${
            promptState.selectedRows
              ? `(${promptState.selectedRows.length})`
              : ""
          }`}
        </p>
      </TargetKeyword>
    </>
  ) : (
    <TargetedKeywords />
  );
};

export default GenerateKeyComponent;
