import React, {useState} from "react";
import {
  Container,
  RoundImg,
  Card,
  CardBody,
  Header,
  LogoWrapper,
  TitleWrap,
  Title,
  LinkWrap,
  KeywordWrap,
  DescriptionWrap,
  FooterWrap,
  CountryWrap,
  Country,
  GoogleIcon,
  CrownImg,
  TopAlertWrap,
  TopAdAlert,
  ContentWrap,
  WrapContainer,
  TryAdGenerate,
  TryAdGenerateBtn,
  SnapShotList,
  SnapShotContainer,
  SnapStat,
  SnapShotButton,
  SnapDetails,
  AdSection,
  AdDetailsRight,
  SnapDetailsContainer,
  RightStats,
  GenerateButton
} from "./index.styled";
import Round from "../../../../../../media/Dashboard/TryAdGenRound2.svg";
import AI from "../../../../../../media/Dashboard/AI.png";
import EngagementRateImage from "../../../../../../media/Dashboard/EngmntRate.gif";
import AudienceSizeImage from "../../../../../../media/Dashboard/AudienceSize.gif";
import AdHealthImage from "../../../../../../media/Dashboard/AdHealth.gif";
import DownArrow from "../../../../../../media/Dashboard/down-arrow-white.png"
import googleImg from "../../../../../../media/Dashboard/google.svg";
import GlobeIcon from "../../../../../../media/Dashboard/globe.svg";
import DownArrowPurple from "../../../../../../media/Dashboard/up-arrow-purple.png";
import { convertTimestamp } from "../../../../../../utils/dateFormat";
import locationImg from "../../../../../../media/Dashboard/locationCard.svg";
import MagicWand from "../../../../../../media/Dashboard/magic-wand.png"
import Crown from "../../assets/crown.png";
import Star from "../../assets/Star.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../context/auth";
import { getSearchParams } from "../../../../../../utils/helper";
import ReactGA from "react-ga4";

const TryAdGeneration = ({topAd,platform}) => {
  const location = useLocation()
  const navigate = useNavigate()
  
  const searchParamFromState = getSearchParams(location.search)
  
  let adsArray = [{
    country: ["united states of america"],
    brandName: "",
    campaignId: "ADPR-NCHQJYMJWPNE",
    firstSeen: 1733989785980,
    created_at: "2024-12-12T07:53:47.350Z",
    title: "Amazon Book Publishing | Covenant Book Publishing",
    content:
      "Retain 100% of the rights to your work. Download your free author's kit to learn more.",
    domainId: "goto.covenantbooks.com",
    url: "https://goto.covenantbooks.com",
    lastSeen: 1733989785980,
    requestId: "c8cd1256-af0a-4df3-a101-fac9d7c19511",
    siteLinks: [],
    position: -1,
    id: "ADPR-NCHQJYMJWPNE-bbdefa2950f49882f295b1285d4fa9dec45fc4144bfb07ee6acc68762d12c2e3-0",
    updatedAt: 1733990027144,
  },{
    country: ["united states of america"],
    brandName: "",
    campaignId: "ADPR-NCHQJYMJWPNE",
    firstSeen: 1733989785980,
    created_at: "2024-12-12T07:53:47.350Z",
    title: "Amazon Book Publishing | Covenant Book Publishing",
    content:
      "Retain 100% of the rights to your work. Download your free author's kit to learn more.",
    domainId: "goto.covenantbooks.com",
    url: "https://goto.covenantbooks.com",
    lastSeen: 1733989785980,
    requestId: "c8cd1256-af0a-4df3-a101-fac9d7c19511",
    siteLinks: [],
    position: -1,
    id: "ADPR-NCHQJYMJWPNE-bbdefa2950f49882f295b1285d4fa9dec45fc4144bfb07ee6acc68762d12c2e3-0",
    updatedAt: 1733990027144,
  },
  {
    country: ["united states of america"],
    brandName: "",
    campaignId: "ADPR-NCHQJYMJWPNE",
    firstSeen: 1733989785980,
    created_at: "2024-12-12T07:53:47.350Z",
    title: "Amazon Book Publishing | Covenant Book Publishing",
    content:
      "Retain 100% of the rights to your work. Download your free author's kit to learn more.",
    domainId: "goto.covenantbooks.com",
    url: "https://goto.covenantbooks.com",
    lastSeen: 1733989785980,
    requestId: "c8cd1256-af0a-4df3-a101-fac9d7c19511",
    siteLinks: [],
    position: -1,
    id: "ADPR-NCHQJYMJWPNE-bbdefa2950f49882f295b1285d4fa9dec45fc4144bfb07ee6acc68762d12c2e3-0",
    updatedAt: 1733990027144,
  }];

  const [openedDetails, setOpenedDetails] = useState({})

  const  topAdTypes = [
    {
      name: "Engagement Rate",
      count: "> 10%",
      width: "48px",
      height: "40px",
      gif: EngagementRateImage,
      ad: topAd[2]
    },
    {
      name: "Audience Size",
      count: "High",
      width: "60px",
      height: "48px",
      gif: AudienceSizeImage,
      ad: topAd[1]
    },
    {
      name: "Ad Health",
      count: null,
      width: "46px",
      height: "23px",
      gif: AdHealthImage,
      ad: topAd[0]
    }
  ]

  const arrangeHeadings = (openedDetails) => {

    let details = {
      score: `🏆 Ad Score: ${openedDetails.ad.adHealth ? Number(openedDetails.ad.adHealth.toString().slice(0,2))/10 : "8"} /10 `,
      ctr: `📊 CTR: > 10%`,
      engmntRate: `↗️ Engagement rate: > 10%`,
      impression: `👁️ Impressions: High `
    }

    if(openedDetails.name == "Engagement Rate"){
      return details.score + " | " + details.ctr + " | " + details.engmntRate ;
    }else if(openedDetails.name == "Audience Size"){
      return details.score + " | " + details.engmntRate + " | " + details.impression ;
    }else if(openedDetails.name == "Ad Health"){
      return details.score + " | " + details.ctr + " | " + details.engmntRate + " | " + details.impression ;
    } 
  }

  const findHighestScore = () => {

  }

  const trackButtonClick = (buttonName, destinationUrl, context) => {
    ReactGA.event({
      category: "Button", 
      action: "Click", 
      label: buttonName, 
      nonInteraction: false,
      
      customParameters: {
        destination_url: destinationUrl,
        click_context: context,
      },
    });
  };

  return (
    <Container isOpened={openedDetails.name} >
      <WrapContainer>
        <Header>
          <img src={AI}/><h3>Ad Campaign SnapShot</h3>
        </Header>
        <p>Looking for {searchParamFromState.searchMode === "keyword" ? searchParamFromState.keywordSearchType === "broad" ? searchParamFromState.keywords : searchParamFromState.phraseInput: searchParamFromState.domainUrlInput.value}? The top ads excel in innovation, quality, and customer stories. AdSpyder highlights the best ads by Ad Health, Engagement Rate, and Conversion Rates—see how they stand out!</p>
        <SnapShotList>
          {topAdTypes.map((snap, index) => {
            if(snap.ad){
              const score = snap.ad.adHealth
              return <SnapShotContainer isActive={openedDetails.name == snap.name} openedDetails={openedDetails}>
                <SnapStat isActive={openedDetails.name == snap.name} openedDetails={openedDetails}>
                  <img width={snap.width} height={snap.height} src={snap.gif}/>
                  <p>{snap.name}<span>{snap.name == "Ad Health" ? "> " + `${score ? Number(score.toString().slice(0,2))/10: "8"}` + "/10" : snap.count}</span></p>
                </SnapStat>
                <SnapShotButton isActive={openedDetails.name == snap.name} openedDetails={openedDetails} onClick={()=> {
                  setOpenedDetails({})
                  setOpenedDetails(snap)
                }}>
                  Ad Details <img src={DownArrow}/>
                </SnapShotButton>
              </SnapShotContainer>
            }
          })}
        </SnapShotList>
        {openedDetails.name && <SnapDetails>
          <SnapDetailsContainer>
            <div>
              <h3>Ad Details</h3>
              <span onClick={()=> setOpenedDetails({})}>Hide <img src={DownArrowPurple}/></span>
            </div>
            <AdSection>
                <CardBody>
                  <Header>
                    <LogoWrapper>
                      <img src={GlobeIcon} />
                    </LogoWrapper>
                    <TitleWrap>
                      <Title>{openedDetails.ad.domainId}</Title>
                      <LinkWrap>{openedDetails.ad.url}</LinkWrap>
                    </TitleWrap>
                  </Header>
                  <KeywordWrap>{openedDetails.ad.title}</KeywordWrap>
                  <DescriptionWrap>
                    {`${convertTimestamp(openedDetails.ad.lastSeen)} __ ${openedDetails.ad.content}`}
                  </DescriptionWrap>
                  <FooterWrap>
                    <CountryWrap>
                      <img src={locationImg} height={"9px"} />
                      <Country>
                        {openedDetails.ad.country.length == 1
                          ? openedDetails.ad.country[0]
                          : openedDetails.ad.country.length > 1
                          ? openedDetails.ad.country[0] + " + " + (openedDetails.ad.country.length - 1)
                          : "--"}
                      </Country>
                    </CountryWrap>
                    <GoogleIcon>
                      <img src={googleImg} />
                    </GoogleIcon>
                  </FooterWrap>
                </CardBody>
              <AdDetailsRight>
                  <RightStats>{arrangeHeadings(openedDetails, openedDetails)}</RightStats>
                  <p>With data-driven insights like {searchParamFromState.searchMode === "keyword" ? searchParamFromState.keywordSearchType === "broad" ? searchParamFromState.keywords : searchParamFromState.phraseInput: searchParamFromState.domainUrlInput.value} seamless reach to High engaged users, you too can create impactful campaigns. Start generating data-backed ads now!</p>
                  <GenerateButton onClick={()=> {
                    if (window.gtag) {
                      window.gtag("event", "Generate_Winning_Ads_Buttonclick", {
                        event_category: "User Actions",
                        event_label: "Generate Winning Ads",
                        value: 1,
                      });
                    } else {
                      console.warn("Google Analytics not initialized");
                    }
                    // trackButtonClick(
                    //   "Generate_Winning_Ads_Buttonclick",
                    //   "/adgenerator/text-ad",
                    //   "Adspy Page"
                    // );
                    navigate(`/adgenerator/text-ad/input?adId=${openedDetails.ad.id}&adPlatform=${platform}`);
                  }}>
                  {/* <GenerateButton onClick={() => navigate(`/adgenerator/text-ad/input?adId=${openedDetails.ad.id}&adPlatform=${platform}`) }> */}
                    <img src={MagicWand}/>Generate Winning Ads Now
                  </GenerateButton>
              </AdDetailsRight>
            </AdSection>
          </SnapDetailsContainer>
        </SnapDetails>}
      </WrapContainer>
    </Container>
  );
};

export default TryAdGeneration;