import styled from "styled-components";

export const TestWrapper = styled.div`
  padding: 50px;
  width: 100%;
`;
export const ButtonGet = styled.div`
  margin-top: 15px;
  background: rgb(255, 113, 30);
  color: white;
  padding: 10px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: fit-content;
  opacity: ${(props) => (!props.isActive ? "0.5" : "")};
  pointer-events: ${(props) => !props.isActive && "none"};
`;

export const DataList = styled.div`
  margin-top: 35px;
  p {
    margin-bottom: 10px;
  }
  input {
    margin-right: 10px;
  }
`;
export const SuggestedList = styled.div`
  margin: 10px 0;
  p {
    margin: 7px 0;
  }
`;
export const SuggestWrap = styled.div`
  margin-top: 25px;
`;

export const CalendarWrap = styled.div`
  margin-top: 25px;
  position: relative;
  p {
    margin-top: 15px;
    span {
      color: #1f75ca;
    }
  }
  .DateRangePicker {
    background: white;
    position: absolute;
    z-index: 2;
  }
`;
export const ListWrap = styled.div``;
export const WebSiteListDiv = styled.div`
  margin-bottom: 5px;
`;
export const ErrorText = styled.div`
color: red;
margin-top: 22px;
`;
