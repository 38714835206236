import React from "react";
import { useLandingPageAnalysis } from "../../context/landingpage";
import SelectDate from "./SelectDate";
import ListCompetitors from "./ListCompetitors";
import SelectUrl from "./SelectUrl";
import ResultPage from "./ResultPage";
import { useNavigate } from "react-router-dom";


const LandingPageAnalysisHome = () => {
    const { landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis();

    const navigate = useNavigate()

    switch (landingPageAnalysisState.step) {
        case "select-date":
            return <SelectDate />;
    
        case "select-url":
            return <SelectUrl />;

        case "list-competitors":
            return <ListCompetitors />;
            
        default:
            return; // A fallback component if state is invalid
    }
}

export default LandingPageAnalysisHome