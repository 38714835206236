import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { appClient } from "../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import {
  ButtonGet,
  CalendarWrap,
  DataList,
  ErrorText,
  ListWrap,
  SuggestWrap,
  SuggestedList,
  TestWrapper,
  WebSiteListDiv,
} from "./index.styled";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from "moment";

const getAllWebsiteList = async (data) => {
  const config = {
    url: "/suggest-landing-page-managment/get-website-list",
    method: "POST",
    data,
  };

  return appClient(config);
};
const getWebpages = async (data) => {
  const config = {
    url: "/suggest-landing-page-managment/get-webpages-list",
    method: "POST",
    data,
  };

  return appClient(config);
};
const TestLanding = () => {
  const [webState, setWebState] = useState();
  const [selectedValue, setSelectedValue] = useState({ site: null, id: null });
  const [suggestedWeb, setSuggestedPage] = useState(null);
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const today = moment();

  const [dates, setDates] = useState(
    moment.range(today.clone().subtract(15, "days"), today.clone())
  );

  const { mutate: setAllWebsiteList, isLoading: isAllWebsiteListLoading } =
    useMutation(getAllWebsiteList, {
      onSuccess: (data) => {
        setWebState(data);
      },
      onError: (err) => {},
    });
  const { mutate: setWebpages, isLoading: isWebpagesLoading } = useMutation(
    getWebpages,
    {
      onSuccess: (data) => {
        setSuggestedPage(data);
      },
      onError: (err) => {
        setErrorMsg(err.response.data.errors[0].message);
      },
    }
  );
  useEffect(() => {
    setAllWebsiteList({
      userId: "113425002644452353738",
    });
  }, []);

  const handleFunction = () => {
    setSuggestedPage(null);
    setErrorMsg(null);
    setWebpages({
      website: selectedValue.site,
      startDate: dates.start.format("YYYY-MM-DD"),
      endDate: dates.end.format("YYYY-MM-DD"),
    });
  };

  const onSelect = (val, states) => {
    setDates(val);
    setShow(false);
  };

  return (
    <Layout>
      <TestWrapper>
        <div>TestLanding</div>
        <CalendarWrap>
          <button onClick={() => setShow(!show)}>Select Date Range</button>

          {show && (
            <DateRangePicker
              value={dates}
              onSelect={onSelect}
              singleDateRange={true}
            />
          )}
          <p>
            Selected dates: <span>{dates.start.format("YYYY-MM-DD")}</span> to{" "}
            <span>{dates.end.format("YYYY-MM-DD")}</span>
          </p>
        </CalendarWrap>
        <DataList>
          <p>Select a website</p>
          <ListWrap>
            {webState?.sites &&
              webState?.sites.map((item, index) => {
                return (
                  <WebSiteListDiv>
                    <label>
                      <input
                        type="radio"
                        name="options"
                        value={item.index}
                        checked={selectedValue?.id === index}
                        onChange={() =>
                          setSelectedValue({ site: item.siteUrl, id: index })
                        }
                      />
                      {item.siteUrl}
                    </label>
                  </WebSiteListDiv>
                );
              })}
          </ListWrap>
        </DataList>

        <ButtonGet isActive={selectedValue.id != null} onClick={handleFunction}>
          click here to get webpages
        </ButtonGet>
        {errorMsg && <ErrorText>{errorMsg}!!!</ErrorText>}
        {isWebpagesLoading && !suggestedWeb ? (
          <SuggestWrap>
            <p>Loading...</p>
          </SuggestWrap>
        ) : (
          suggestedWeb && (
            <SuggestWrap>
              <p style={{ marginBottom: "14px" }}>Suggested Pages: </p>
              {suggestedWeb?.suggestedPages?.map((item, index) => (
                <SuggestedList>
                  <p key={index}>{`Url : ${item.url}`}</p>
                  <p key={index}>{`Score : ${item.score}`}</p>
                </SuggestedList>
              ))}
            </SuggestWrap>
          )
        )}
      </TestWrapper>
    </Layout>
  );
};

export default TestLanding;
