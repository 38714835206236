import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom'
// Styles
import { SideNavContainer, GroupContainer, Wrapper, LinkElement, LinkElementsCopy, LevelOneContainer, LevelOneContainerCopy, LevelTwoContainer, InnerMenu, ToggleNavContainer, MenuHeader, Logo, CollapsedIcon, LinkElements, FinishBtn, CustomComponentContainer, DashBoardSetupStatus, SetupStatusBody, SetupFooter, SetupDetails, SetupDetailsBody, ListContainer } from "./index.styled"
import Arrow from "../../../../media/Dashboard/polygon-arrow.svg"
import { useAuth } from '../../../../context/auth';
import { getSearchParams, getDomainName, urlPatternValidation } from "../../../../utils/helper.js"

import OverviewGreyIcon from "../../../../media/Dashboard/OverViewGreyLogo.svg"
import OverviewOrangeIcon from "../../../../media/Dashboard/OverViewOrangeLogo.svg"

import FolderGreyIcon from "../../../../media/Dashboard/FolderGreyLogo.svg"
import FolderOrangeIcon from "../../../../media/Dashboard/FolderOrangeLogo.svg"
import AdspyderBlackLogo from "./../../../../media/Images/AdspyderBlackLogo.svg"
import AdspyderWhiteSmallLogo from "../../../../media/Dashboard/AdspyderNavSmallLogo.svg"
import KeywordGreyLogo from "../../../../media/Dashboard/KeywordGreyLogo.svg"
import KeywordOrangeLogo from "../../../../media/Dashboard/KeywordOrangeLogo.svg"
import AdLibGreyLogo from "../../../../media/Dashboard/AdLibGreyLogo.svg"
import AdLibOrangeLogo from "../../../../media/Dashboard/AdLibOrangeLogo.svg"
import GlobeGreyLogo from "../../../../media/Dashboard/GlobeGreyLogo.svg"
import GlobeOrangeLogo from "../../../../media/Dashboard/GlobeOrangeLogo.svg"
import LandingPageGreyLogo from "../../../../media/Dashboard/LandingPageGreyLogo.svg"
import LandingPageOrangeLogo from "../../../../media/Dashboard/LandingPageOrangeLogo.svg"
import AiGreyLogo from "../../../../media/Dashboard/AiGreyLogo.svg"
import AiOrangeLogo from "../../../../media/Dashboard/AiOrangeLogo.svg"

import SidebarResponsiveIcon from "../../../../media/Dashboard/hide navigation.svg"
import newLogoIcon from '../../../../media/Dashboard/newLogo.svg'
import ToggleLogo from '../../../../media/Dashboard/open.svg'
import RightArrow from "../../../../media/Dashboard/right-arrow.png"

import DashboardIcon from '../../../../media/Dashboard/overview.svg'
import ManageProjectsIcon from '../../../../media/Dashboard/manage.svg'

import ImageAdsLogo from '../../../../media/Dashboard/image.svg'
import TextAdsLogo from '../../../../media/Dashboard/text.svg'
import AdCampaignLogo from '../../../../media/Dashboard/campaign.svg'
import Tooltip from 'rc-tooltip';
import Tour from 'reactour'

import { appClient } from '../../../../utils/axios-utils'
import { useMutation } from "@tanstack/react-query"
// Default Icon 
import LibraryIcon from '../../../../media/Dashboard/library.svg'
import PaidKeywordsIcon from '../../../../media/Dashboard/keywords.svg'
import DomainOverviewIcon from '../../../../media/Dashboard/domain.svg'
import LandingPageIcon from '../../../../media/Dashboard/landing.svg'
import AdspyScreenshot from "../../../../media/Dashboard/adspy-screenshot.png"
import DomainAnalysisScreenshot from "../../../../media/Dashboard/domainanalysis-screenshot.png"
import KeywordAnalysisScreenshot from "../../../../media/Dashboard/keywordanalysis-screenshot.png"
import LandingPageAnalysisScreenshot from "../../../../media/Dashboard/landingpage-screenshot.png"
import ImageAdGenerationScreenshot from "../../../../media/Dashboard/imageadgeneration-screenshot.png"

import WarningRedIcon from "../../../../media/Dashboard/warning-red.svg"
import InfoIcon from "../../../../media/Dashboard/info_button_tool_tip.svg"
import RoundLoaderIcon from "../../../../media/Dashboard/RoundLoaderIcon.png"
import TimeLeft from "../../../../media/Dashboard/time-left.svg"

import { useNavigate } from 'react-router-dom';
import {useOutsideAlerter} from "../../../../utils/outsiderAlert.js"
import _static from '../../../../config/static.js';

const toggleTourCompleted = () => {
  const config = {
    url: "/auth/app-tour",
    method: "GET"
  }

  return appClient(config)
}

const CustomComponent = ({ heading, desc, screenshot }) => (
  <CustomComponentContainer>
    <h1>{heading}</h1>
    <p>{desc}</p>
    <img src={screenshot} />
  </CustomComponentContainer>
);

const steps = [{
  selector: ".adspy", content: <CustomComponent heading={"AdSpy"}
    desc={"Search for competitors ads from over 1 billion+ historical ad copies across 15+ platforms."} screenshot={AdspyScreenshot}
  />
}, {
  selector: ".domain-analysis", content: <CustomComponent heading={"Domain Analysis"}
    desc={"Get complete analysis of your competitor domain including overview, funnel and tracking."} screenshot={DomainAnalysisScreenshot}
  />
}, {
  selector: ".keyword-analysis", content: <CustomComponent heading={"Keyword Analysis"} desc={"Get complete analysis of your competitor keyword including overview, funnel and tracking."} screenshot={KeywordAnalysisScreenshot} />
}, {
  selector: ".landingpage-analysis", content: <CustomComponent heading={"Landing Page Analysis"} desc={"Get Landing page screenshot of your competitor in specific past events."} screenshot={LandingPageAnalysisScreenshot} />
}, {
  selector: ".image-ad-generation", content: <CustomComponent heading={"Image Ad Generation"} desc={"Generate Image ads inspired from competitors and available templates."} screenshot={ImageAdGenerationScreenshot} />
}, {
  selector: ".text-ad-generation", content: <CustomComponent heading={"Text Ad Generation"} desc={"Generate Text ads inspired from competitors and available templates."} screenshot={ImageAdGenerationScreenshot} />
}
];

const SideNav = () => {
  const wrapperRef = useRef(null)

  // console.log("side nav render")
  const { authState, authDispatch } = useAuth()
  const [isTourOpen, setIsTourOpen] = useState(true);

  const [ setupStatusPopup ,setSetupStatusPopup] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  const [isHovered, setIsHovered] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  useOutsideAlerter(wrapperRef, setSetupStatusPopup)
  const { baseUrl } = _static;

  const handleNextStep = () => {
    if (currentStep === 0) {
      // Do something specific for the first step
      console.log('First step next button clicked');
    } else if (currentStep === 1) {
      // Do something specific for the second step
      console.log('Second step next button clicked');
    } else if (currentStep === 2) {
      // Do something specific for the third step
      console.log('Third step next button clicked');
    } else if (currentStep === 3) {
      // Do something specific for the third step
      console.log('Third step next button clicked');
    } else if (currentStep === 4) {
      // Do something specific for the third step
      console.log('Third step next button clicked');
    } else if (currentStep === 5) {
      // Do something specific for the third step
      console.log('Third step next button clicked');
    }
    setCurrentStep(prevStep => prevStep + 1);
  };

  const NavItem = [
    {
      heading: "Ad Library",
      inactiveLogo: LibraryIcon,
      activeLogo: AdLibOrangeLogo,
      selector: "adspy",
      subHeadings: [
        {
          name: "Text Search",
          code: "keyword",
          path: "/adspy"
        },
        {
          name: "Url Search",
          code: "url",
          path: "/adspy"
        }
      ]
    },
    {
      heading: "Domain Analysis",
      inactiveLogo: DomainOverviewIcon,
      selector: "domain-analysis",
      activeLogo: GlobeOrangeLogo,
      subHeadings: [
        {
          name: "Domain Overview",
          path: "/domain-overview"
        },
        // {
        //   name: "Domain Funnel",
        //   path: "/domain-funnel"
        // },
        {
          name: "Domain Tracking",
          path: "/domain-tracking"
        }
      ]
    },
    {
      heading: "Keywords Analysis",
      inactiveLogo: PaidKeywordsIcon,
      selector: "keyword-analysis",
      activeLogo: KeywordOrangeLogo,
      subHeadings: [
        {
          name: "Keyword Overview",
          path: "/keyword-overview"
        },
        // {
        //   name: "Keyword Funnel",
        //   path: "/keyword-funnel"
        // },
        {
          name: "Keyword Tracking",
          path: "/keyword-tracking"
        }
      ]
    },

    {
      heading: "Landing Page (LP)",
      inactiveLogo: LandingPageIcon,
      selector: "landingpage-analysis",
      activeLogo: LandingPageOrangeLogo,
      subHeadings: [
        {
          name: "LP & Ads relation",
          path: "/landing-page-analysis"
        }
      ]
    }
  ]

  const generationAndIntegrationItem = [
    {
      title: "AI GENERATION (AI BASED)",
      links: [
        {
          to: "/adgenerator/text-ad",
          logo: TextAdsLogo,
          selector: "text-ad-generation",
          text: "Text ad Generation"
        },
        {
          to: "/adgenerator/image-ad",
          logo: ImageAdsLogo,
          selector: "image-ad-generation",
          text: "Image ad Generation"
        }
      ]
    },
    {
      title: "Ad Management",
      selector: "ad-management",
      links: [
        {
          to: "/admanagement/ad-campaigns",
          logo: AdCampaignLogo,
          text: "Ad Optimization"
        }
      ]
    }
  ]

  const dashboardItem = [
    {
      title: "Overview",
      to: "/overview",
      logo: DashboardIcon,
    },
    // {
    //   title: "Manage Projects",
    //   to: "/projects",
    //   logo: ManageProjectsIcon,
    // },
  ]

  const ToggleMenu = (menu) => {
    if (authState.isNavCollapsed) {
      authDispatch({ type: "SET_IS_NAV_COLLAPSED", value: false })
    }

    if (authState.collapsedMenus.includes(menu)) {
      let finalCollapsedMenus = authState.collapsedMenus.filter((ele) => ele != menu)
      authDispatch({ type: 'SET_COLLAPSED_MENUS', value: finalCollapsedMenus })
    } else {
      let finalCollapsedMenus = [...authState.collapsedMenus, menu]
      authDispatch({ type: 'SET_COLLAPSED_MENUS', value: finalCollapsedMenus })
    }
  }

  useEffect(() => {
    const searchParamFromState = getSearchParams(location.search)
    const {
      searchMode
    } = searchParamFromState

    if (location.pathname.includes("/adspy/")) {
      if (searchMode) {
        authDispatch({ type: 'SET_ADSPY_SEARCH_MODE', value: searchMode })
      }
    }
  }, [])

  // useEffect(() => {
  //   if (window.innerWidth > 1375) {
  //     authDispatch({ type: "SET_IS_NAV_COLLAPSED", value: false })
  //   } else {
  //     authDispatch({ type: "SET_IS_NAV_COLLAPSED", value: true })
  //   }

  //   const updateMedia = () => {
  //     if (window.innerWidth > 1375) {
  //       authDispatch({ type: "SET_IS_NAV_COLLAPSED", value: false })
  //     } else {
  //       authDispatch({ type: "SET_IS_NAV_COLLAPSED", value: true })
  //     }
  //   };
  //   window.addEventListener('resize', updateMedia);
  //   return () => window.removeEventListener('resize', updateMedia);
  // }, []);

  const { mutate: ToggleTourCompletedMutate } = useMutation(toggleTourCompleted, {
    onSuccess: (data) => {
      authDispatch({ type: "SET_IS_APP_TOUR_DONE", value: true })
    },
    onError: (err) => {

    },
    onMutate: () => {

    }
  })

  const handleMouseEnter = () => {
    setIsHovered(true)
  };

  const handleMouseLeave = () => {
    setIsHovered(false)
  };


  const selectAccount = () => {
    const windowWidth = 600;
    const windowHeight = 500;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const left = (screenWidth - windowWidth) / 2;
    const top = (screenHeight - windowHeight) / 2;

    const oauthWindow = window.open(
      `${baseUrl}/suggest-landing-page-managment/login`,
      "_blank",
      `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
    );

    const messageListener = function (event) {
      if (event.data === "true") {
        navigate('/testLanding')
        window.removeEventListener("message", messageListener);
      }
    };

    window.addEventListener("message", messageListener);
  };


//   console.log("location pathnameee", location.pathname, authState, (location.pathname == "/overview") && (authState.selectedProject == null))
// console.log(authState.defaultProjectInfo,'authState.defaultProjectInfo')
  return (
    <SideNavContainer
      isNavCollapsed={authState.isNavCollapsed}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!authState.isNavCollapsed &&
      <Tooltip placement="right" trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>{"collapse"}</span>}>
        <ToggleNavContainer
          isNavCollapsed={authState.isNavCollapsed}
          onClick={() => {
            authDispatch({ type: "SET_IS_NAV_COLLAPSED", value: !authState.isNavCollapsed })
          }}>
          <img src={SidebarResponsiveIcon} />
        </ToggleNavContainer>
        </Tooltip>}

      <Wrapper isFull={(authState.defaultProjectInfo.setupDomains && authState.defaultProjectInfo.setupKeywords && authState.defaultProjectInfo.aiProcessed) || (location.pathname == "/overview")}>
        <Logo isNavCollapsed={authState.isNavCollapsed}>
          {authState.isNavCollapsed && isHovered ? (
            <Tooltip placement="right" trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>{"expand"}</span>}>
              <CollapsedIcon
                src={SidebarResponsiveIcon}
                onClick={() => {
                  authDispatch({ type: "SET_IS_NAV_COLLAPSED", value: !authState.isNavCollapsed })
                }}
              />
            </Tooltip>
          ) : (<img src={authState.isNavCollapsed ? AdspyderWhiteSmallLogo : newLogoIcon} onClick={() => { navigate('/') }} />)}
        </Logo>
        {/* level 1  */}
        <LevelOneContainer>
          {!authState.isNavCollapsed && <h2> DASHBOARD </h2>}
          {dashboardItem.map((item, i) => {
            return (
              <LinkElementsCopy isNavCollapsed={authState.isNavCollapsed} activePath={location.pathname.includes(item.to)}>
                <Link to={item.to}>
                  {!authState.isNavCollapsed ? <img src={item.logo} /> :
                    <Tooltip placement="right" trigger={['hover']} overlayStyle={{ width: "100" }} overlay={<span>{item.title}</span>}>
                      <img src={item.logo} />
                    </Tooltip>}
                  {!authState.isNavCollapsed && <span>{item.title}</span>}
                </Link>
              </LinkElementsCopy>
            )
          })}
        </LevelOneContainer>

        {/* level 2 */}
        {<LevelTwoContainer isAnalysing={!authState.defaultProjectInfo.aiProcessed && !location.pathname.includes("/overview")} collapsedState={authState.isNavCollapsed}>
          {!authState.isNavCollapsed && <h2>AD ANALYTICS</h2>}
          {
            NavItem.map((item, i) => {
              return <GroupContainer
                className={item.selector}
                closed={authState.collapsedMenus.includes(item.heading)}
                collapsedState={authState.isNavCollapsed}
                active={authState.activeMenuSection == item.heading && (!location.pathname.includes("/overview") && !location.pathname.includes("/projects"))}>

                {item.heading &&
                  <MenuHeader
                    isOpen={authState.collapsedMenus.includes(item.heading)}
                    active={authState.activeMenuSection == item.heading}
                    onClick={() => ToggleMenu(item.heading)}> 
                      {!authState.isNavCollapsed && <img className='menuheader-arrow' src={ToggleLogo} />}
                    <div>
                      {!authState.isNavCollapsed ? <img src={item.inactiveLogo} /> :
                        <Tooltip placement="right" trigger={['hover']} overlay={<span>{item.heading}</span>}>
                          <img
                            src={item.inactiveLogo} />
                        </Tooltip>}

                      {!authState.isNavCollapsed &&
                        <p>{
                          item.heading}
                        </p>}
                    </div>

                  </MenuHeader>}

                {!authState.isNavCollapsed &&
                  <InnerMenu closed={authState.collapsedMenus.includes(item.heading)}>
                    {item.subHeadings.length > 0 && item.subHeadings.map((subItem) => {
                      return <LinkElement
                        onClick={() => {
                          authDispatch({ type: 'SET_ACTIVE_MENU_SECTION', value: item.heading })
                          if (item.heading == "Ad Library") {
                            authDispatch({ type: 'SET_ADSPY_SEARCH_MODE', value: subItem.code })
                            authDispatch({ type: "SET_SEARCH_PLATFORM", value: "google" })
                          }
                        }}

                        activePath={location.pathname.includes("/adspy") ? authState.searchMode == subItem.code : location.pathname.includes(subItem.path) && (!location.pathname.includes("/overview") && !location.pathname.includes("/projects"))}
                      >
                        {/* <Link to={subItem.path}> */}
                        <div onClick={() => navigate(subItem.path)}>
                          <img /><p>{subItem.name}</p>
                        </div>
                        {/* </Link> */}
                      </LinkElement>
                    })}
                  </InnerMenu>}

              </GroupContainer>
            })
          }

          {/* level 3  */}
          {generationAndIntegrationItem.map((container) => (
            <LevelOneContainerCopy isNavCollapsed={authState.isNavCollapsed}>
              {!authState.isNavCollapsed && <h2>{container.title}</h2>}

              {container.links.map((link) => (
                <LinkElements activePath={location.pathname.includes(link.to)}
                  className={link.selector}>
                  <Link to={link.to}>
                    {!authState.isNavCollapsed ? <img src={link.logo} /> :
                      <Tooltip placement="right" trigger={['hover']} overlay={<span>{link.text}</span>}>
                        <img src={link.logo} />
                      </Tooltip>}   
                    {!authState.isNavCollapsed && <span>{link.text}</span>}
                  </Link>
                </LinkElements>
              ))}

            </LevelOneContainerCopy>

          ))}
    {/* <button onClick={selectAccount}>login</button> */}

        </LevelTwoContainer>} 

      </Wrapper>

      {
      (authState.defaultProjectInfo.setupDomains && authState.defaultProjectInfo.setupKeywords && authState.defaultProjectInfo.aiProcessed) ? "" :
       (location.pathname == "/overview") ? "" :
        <DashBoardSetupStatus ref={wrapperRef} isNavCollapsed={authState.isNavCollapsed}>
          <SetupStatusBody setupPending={(!authState.defaultProjectInfo.setupDomains || !authState.defaultProjectInfo.setupKeywords) ? true : authState.defaultProjectInfo.aiProcessed ? false : false}>
          {
          !(!authState.defaultProjectInfo.setupDomains || !authState.defaultProjectInfo.setupKeywords) && <Tooltip placement="right" trigger={[ 'hover']} overlay={<ListContainer style={{ color: "#FFF"}}>
                <h4 style={{color: "#FFF", marginBottom: "10px"}}>
                  Once Complete, You’ll Unlock:
                </h4>
                <ul>
                  <li style={{color: "#FFF"}}>
                    Advanced filters in AdSpy search
                  </li>
                  <li style={{color: "#FFF"}}>
                    Competitor-based personas
                  </li>
                  <li style={{color: "#FFF"}}>
                    More precise ad generation results
                  </li>
                </ul>
              </ListContainer>}>
            <img className='info-icon' src={InfoIcon} />
          </Tooltip>
          }
            {
              (!authState.defaultProjectInfo.setupDomains || !authState.defaultProjectInfo.setupKeywords) ?
                <>
                  <img src={WarningRedIcon} />
                  <h3>Halfway there</h3>
                  {!authState.isNavCollapsed && <p>{authState.defaultProjectInfo.setupKeywords ? "Competitors" : "Keywords"} setup is next! Complete to unlock more features.</p>}
                </>
                :
                <>
                  <img className="loading-icon" src={RoundLoaderIcon} />
                  {!authState.isNavCollapsed && <p>Analyzing your setup for better ads and results</p>}
                </>

            }

          </SetupStatusBody>
          {setupStatusPopup && <SetupDetails isNavCollapsed={authState.isNavCollapsed}>
            <h3>Setup Analysis in Progress</h3>
            <SetupDetailsBody>
              <div>
                <img src={TimeLeft} />
                <p>We're analyzing your setup to fine-tune ad personas and results. This process takes upto 2 hours.</p>
              </div>
              <ListContainer>
                <h4>
                  Once Complete, You’ll Unlock:
                </h4>
                <ul>
                  <li>
                    Advanced filters in AdSpy search
                  </li>
                  <li>
                    Competitor-based personas
                  </li>
                  <li>
                    More precise ad generation results
                  </li>
                </ul>
              </ListContainer> 
            </SetupDetailsBody>
          </SetupDetails>}
          <SetupFooter 
          isNotActive={(!authState.defaultProjectInfo.setupDomains || !authState.defaultProjectInfo.setupKeywords) ? location.pathname.includes("/overview"): false}
          onClick={()=>{
            (!authState.defaultProjectInfo.setupDomains || !authState.defaultProjectInfo.setupKeywords) ? navigate("/overview") : setSetupStatusPopup(prev => !prev)
          }}>
            {
              (!authState.defaultProjectInfo.setupDomains || !authState.defaultProjectInfo.setupKeywords) ? location.pathname.includes("/overview") ? "" : "Setup now" : setupStatusPopup ? "Hide Details <<" : "See Details >>"
            }
          </SetupFooter>
        </DashBoardSetupStatus>
      }



      <Tour
        steps={steps}
        isOpen={!authState.tourCompleted}
        onRequestClose={() => ToggleTourCompletedMutate()}
        lastStepNextButton={<FinishBtn onClick={() => {
          ToggleTourCompletedMutate()
        }}>Finish</FinishBtn>}
      />

    </SideNavContainer>
  )
}

export default SideNav










