import React,{ useEffect, useState } from 'react'
import { useAuth } from '../../../../../../context/auth'
import { useDashboard } from '../../../../../../context/dashboard';
import { Wrapper, Header, ContentContainer, Breadcrumb, LeftContentContainer, RightContentContainer, TopPagesContainer, LandingPagesList, LandingPageLink, CustomDomainConatiner, InputUrlWrapper, InputUrlContainer, NextButton, FocusQueryContainer } from "./index.styled"
import { useTrackingCompetitors, useTrackingKeywords } from '../../../../AdPages/utils/SortFocus/hooks/useSortFocus';
import { useNavigate } from 'react-router-dom';
import Back from "../../../../../../media/Dashboard/back.svg"

import { useLandingPageAnalysis } from '../../../context/landingpage';
import DownArrow from "../../../../../../media/Dashboard/downarrow.png";
import FilterIcon from "../../../../../../media/Dashboard/filter.png";
import UpArrow from "../../../../../../media/Dashboard/uparrow.png";
import RightArrowIcon from "../../../../../../media/Dashboard/rightarrow.png"
import LinkPin from "../../../../../../media/Dashboard/LinkPin.png"

const SelectUrl = () => {
  const { authState, authDispatch } = useAuth();
  const { dashboardState} = useDashboard()
  const [isAddNewPopupOpened, setAddNewPopupOpened] = useState(false);

  const {landingPageAnalysisState, landingPageAnalysisDispatch} = useLandingPageAnalysis()
  const navigate = useNavigate()

  const [url, setUrl] = useState("")
  const {
    authState: { trackingKeywords, trackingCompetitors, defaultProjectInfo },
  } = useAuth();

  return <Wrapper>
          <Breadcrumb onClick={() => {
            landingPageAnalysisDispatch({type: "SET_STEP", value: "list-competitors"})
          }} >
              <img src={Back} />
              Back to LP Homepage
          </Breadcrumb>
          <Header>
            <h2>
              Landing Page Analysis <span>{"<  " + landingPageAnalysisState.selectedCompetitor}</span>
            </h2>
            <p>Choose any option below to explore the competitor's landing pages and insights.</p>
          </Header>
          <ContentContainer>
            <LeftContentContainer>
              <TopPagesContainer>
                <h3>
                  Top Landing Pages
                </h3>
                <p>
                  View the competitor's most advertised pages.
                </p>
                <LandingPagesList>
                  {
                    landingPageAnalysisState.topLandingPages.map((landingPageData)=>{
                      return <LandingPageLink>
                        <img src={LinkPin}/>
                        <p>{landingPageData}</p>
                      </LandingPageLink>
                    })
                  }
                </LandingPagesList>
              </TopPagesContainer>
            </LeftContentContainer>
            <RightContentContainer>
                <CustomDomainConatiner>
                  <h3>
                    Top Landing Pages
                  </h3>
                  <p>
                    View the competitor's most advertised pages.
                  </p>
                  <InputUrlWrapper>
                    <InputUrlContainer>
                      <p>{landingPageAnalysisState.selectedCompetitor}</p> 
                      <input type="text" value={url} onChange={(e)=> {
                        setUrl(e.target.value)
                      }} placeholder='Enter their service page URL (e.g., /service-page)'/>
                    </InputUrlContainer>
                    <NextButton onClick={()=>{
                        landingPageAnalysisDispatch({type: "SET_STEP", value: "select-date"})
                        landingPageAnalysisDispatch({type: "SET_URL", value: url})
                      }}>
                      <img src={RightArrowIcon}/>
                    </NextButton>
                  </InputUrlWrapper>
                </CustomDomainConatiner>
                <FocusQueryContainer>
                  <h3>
                    Top Landing Pages
                  </h3>
                  <p>
                    View the competitor's most advertised pages.
                  </p>
                  <InputUrlWrapper>
                    <InputUrlContainer>
                      <input type="text" placeholder='Enter focus queries (e.g., “Black Friday”).'/>
                    </InputUrlContainer>
                    <NextButton>
                      <img src={RightArrowIcon}/>
                    </NextButton>
                  </InputUrlWrapper>
                </FocusQueryContainer>
            </RightContentContainer>
          </ContentContainer>
        </Wrapper>
        }


export default SelectUrl 