import styled from "styled-components";

export const CompetitorTabDiv = styled.div``;
export const TabWrapper = styled.div`
  background: #e6e6e6;
`;
export const TabList = styled.div`
  display: flex;
  gap: 18px;
  padding: 12px 20px;
`;
export const TabsDiv = styled.div`
  padding: 8px;
  background: #b3b3b3;
  border-radius: 4px;
`;
export const TotalListWrap = styled.div`
  display: flex;
`;
export const TotalContainer = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  width: 250px;
  height: 150px;
  background: #ffffff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  p {
    color: #1e1e1e;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.132px;
  }
`;
export const TotalDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    color: #1e1e1e;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.176px;
    display: flex;
    align-items: center;
    gap: 6px;
    p {
      color: #008919;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.132px;
    }
  }
`;
export const Amount = styled.div`
  margin-top: 34px;
  color: #1e1e1e;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.456px;
`;
