import React from "react";
import {
  CombinationDiv,
  Decrip,
  GenerateBtn,
  GenerateWrapper,
  ListTitle,
  PromptDropDown,
  PromptLayer,
  PromptListWrap,
  PromptWrapper,
  RefinePrompt,
  SendWrap,
  Suggestion,
  TextareaDiv,
  TextareaWrap,
  Title,
} from "./index.styled";
import rightArrow from "../../../../media/Dashboard/rightarrow.png";
import { useMutation } from "@tanstack/react-query";
import { appClient } from "../../../../utils/axios-utils";
import { usePromptTracking } from "../context/promtContext";
import { useAuth } from "../../../../context/auth";
import loader from "../../../../media/Dashboard/Rolling.svg";
import recycle from "../../../../media/Dashboard/refresh.svg";
import GenerateKeyComponent from "./GenerateKeyComponent";
import toast from "react-hot-toast"

const getCreateKeyword = async (data) => {
  const config = {
    url: "/keyword-research/create-keyword-plan",
    method: "POST",
    data,
  };

  return appClient(config);
};

const getRefinePrompt = async (data) => {
  const config = {
    url: "/keyword-research/refine-prompt",
    method: "POST",
    data,
  };

  return appClient(config);
};

const getGenerateKeyword = async (data) => {
  const config = {
    url: "/keyword-research/generate-keywords",
    method: "POST",
    data,
  };

  return appClient(config);
};

const PromptContainer = () => {
  const { authState, authDispatch } = useAuth();
  const {
    promptState: {
      promptInput,
      keywordPlan,
      selectedInputs,
      generateKeyw,
      targetToggle,
      seedKeywords,
    },
    promptDispatch,
  } = usePromptTracking();

  const { mutate: setCreateKeyword, isLoading: isCreateKeywordLoading } =
    useMutation(getCreateKeyword, {
      onSuccess: (data) => {
        promptDispatch({ type: "SET_KEYWORD_PLAN", value: data });
      },
      onError: (err) => {},
    });

  const { mutate: setRefinePrompt, isLoading: isRefinePromptLoading } =
    useMutation(getRefinePrompt, {
      onSuccess: (data) => {
        promptDispatch({ type: "SET_REFINED_PROMPT", value: data });
        promptDispatch({
          type: "SET_KEYWORD_PLAN",
          value: { ...keywordPlan, userPrompt: data.refinedPrompt },
        });
      },
      onError: (err) => {},
    });

  const { mutate: setGenerateKeyword, isLoading: isGenerateKeywordLoading } =
    useMutation(getGenerateKeyword, {
      onSuccess: (data) => {
        data.success
          ? promptDispatch({ type: "SET_GENERATE_KEYWORD", value: data })
          : toast.error(`Something went wrong!  ${ data.message}`);
      },
      onError: (err) => {},
    });

  const handleClick = () => {
    promptInput.trim() != "" &&
      setCreateKeyword({
        projectId: authState.defaultProjectInfo.projectId,
        prompt: promptInput,
      });
  };

  const handleRefineClick = () => {
    setRefinePrompt({
      projectId: authState.defaultProjectInfo.projectId,
      keywordPlanId: keywordPlan.id,
      prompt: `${keywordPlan.userPrompt}${
        selectedInputs.location ? ` in ${selectedInputs.location}` : ""
      }${selectedInputs.audience ? ` for ${selectedInputs.audience}` : ""}${
        selectedInputs.searchInt ? ` ${selectedInputs.searchInt}` : ""
      }${
        selectedInputs.campaignGoal ? ` who ${selectedInputs.campaignGoal}` : ""
      } `,
      locations: [selectedInputs.location],
      audienceDemographics: [selectedInputs.audience],
      searchIntent: [selectedInputs.searchInt],
      campaignGoal: [selectedInputs.campaignGoal],
    });
  };

  const handleGenerate = () => {
    setGenerateKeyword({
      projectId: authState.defaultProjectInfo.projectId,
      keywordPlanId: keywordPlan.id,
      prompt: `${keywordPlan.userPrompt}${
        selectedInputs.location ? ` in ${selectedInputs.location}` : ""
      }${selectedInputs.audience ? ` for ${selectedInputs.audience}` : ""}${
        selectedInputs.searchInt ? ` ${selectedInputs.searchInt}` : ""
      }${
        selectedInputs.campaignGoal ? ` who ${selectedInputs.campaignGoal}` : ""
      } `,
      locations: [selectedInputs.location],
      audienceDemographics: [selectedInputs.audience],
      searchIntent: [selectedInputs.searchInt],
      campaignGoal: [selectedInputs.campaignGoal],
      seedKeywords: seedKeywords.split(","),
    });
  };

  const onPromptInputChange = (e) => {
    promptDispatch({ type: "SET_PROMPT_INPUT", value: e.target.value });
  };
  const handleSeed = (e) => {
    promptDispatch({ type: "SET_SEED_KEYWORDS", value: e.target.value });
  };

  return (
    <>
      {!generateKeyw ? (
        <PromptLayer>
          <PromptWrapper>
            <Title>Adspyder AI Keyword Lab</Title>
            <Decrip>Where creativity meets data-driven decisions.</Decrip>
            <TextareaWrap>
              <TextareaDiv
                value={
                  keywordPlan
                    ? `${keywordPlan.userPrompt}${
                        selectedInputs.location
                          ? ` in ${selectedInputs.location}`
                          : ""
                      }${
                        selectedInputs.audience
                          ? ` for ${selectedInputs.audience}`
                          : ""
                      }${
                        selectedInputs.searchInt
                          ? ` ${selectedInputs.searchInt}`
                          : ""
                      }${
                        selectedInputs.campaignGoal
                          ? ` who ${selectedInputs.campaignGoal}`
                          : ""
                      } `
                    : promptInput
                }
                onChange={(e) => onPromptInputChange(e)}
                placeholder="Describe your campaign goal, audience or product focus in a few words"
                autoFocus={true}
              />
              {!isCreateKeywordLoading ? (
                !keywordPlan && (
                  <SendWrap
                    onClick={handleClick}
                    isActive={promptInput && promptInput.trim() != ""}
                  >
                    <img src={rightArrow} />
                  </SendWrap>
                )
              ) : (
                <SendWrap isActive={!isCreateKeywordLoading}>
                  <img src={loader} className="loadClass" />
                </SendWrap>
              )}
              {!isRefinePromptLoading ? (
                (selectedInputs.location ||
                  selectedInputs.audience ||
                  selectedInputs.searchInt ||
                  selectedInputs.campaignGoal) && (
                  <RefinePrompt
                    onClick={handleRefineClick}
                    isActive={
                      !selectedInputs.location ||
                      !selectedInputs.audience ||
                      !selectedInputs.searchInt ||
                      !selectedInputs.campaignGoal
                    }
                  >
                    <img src={recycle} />
                    Refine prompt
                  </RefinePrompt>
                )
              ) : (
                <SendWrap>
                  <img src={loader} className="loadClass" />
                </SendWrap>
              )}
            </TextareaWrap>
          </PromptWrapper>
          {keywordPlan && (
            <>
              <CombinationDiv>
                <p>Combination Prompts(optional)</p>
                <PromptListWrap>
                  <ListTitle>Location-Based Targeting</ListTitle>
                  <PromptDropDown>
                    <label for="location">
                      Where do you want to target your campaign?
                    </label>
                    <div>
                      <select
                        id="location"
                        name="location"
                        defaultValue=""
                        style={{ color: "#d0d0d0", fontSize: "12px" }}
                        onChange={(e) => {
                          e.target.style.color = "black";
                          e.target.style.fontSize = "14px";
                          promptDispatch({
                            type: "SET_SELECTED_INPUTS",
                            key: "location",
                            value: e.target.value,
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          City, state, or country (e.g., New York, USA)
                        </option>
                        {keywordPlan.suggestedLocations.map((loc) => (
                          <option value={loc}>{loc}</option>
                        ))}
                      </select>
                      <Suggestion>
                        Suggestion : {keywordPlan.suggestedLocations.join(", ")}
                      </Suggestion>
                    </div>
                  </PromptDropDown>
                </PromptListWrap>
                <PromptListWrap>
                  <ListTitle>Audience Demographics</ListTitle>
                  <PromptDropDown>
                    <label for="audience">Who is your target audience?</label>
                    <div>
                      <select
                        id="audience"
                        name="audience"
                        defaultValue=""
                        style={{ color: "#d0d0d0", fontSize: "12px" }}
                        onChange={(e) => {
                          e.target.style.color = "black";
                          e.target.style.fontSize = "14px";
                          promptDispatch({
                            type: "SET_SELECTED_INPUTS",
                            key: "audience",
                            value: e.target.value,
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          Young professionals, families, college students, etc
                        </option>
                        {keywordPlan.suggestedAudience.map((aud) => (
                          <option value={aud}>{aud}</option>
                        ))}
                      </select>
                      <Suggestion>
                        Suggestion : {keywordPlan.suggestedAudience.join(", ")}
                      </Suggestion>
                    </div>
                  </PromptDropDown>
                </PromptListWrap>
                <PromptListWrap>
                  <ListTitle>Search Intent</ListTitle>
                  <PromptDropDown>
                    <label htmlFor="searchIntents">
                      What is your audience looking for?
                    </label>
                    <div>
                      <select
                        id="searchIntents"
                        name="searchIntents"
                        defaultValue=""
                        style={{ color: "#d0d0d0", fontSize: "12px" }}
                        onChange={(e) => {
                          e.target.style.color = "black";
                          e.target.style.fontSize = "14px";
                          promptDispatch({
                            type: "SET_SELECTED_INPUTS",
                            key: "searchInt",
                            value: e.target.value,
                          });
                        }}
                      >
                        <option value="" disabled style={{ color: "grey" }}>
                          Affordable meals, healthy eating, fast delivery, etc.
                        </option>
                        {keywordPlan.suggestedSearchIntents.map(
                          (intent, index) => (
                            <option key={index} value={intent}>
                              {intent}
                            </option>
                          )
                        )}
                      </select>

                      <Suggestion>
                        Suggestion :{" "}
                        {keywordPlan.suggestedSearchIntents.join(", ")}
                      </Suggestion>
                    </div>
                  </PromptDropDown>
                </PromptListWrap>
                <PromptListWrap>
                  <ListTitle>Campaign Goal</ListTitle>
                  <PromptDropDown>
                    <label htmlFor="campaign">
                      What is your primary campaign goal or USP?
                    </label>
                    <div>
                      <select
                        id="campaign"
                        name="campaign"
                        defaultValue=""
                        style={{ color: "#d0d0d0", fontSize: "12px" }}
                        onChange={(e) => {
                          e.target.style.color = "black";
                          e.target.style.fontSize = "14px";
                          promptDispatch({
                            type: "SET_SELECTED_INPUTS",
                            key: "campaignGoal",
                            value: e.target.value,
                          });
                        }}
                      >
                        <option value="" disabled selected>
                          Highlight affordability and convenience of vegan
                          meals.
                        </option>
                        {keywordPlan.suggestedCampaignGoals.map((camp) => (
                          <option value={camp}>{camp}</option>
                        ))}
                      </select>
                      <Suggestion>
                        Suggestion :{" "}
                        {keywordPlan.suggestedCampaignGoals.join(", ")}
                      </Suggestion>
                    </div>
                  </PromptDropDown>
                </PromptListWrap>
                <PromptListWrap>
                  <ListTitle>Seed Keywords</ListTitle>
                  <PromptDropDown>
                    <label>Enter Seed Keywords*</label>
                    <div>
                      <input
                        value={seedKeywords}
                        onChange={(e) => handleSeed(e)}
                      />
                    </div>
                  </PromptDropDown>
                </PromptListWrap>
              </CombinationDiv>
              {isGenerateKeywordLoading ? (
                <GenerateBtn isActive={isGenerateKeywordLoading}>
                  <img src={loader} className="loadClass" />
                </GenerateBtn>
              ) : (
                <GenerateBtn
                  onClick={handleGenerate}
                  isActive={
                    isGenerateKeywordLoading ||
                    !selectedInputs.location ||
                    !selectedInputs.audience ||
                    !selectedInputs.searchInt ||
                    !selectedInputs.campaignGoal ||
                    !seedKeywords.trim().length
                  }
                >
                  Generate keywords
                </GenerateBtn>
              )}
            </>
          )}
        </PromptLayer>
      ) : (
        <PromptLayer>
          <GenerateWrapper isActive={targetToggle}>
            {targetToggle && (
              <p
                className="back"
                onClick={() =>
                  promptDispatch({ type: "SET_TARGET_TOGGLE", value: false })
                }
              >
                <img src={rightArrow} />
                back
              </p>
            )}
            <Title>
              {!targetToggle ? "Adspyder AI Keyword Lab" : "Targeted keywords"}
            </Title>
            <Decrip>
              {!targetToggle
                ? "Where creativity meets data-driven decisions."
                : "Review and refine your keywords for the best performance"}
            </Decrip>
            <GenerateKeyComponent />
          </GenerateWrapper>
        </PromptLayer>
      )}
    </>
  );
};

export default PromptContainer;
