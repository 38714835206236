import React from "react";
import {
  CreatedText,
  CreateInfo,
  CreateWrap,
  LinkedInInfo,
  LinkedInWrap,
  OnlyOffer,
  ShareBtn,
} from "./index.styled";
import linkedImg from "../../../../../media/Dashboard/LinkedIn16.svg";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useAuth } from "../../../../../context/auth";
import _static from "../../../../../config/static";

const LinkedIn = ({ InfoState,  IsFeedLoad }) => {
  const { authState, authDispatch } = useAuth();
  const { baseUrl } = _static;
  return (
    <LinkedInWrap>
      <LinkedInInfo>
        We’ve crafted a LinkedIn post based on your feedback. Sharing it will
        inspire others and unlock your first month FREE – instantly!
      </LinkedInInfo>
      <CreateWrap>
        <CreateInfo>
          <p>Create a post</p>
          <img src={linkedImg} />
        </CreateInfo>
        <CreatedText>
          {IsFeedLoad ? (
            <Skeleton
              style={{ lineHeight: "190px", width: "100%" }}
              baseColor="rgb(234, 244, 255)"
              highlightColor="rgb(168 211 255)"
            />
          ) : (
            <p>{authState.linkedInPost && authState.linkedInPost}</p>
          )}
        </CreatedText>
      </CreateWrap>
      <ShareBtn isLoading={!authState.linkedInPost}>
        <a href={`${baseUrl}/feedback/linkedin-share`}>
          Share & Unlock Your Free Month
        </a>
      </ShareBtn>
      <OnlyOffer>only the first 100 users can claim this reward!</OnlyOffer>
    </LinkedInWrap>
  );
};

export default LinkedIn;
